/* This stylesheet generated by Transfonter (https://transfonter.org) on June 1, 2017 1:53 AM */
@use 'sass:math';

@font-face {
	font-family: 'Work Sans';
	src: url('../../assets/fonts/WorkSans-Regular.eot');
	src: url('../../assets/fonts/WorkSans-Regular.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/WorkSans-Regular.woff') format('woff'),
		url('../../assets/fonts/WorkSans-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Work Sans';
	src: url('../../assets/fonts/WorkSans-Light.eot');
	src: url('../../assets/fonts/WorkSans-Light.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/WorkSans-Light.woff') format('woff'),
		url('../../assets/fonts/WorkSans-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Work Sans';
	src: url('../../assets/fonts/WorkSans-Medium.eot');
	src: url('../../assets/fonts/WorkSans-Medium.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/WorkSans-Medium.woff') format('woff'),
		url('../../assets/fonts/WorkSans-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Work Sans';
	src: url('../../assets/fonts/WorkSans-Bold.eot');
	src: url('../../assets/fonts/WorkSans-Bold.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/WorkSans-Bold.woff') format('woff'),
		url('../../assets/fonts/WorkSans-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}


$bodyfont: Helvetica, Arial, sans-serif;
$bodyfontbold: Helvetica, Arial, sans-serif;
$headingfont: "Work Sans", Helvetica, Arial, sans-serif;

// Define the `for` mixin
@mixin for($breakpoint) {
  @if $breakpoint == sm {
    @media (max-width: 640px) {
      @content; // This allows the content to be injected inside the mixin
    }
  }
  @else if $breakpoint == md {
    @media (max-width: 768px) {
      @content;
    }
  }
  @else if $breakpoint == lg {
    @media (max-width: 1024px) {
      @content;
    }
  }
  // Add more breakpoints if needed
}


@mixin heading($style, $text) {
	font-family: $headingfont;
	color: $text;
	font-style: normal;
	text-transform: none;
	margin-top: 0;
	margin-bottom: 0;
	@if $style == h1-home {
		
		@include x-rem(font-size, 5.5);
		font-weight: 300;
		margin-top: 40px;
		line-height: 1.1;
		@include for(sm md) {
			margin-top: 20px;
			@include x-rem(font-size, 4.5);
		}
		@include for(xs) {
			margin-top: 20px;
			@include x-rem(font-size, 3.2);
		}
	}
	@if $style == h1 {
		@include x-rem(font-size, 7.5);
		font-weight: 300;
		margin-top: 40px;
		line-height: 1.1;
		@include for(sm) {
			margin-top: 20px;
			@include x-rem(font-size, 5.5);
		}
		@include for(xs) {
			margin-top: 20px;
			@include x-rem(font-size, 3.5);
		}
	}
	@if $style == h2 {
		@include x-rem(font-size, 4.5);
		font-weight: 300;
		margin-top: 30px;
		line-height: 1.2;
		@include for(sm) {
			margin-top: 10px;
			@include x-rem(font-size, 4);
		}
		@include for(xs) {
			margin-top: 10px;
			@include x-rem(font-size, 3.2);
		}
	}
	@if $style == h3 {
		@include x-rem(font-size, 3.6);
		font-weight: 300;
		margin-top: 20px;
		line-height: 1.2;
		@include for(xs) {
			margin-top: 10px;
			@include x-rem(font-size, 2.8);
		}
	}
	@if $style == h4 {
		@include x-rem(font-size, 2.8);
		font-weight: normal;
		margin-top: 10px;
		line-height: 1.2;
		@include for(xs) {
			margin-top: 10px;
			@include x-rem(font-size, 2.2);
		}
	}
	@if $style == h5 {
		@include x-rem(font-size, 2.2);
		font-weight: normal;
		margin-top: 10px;
		line-height: 1.2;
		@include for(xs) {
			margin-top: 10px;
			@include x-rem(font-size, 1.8);
		}
	}
	@if $style == p {
		@include x-rem(font-size, 1.8);
		font-weight: normal;
		margin-top: 10px;
		line-height: 1.4;
		@include for(xs) {
			margin-top: 10px;
			@include x-rem(font-size, 1.6);
		}
	}
	@if $style == light {
		@include x-rem(font-size, 1.4);
		font-weight: normal;
		margin-top: 10px;
		opacity: .75;
		text-transform: uppercase;
		letter-spacing: 1.2px;
		@include for(xs) {
			margin-top: 10px;
		}
	}
}

h1, .h1 {
	@include heading(h1, 'inherit');
}
h2, .h2 {
	@include heading(h2, 'inherit');
}
h3, .h3 {
	@include heading(h3, 'inherit');
}
h4, .h4 {
	@include heading(h4, 'inherit');
}
h5, .h5 {
	@include heading(h5, 'inherit');
}
p {
	@include heading(p, 'inherit');
}
a {
	@include heading(p, 'inherit');
	text-decoration: none;
}
ul {
	padding-left: 0;
	list-style-type: none;
	li {
		$text: "inherit";
		@include heading(p, $text);
		margin-top: 0;
	}
}
ol {
	padding-left: 20px;

	li {
		$text: "inherit";
		@include heading(p, $text);
		margin-top: 0;
		ol {
			list-style-type: upper-alpha;
			li {
				ol {
					list-style-type: lower-alpha;
				}
			}
		}
	}

}

table {
	$text: "inherit";
	@include heading(p, $text);
}
