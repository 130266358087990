.en-simple-block {
  margin-top: -80px !important;
  &__content {
    max-width: 1024px;

     h6 {
      margin-block: 1.5em;
      font-size: 18px;
      color: var(--color-secondary);
      font-weight: 600;
     }
     p {
      font-size: 1.6rem;
     }
  }
}
