section.store-finder-filter .stores .store-list li.store .available-sizes .range span:before {
  content: "";
  width: 0;
  height: 0;
}

section.store-finder-filter .stores .store-list li.store .available-sizes .range span {
  margin-right: 5px;
}

section.store-finder-filter .stores .store-list li.store .available-sizes .range span.comma {
  padding: 0;
  margin: 0;
}

section.store-finder-filter .stores .store-list li.store .available-sizes .range li:last-of-type .comma {
  position: absolute;
  left: 9999999px;
}
