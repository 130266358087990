.en-content-block {
  margin-block: 85px !important;
  background-color: var(--color-bg-dark);
  color: var(--color-white) !important;

  @include breakpoint($sm) {
    margin-block: 64px;
  }

  @include breakpoint($lg) {
    margin-block: 112px;
  }
  p {
    color: var(--color-white);
    padding-block: 20px;
  }

  &__content {
    padding-block: 24px;

    @include breakpoint($sm) {
      padding-block: 32px;
    }

    @include breakpoint($lg) {
      padding-right: 40px;
    }

    h2 {
      margin-top: 1rem;
      line-height: 1;
      color: var(--color-white);
    }
  }

  &__image {
    margin-bottom: -48px;

    @include breakpoint($lg) {
      height: calc(100% + 96px);
      margin-block: -48px;
    }

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  &--alt {
    .en-content-block__image {
      margin-top: -48px;
      margin-bottom: -1rem;
    }

    .en-content-block__content {
      @include breakpoint($lg) {
        padding-left: 32px;
      }
    }
  }
}
