.cta-panel-two-col.img-left{	
	height: auto !important;
	min-height: 100% !important;
	margin-top: 80px;
	margin-bottom: 80px;
	
	.col-right{		
		background-color: #{$color-blue};
		.content{
			float: left;			
			text-align: left;
			padding-left: 36px;
			padding-right: 36px;
			h1, h2, h3, h4, h5, h6, p{
				color: #{$color-white} !important;
			}
		}			
	}
	.col-left{
		.content-image{
			img{
				width: 100%;
				height: auto;
			}
		}	
	}
	
}
.cta-panel-two-col.img-right{
	height: auto !important;
	min-height: 100% !important;
	margin-top: 80px;
	margin-bottom: 80px;

	.col-left{		
		background-color: #{$color-blue};
		.content{
			float: right;			
			text-align: left;
			padding-left: 36px;
			padding-right: 36px;
			h1, h2, h3, h4, h5, h6, p{
				color: #{$color-white};
			}			

		}
		.content.lightText{
			h1, h2, h3, h4, h5, h6, p{
				color: #{$color-white};
			}
			.button:before{
				content: "";
			    background-image: url('https://www.coregas.com.au/dist/images/arrow-white@2x.png');
			    background-size: 100%;
			    background-repeat: no-repeat;
			    position: relative;
			    display: inline-block;
			    width: 26px;
			    height: 16px;
			    top: 3px;
			    left: 0;
			    color: #{$color-white};
			}			
		}
		.content.darkText{
			h1, h2, h3, h4, h5, h6, p{
				color: #{$color-dark-grey};
			}
			.button:before{
				content: "";
			    background-image: url('https://www.coregas.com.au/dist/images/arrow-blue@2x.png');
			    background-size: 100%;
			    background-repeat: no-repeat;
			    position: relative;
			    display: inline-block;
			    width: 26px;
			    height: 16px;
			    top: 3px;
			    left: 0;
			    color: #{$color-white};
			}
			.button{
				span{
					color: #{$color-blue};
				}
			}
		}		
	}			
	.col-right{
		.content-image{
			img{
				width: 100%;
				height: auto;
			}
		}	
		.content{
			float: right;			
			text-align: left;
			padding-left: 36px;
			padding-right: 36px;
			h1, h2, h3, h4, h5, h6, p{
				color: #{$color-white};
			}
			.button:before{
				content: "";
			    background-image: url('https://www.coregas.com.au/dist/images/arrow-white@2x.png');
			    background-size: 100%;
			    background-repeat: no-repeat;
			    position: relative;
			    display: inline-block;
			    width: 26px;
			    height: 16px;
			    top: 3px;
			    left: 0;
			    color: #{$color-white};
			}	
		}
		.content.lightText{
			h1, h2, h3, h4, h5, h6, p{
				color: #{$color-white};
			}
			.button:before{
				content: "";
			    background-image: url('https://www.coregas.com.au/dist/images/arrow-white@2x.png');
			    background-size: 100%;
			    background-repeat: no-repeat;
			    position: relative;
			    display: inline-block;
			    width: 26px;
			    height: 16px;
			    top: 3px;
			    left: 0;
			    color: #{$color-white};
			}
		}
		.content.darkText{
			h1, h2, h3, h4, h5, h6, p{
				color: #{$color-dark-grey};
			}
			.button:before{
				content: "";
			    background-image: url('https://www.coregas.com.au/dist/images/arrow-blue@2x.png');
			    background-size: 100%;
			    background-repeat: no-repeat;
			    position: relative;
			    display: inline-block;
			    width: 26px;
			    height: 16px;
			    top: 3px;
			    left: 0;
			    color: #{$color-white};
			}
			.button{
				span{
					color: #{$color-blue};
				}
			}
		}		
	}	
	
}

@media screen and (max-width: 991px) {
	.cta-panel-two-col.img-left{
		.col-right{					
			.content{
				padding-top: 30px;
				padding-bottom: 30px;				
			}			
		}	
		
	}
	.cta-panel-two-col.img-right{
		.col-left{					
			.content{
				padding-top: 30px;
				padding-bottom: 30px;				
			}			
		}	
		
	}
}
