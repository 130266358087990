.content-modules section {
	.login-form	{
		padding: 0;
		&__fields {
			display: flex;
			flex-wrap: wrap;
			margin: 20px -10px 0;
			
		}
		&__field {
			padding: 10px;
			flex: 1 1 300px;
			@media only screen and (min-width: 768px) {
				flex: 1 0 350px;
			}
			@media only screen and (min-width: 1200px) {
				flex: 1 0 400px;
			}
			h3 {
				margin-top: 0;
				label {
	
				}
			}
			input {
				min-width: 0;
				width: 100%;
			}
		}
		.submit.button {
			margin-top: 0;
		}
		&__error {
			margin-top: 10px;
			padding: 10px 15px;
			border: 1px solid $secondary;
			background-color: rgba($secondary, .1);
		}
	}
	.service-center {
		margin-bottom: 40px;
		@media only screen and (min-width: 992px) {
			margin-bottom: 20px;
		}
		&__download {
			padding: 10px 22px ;
			border: 2px solid $secondary;
			display: inline-block;
			position: relative;
			color: $secondary;
			text-align: center;
			transition: all 0.15s ease-in-out;
			margin-top: 20px;
			@media only screen and (min-width: 768px) {
				min-width: 300px;
				padding: 12px 24px;
			}
			span::before {
				position: relative;
				display: inline-block;
				content: "";
				width: 18px;
				height: 18px;
				background-image: url('../../assets/images/icons/Symbol/orange/download-orange.svg');
				background-size: 100% auto;
				background-repeat: no-repeat;
				margin-right: 8px;
				transition: all 0.15s ease-in-out;
				transform: translateY(2px);
			}
			&:hover, &:focus {
				color: $white;
				background-color: $secondary;
				span::before {
					background-image: url('../../assets/images/icons/Symbol/reversed/download-white.svg');
				}
			}
		}
	}
}