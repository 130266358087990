// Page specific style includes

header.page-not-found {
	padding-top: 80px;
	@include for(xs) {
		padding-top: 40px;
	}
	.block-content {
		h1, .blurb {
			width: 100%;
		}
		margin-bottom: 50px;
		@include for(xs) {
			margin-bottom: 20px;
		}
	}
	.button {
		@include button-arrow('blue', 'primary');
		min-width: 300px;
		margin-top: 30px;
		@include for(xs) {
			min-width: 0;
			width: 100%;
			max-width: 300px;
		}
	}
}
