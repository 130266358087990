$specialtyColor: #006FBA;
$specialtyColorDark: #0064a7;

body.specialtyGasesMicrosite {

    /**
     *	Homepage header button
     **/

    header.home .search-wrapper .search-submit {
        background-color: $specialtyColor;
        border-color: $specialtyColor;
    }

    header.home .search-wrapper .search-submit:hover {
        border-color: $specialtyColorDark !important;
    }

    /**
     *	Homepage sub-header nav
     **/
    header.home .block-content .content ul li a span {
        color: $specialtyColor;
	}
	
	header.home .block-content .content ul li a span:hover {
		border-color:$specialtyColor;
	}

    header.home .block-content .content ul li a:before {
        background-image: url(../images/arrow-blue@2x.png);
    }

    /**
    * Homepage Steps
    **/

    section.tngg-steps .content-column .step__icon {
        span {
            color: $specialtyColor;
        }
        &--purchase:before {
            background-image: url(../images/icons/Symbol/blue/icon-purchase.svg);
        }
        &--return:before {
            background-image: url(../images/icons/Symbol/blue/icon-return.svg);
        }
        &--swap:before {
            background-image: url(../images/icons/Symbol/blue/icon-swap.svg);
        }
    } 
    section.tngg-steps a.button {
        @include button-arrow('white', 'primary-blue');
    }
    /**
    * Homepage CTA Large
    **/
    .homepage-modules section.cta-panel-big .block-content .content,
    .homepage-modules section.cta-panel-big .block-content .content:before {
        background-color: $grey-bg;
    }
    /**
    * Homepage end page form
    **/
    section.why-choose-us form {
        margin-bottom: 0px;
        input[type=search] {
            border-color: $specialtyColor;
            &:focus {
                border-color: $specialtyColorDark;
            }
        }
        .search-wrapper .search-submit {
            @include button-arrow('white', 'primary-blue');
        }
        @media only screen and (max-width: 767px) {
            display: inline-block;
            margin-top: 0;
            padding: 20px;
            h3 {
                @include x-rem(font-size, 2.2);
            }
            input[type=search] {
                width: 100%;
                padding: 15px 15px 13px;
                @include x-rem(font-size, 1.6);
            }
            .search-wrapper {
                height: auto;
                .search-btn-wrapper {
                    margin-top: 15px;
                    position: relative;
                    text-align: left;
                    
                }
            }
        }
    }
}
