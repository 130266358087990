/**************************** JOB LISTING And Other CSS ****************************/
a,
a label {
  cursor: pointer;
}

.home-page-slider .slider-content-inner {
  height: 80vh !important;
}

#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: white;
}



.site-nav__nav .mega-menu .mega-menu__level-2 .mega-menu__items {
  width: 100% !important;
  max-width: 100% !important;
}

.home-page-slider .slider-content-inner .banner__content-inner h2 {
  margin-top: 0;
}

.home-page-slider .special_heading {
  font-size: 5.5rem !important;
}

.home-page-slider .special_sub_heading {
  color: #006fba;
  line-height: 0.9;
  font-size: 38px;
  font-weight: 600 !important;
  margin-bottom: 0;
}
.home-page-slider .special_sub_heading {
  color: #fff;
  line-height: 1;
  font-weight: 500;
}

.contact-btn-on-product-pages a.button {
  color: #fff;
  background-color: #ff7300;
  border: 2px solid #ff7300;
  text-decoration: none;
  padding: 16px 20px;
  position: relative;
  transition: all .2s ease-in-out;
  display: inline-block;
  text-align: center;
  min-width: 300px;
  margin-top: 20px;
}

.gases .contact-btn-on-product-pages {
  margin-top: 20px;
}

.contact-btn-on-product-pages {
  font-size: 18px;
  margin: 30px 0 80px 0;
}

.gases .content-modules {
  padding-top: 0;
}

/* Job Listing */
.job-listing {
  .row {
    > div {
      margin-bottom: 30px;
    }
  }

  .single-job {
    border: 1px solid #b9b8b8;
    border-radius: 10px;
    padding: 30px 40px;
    box-shadow: 0 3px 6px #00000029;

    .job-refNum {
      font-size: 16px;
      color: #958f8f;
    }

    .job-title {
      font-size: 30px;
      color: #006fba;
      font-weight: 700;
      margin-top: 20px;
    }

    .job-location {
      font-size: 18px;
      color: #006fba;
    }

    .job-type,
    .job-salary,
    .job-period {
      font-size: 16px;
      color: #3b3b3b;
      font-weight: 500;
    }

    .job-period {
      margin-top: 0;
    }

    .job-closing-Date {
      font-size: 16px;
      color: #006fba;
      margin-top: 30px;
      font-weight: 500;
    }

    .job-desc {
      font-size: 18px;
      color: #3b3b3b;
    }

    a.btn {
      color: #fff;
      background-color: #ff7300;
      border: 2px solid #ff7300;
      text-decoration: none;
      padding: 16px 20px;
      position: relative;
      transition: all .2s ease-in-out;
      display: inline-block;
      text-align: center;
      width: 100%;
      margin-top: 30px;

      &:hover,
      &:focus {
        padding: 16px 5px 16px 30px;
        border: 2px solid #b44b09;
      }

      &::before {
        background-image: url(https://www.coregas.com.au/assets/images/arrow-white@2x.png);
        content: '';
        background-size: 100%;
        background-repeat: no-repeat;
        position: absolute;
        display: inline-block;
        width: 20px;
        height: 12px;
        top: 22px;
        left: 20px;
        transition: all .25s cubic-bezier(.51, .28, .35, .52);
        transform: translateX(-90%);
        opacity: 0;
      }

      &:hover::before {
        transform: none;
        opacity: 1;
      }
    }
  }
}

/**************************** END OF JOB Listing and Other CSS *********************/

.icon.icon-customer-service {
  background-color: #ed7b30;
  color: #ffffff;

  a {
    background-color: #ed7b30;
    color: #ffffff !important;

    &:hover {
      background-color: #ed7b30;
      color: #006fba !important;
    }

    &::after {
      background-image: url(../images/icons/Symbol/blue/phone-icon.png);
    }

    &:hover::after {
      background-image: url(../images/icons/Symbol/blue/phone-icon-blue.png);
    }
  }
}

/********************** TNGG BANNER SLIDER ******************************/
.tngg-banner-title {
  color: #ff7300;
  text-transform: uppercase;
  font-size: 60px;
  font-weight: 900;
  line-height: 1;
  letter-spacing: -2px;

  sup {
    font-size: 25%;
    display: inline-block;
    transform: translateY(-21px);
    margin-left: -12px;
  }
}

h3.tngg-banner-subtitle {
  color: #fff;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 400;
  line-height: 0.9;
  margin-top: 0;
}

.tngg-banner-img {
  margin: 0;
  margin-top: 20px;

  img {
    max-width: 220px;
  }
}

.special_heading.hydrogen-title {
  display: none;
}

.hydrogen-banner-img {
  margin: 0;

  img {
    max-width: 220px;
  }
}

.banner__quicklinks.banner__quicklinks-mobile {
  display: none;

  &.banner__quicklinks-desktop {
    display: block;
  }
}

@media only screen and (min-width: 1200px) {
  .banner__lg-btns {
    bottom: -125px;
  }
}

@media only screen and (max-width: 767px) {
  .tngg-banner-title {
    font-size: 40px;
  }

  .home-page-slider .item-carousel {
    margin-top: 5%;

    .item {
      background-size: cover;
      min-height: 300px;
      padding-top: 20px;

      .banner_heading h1 {
        color: #fff;
      }
    }

    .slider-content-inner {
      height: auto !important;
      padding: 10px !important;
      background-image: inherit !important;

      .banner__content-inner {
        padding: 10px !important;
        margin-top: 0 !important;
        width: 60%;
      }
    }
  }

  .banner__quicklinks.banner__quicklinks-desktop {
    display: none;
  }

  .banner__quicklinks.banner__quicklinks-mobile {
    display: block;
    background-color: #ff7300;
  }

  .tngg-banner-img {
    display: none;
  }

  .special_heading.hydrogen-title {
    display: block;
    font-size: 29px !important;
    text-transform: uppercase;
    font-weight: 800 !important;
  }

  .hydrogen-banner-img {
    display: none;
  }
}
.icon--make-an-order a:after{
    background-image:url('https://www.coregas.co.nz/dist/images/icons/Symbol/blue/list-alt-regular.svg');
}
@media only screen and (max-width: 767px){
    .icon--make-an-order a:after {
        background-image: url('https://www.coregas.co.nz/dist/images/icons/Symbol/reversed/list-alt-white.svg');
    }
}    