@mixin columnCarousel() {
	position: relative;
	@include for(xs) {
		.slick-slide {
			padding-right: 15px;
		}
	}
	h3 {
		@include heading(h5, $primary);
		position: relative;
		padding-left: 40px;
		max-width: 300px;
		margin-top: 15px;
		transition: all .2s ease-in-out;
		@include for(xs) {
			margin-top: 15px;
		}
		&:before {
		    content: "";
		    background-image: url('../../assets/images/arrow-blue@2x.png');
		    background-size: 100%;
		    background-repeat: no-repeat;
		    position: absolute;
		    display: inline-block;
		    width:26px;
		    height:16px;
		    top: 50%;
		    left: 2px;
		    transform: translateY(-50%);
		    color: $white;
		  }
	}
	p {
		margin-top: 10px;
		@include heading(p, $text);
		@include for(xs) {
			padding-left: 40px;
		}
	}
	img {
		width: 100%;
		transition: filter .2s ease-in-out;
	}
	a:focus, a:active {
		outline: none;
	}
	a:focus, a:hover {
		h3 {
			margin-left: 20px;
		}
		img {
		  filter: brightness(70%);
		}
	}

}
@mixin carouselArrows($bg: $secondary){
	position: absolute;
	top: calc( 260px / 2 );
	@include for(md) {
		top: calc( 225px / 2 );
	}
	@include for(sm) {
		top: calc( 170px / 2 );
	}
	width: calc(100% - 30px);
	height: auto;
	pointer-events: none;
	.slick-arrow {
		position: relative;
		background-color: $bg;
		padding: 30px 15px;
		border: none;
		text-indent: -9999px;
		min-width: 40px;
		opacity: 1;
		transition: opacity .2s ease;
		pointer-events: auto;
		&:after {
			content: " ";
			width: 13px;
			height: 8px;
			background-image: url('../../assets/images/chevron-white@2x.png');
			background-size: 13px 8px;
			background-repeat: no-repeat;
			position: absolute;
			display: inline-block;
			
			top: 50%;
			margin-top: 2px;
			
			color: $white;
		}
		&.slick-disabled {
			opacity: 0;
			pointer-events: none;
		}
		&.slick-prev {
			float: left;
			transform: translateX(-25%);
			&:after {
				left: 50%;
				transform: translateY(-50%) translateX(-50%) rotate(90deg);
			}
			
		}
		&.slick-next {
			float: right;
			transform: translateX(25%);
			&:after {
				right: 50%;
				transform: translateY(-50%) translateX(50%) rotate(-90deg);
			}

		}
	}
}

section.news-items {
	position: relative;
	@include for(xs) {
		>.container {
			padding: 0;
		}
	}
	h2 {
		@include heading(light, $primary);
		opacity: 1;
		margin-top: 0;
		margin-bottom: 25px;
		@include for(xs) {
			margin-bottom: 15px;
			padding:0 30px;
		}
	}
	.carousel-nav {
		@include for(xs) {
			width: 100%;
			.slick-dots {
				list-style-type: none;
				display: flex;
				justify-content: center;
				li {
					flex-grow: 1;
					margin-left: 4px;
					max-width: 30px;
					&:first-child {
						margin-left: 0;
					}
					button {
						text-indent: -9999px;
						width: 100%;
						height: 4px;
						background-color: rgba($text, .8);
						opacity: .5;
						border-radius: 0;
						border: none;
						transition: opacity .2s ease-in-out;
						&:hover, &:focus {
							opacity: .8;
						}
					}
					&.slick-active {
						button {
							background-color: $secondary;
							opacity: 1;
							&:hover, &:focus {
								opacity: 1;
							}
						}
					}
				}
			}
		}
		@include for(sm md lg) {
			@include carouselArrows()
		}
	}
	.item-carousel {
		@include columnCarousel()
	}
}