body.tradeNGoGasMicrosite, body.askPat {
	footer {
        .navfooter {
            display: flex;

            @include for(xs) {
                flex-direction: column;
            }

            .top-bar {
                a {
                    margin-right: 40px;
                    width: calc(50% - 40px);
                    height: 60px;

                    @include for(xs) {
                        width: calc(50% - 20px);

                        +a {
                            margin-right: 0;
                            background-position: right;
                        }
                    }
                }

                p {
                    color: $white;
                    margin-top: 40px;
                    @include x-rem(font-size,
                    1.2);
                }

                @include for(sm md) {
                    margin-right: 60px;
                }

                @include for(lg) {
                    margin-right: 180px;
                }
            }

            ul li {
                width: calc(100% / 2 - 20px);

                @include for(xs) {
                    width: calc(100% - 20px);
                }

                ul li {
                    width: 100%;
                }
            }
        }

        .endfooter {
            margin-top: 60px;

            @include for(xs) {
                margin-top: 20px;
            }

            ul {
                justify-content: center;

                li p,
                li a {
                    opacity: 1;
                }

                .first {
                    margin-left: unset;
                    flex-grow: 0;

                    @include for(xs) {
                        margin-bottom: 0;

                        p {
                            padding-bottom: 0;
                        }
                    }

                    &:after {
                        @include for(lg) {
                            display: inline-block;
                        }
                    }

                    +li {
                        @include for(xs) {
                            width: auto;
                            margin-right: 10px;
                        }

                        &:after {
                            @include for(xs) {
                                display: inline-block;
                            }
                        }

                        a {
                            padding-left: 20px;

                            @include for(xs) {
                                padding-left: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}