html.ie10 {
	.content {
		.content-column {
			width: 50%;
			@include for(xs) {
				width: 100%;
			}
			@include for(md) {
				width: 40%;
				 + .content-column {
					width: 60%;
				 }
			}		
		}

	}

	ul.sub-listing  {
		> li.page-item h2, > li.product-page-item h2 {
			line-height: 1.4;
			max-width: calc(100% - 30px);
			display: block;
		}
	}
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
   	ul.sub-listing  {
		> li.page-item h2, > li.product-page-item h2 {
			line-height: 1.4;
			max-width: calc(100% - 30px);
			display: block;
		}
	}
}