@mixin panelBig($bg: $primary, $position: 'left') {
    position: relative;
    height: 40vw;
    min-height: 520px;
    width: 100%;
    overflow-x: hidden;
    -ms-scroll-limit: 0 0 0 0;
    @include for(xs) {
        min-height: auto;
        height: auto;
        overflow: hidden;
        -ms-scroll-limit: 0 0 0 0;
    }
    .panel-bg {
        position: absolute;
        padding-top: 40vw;
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position-y: center;
        top: 0;
        left: 0;
        @if $position=='left' {
            background-position-x: right;
            @include for(sm) {
                background-position-x: 30vw;
            }
            @include for(md) {
                background-position-x: 40vw;
            }
        }
        @else {
            // left: 0;
            background-position-x: left;
            @include for(sm) {
                background-position-x: calc(50% - 40vw);
            }
            @include for(md) {
                background-position-x: calc(50% - 30vw);
            }
        }
        @include for(xs) {
            display: none!important;
            background-image: none !important;
        }
    }
    .panel-bg-xs {
        width: 100%;
        height: 60vw;
        position: relative;
        background-size: cover;
        display: none!important;
        @include for(xs) {
            display: inline-block!important;
        }
        @include for(sm md lg) {
            background-image: none !important;
        }
    }
    .block-content {
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        height: 80%;
        width: 100%;
        @include for(xs) {
            top: -30px;
            transform: none;
            position: relative;
            width: 100%;
        }
        @include for(sm) {
            height: 90%;
        }
        @include for(md) {
            height: 85%;
        }
        >.container {
            height: 100%;
        }
        .content {
            background-color: $bg;
            @if $position=='left' {
                padding: 50px 50px 40px 40px;
            }
            @else {
                padding: 50px 40px 40px 50px;
            }
            display: flex;
            flex-direction: column;
            flex-basis: 1;
            height: 100%;
            width: 50%;
            @include for(sm md) {
                width: 65%;
            }
            @include for(xs) {
                width: 100%;
                display: inline-block;
                padding: 5px 15px 15px;
                margin-bottom: -30px;
            }
            position: relative;
            float: #{$position};
            &:before {
                content: "";
                width: 9999px;
                height: 100%;
                position: absolute;
                #{$position}: -9999px;
                top: 0;
                background-color: $bg;
                @include for(xs) {
                    display: none;
                }
            }
            h2 {
                @include heading(light, $white);
                margin-top: 0;
                &:empty+h3 {
                    margin-top: 0;
                }
            }
            h3 {
                @include heading(h1-home, $white);
                margin-top: 10px;
                line-height: 1;
                &:last-child {
                    margin-bottom: 20px;
                }
                @include for(xs) {
                    margin-bottom: 20px;
                    margin-top: 10px;
                }
            }
            p {
                color: $white;
                @include for(md) {
                    max-width: 500px;
                }
                @include for(xlg) {
                    max-width: 300px;
                }
                &:nth-last-child(2) {
                    margin-bottom: 20px;
                }
            }
            .button {
                @include link-arrow($white, 'white');
                @include x-rem(font-size, 2.2);
                margin-top: auto;
                @include for(xs) {
                    margin-top: 30px;
                }
            }
        }
        a:hover,
        a:focus {
            .button {
                margin-left: 40px;
                margin-right: 0;
            }
        }
    }
}

@mixin panelLittle() {
    .content {
        background-color: $grey;
        padding: 40px 20px;
        display: flex;
        width: 100%;
        @include for(xs sm) {
            flex-wrap: wrap;
        }
        @include for(xs) {
            padding: 20px 20px;
        }
        .content-column {
            padding: 0 20px;
            flex-basis: 1;
            @include for(xs) {
                padding: 0;
                width: 100%;
            }
            @include for(xs sm) {
                +.content-column {
                    margin-top: 40px;
                }
            }
        }
        .media-column {
            padding: 0 20px;
            padding-top: 40px;
            min-width: 100%;
            position: relative;
            @include for(md lg) {
                max-width: 50%;
                min-width: 50%;
                padding-top: 0px;
            }
            @include for(xs) {
                padding: 0;
                width: 100%;
            }
            // Video wrapper styles are in /modules/
            .image-wrapper {
                position: relative;
                @include for(xs) {
                    margin-top: 20px;
                }
            }
            img {
                width: 100%;
            }
            p.caption {
                position: absolute;
                background-color: $grey;
                width: auto;
                display: inline;
                padding: 8px 15px 8px 10px;
                bottom: 0;
                left: 0;
                @include for(xs) {
                    bottom: 0;
                    background-color: $grey;
                    padding-left: 0;
                    @include x-rem(font-size, 1.4);
                }
            }
        }
        h3 {
            @include for(lg) {
                max-width: 400px;
            }
        }
        h2 {
            @include heading(light, $secondary);
            margin-top: 0;
        }
        p {
            @include for(lg) {
                width: 80%;
            }
        }
        .button-container {
            margin-top: 20px;
            @include for(xs) {
                margin-top: 10px;
            }
        }
        .button {
            @include button-arrow('white', 'primary');
            min-width: 300px;
            @include for(xs) {
                min-width: 0;
                width: 100%;
                max-width: 300px;
                span {
                    @include x-rem(font-size, 1.8);
                }
            }
            +br+.button {
                @include button-arrow('white');
            }
        }
    }
}

.homepage-modules,
.content-modules {
    section.cta-panel-big {
        &.left {
            @include panelBig($primary, left);
        }
        &.right {
            @include panelBig($secondary, right);
        }
    }
}

section.cta-panel-small {
    @include panelLittle();
}