$healthcareColor: #006FBA;
$healthcareColorDark: #0064a7;

body.healthcareMicrosite {
    /**
     *	NAV
     **/

    .site-nav--microsite .site-nav__nav,
    .site-nav--microsite .logo,
    .site-nav--microsite .site-nav__nav li.icon a,
    .site-nav--microsite .site-nav__nav li.search.searching form,
    .site-nav--microsite .site-nav__nav li.search form,
    .site-nav--microsite .site-nav__nav li.search input[type=search] {
        background-color: $healthcareColor;
    }

    .site-nav--microsite .site-nav__nav li.icon a,
    .site-nav--microsite .site-nav__nav li a {
        border-top-color: $healthcareColor;
    }

    .site-nav--microsite .site-nav__nav li:hover a,
    .site-nav--microsite .site-nav__nav li.icon:hover a,
    .site-nav--microsite .site-nav__nav li a:focus,
    .site-nav--microsite .site-nav__nav li.icon a:focus {
        background-color: $healthcareColorDark;
        border-top-color: $secondary;
    }

    .site-nav--microsite .site-nav__nav .nav__level2 {
        background-color: $healthcareColorDark;
    }
    .site-nav--microsite .site-nav__nav .nav__level2 li a:hover {
        background-color: $healthcareColor;
    }
    .site-nav--microsite .site-nav__nav li.search.searching {
		.search-button {
			background-color: transparent;
		}
		&:hover {
			border-color:  $healthcareColor;
			background-color: transparent;
			a.search-button {
				border-top-color: $healthcareColor;
				background-color: transparent;
			}
		}
	} 

    /**
     *	Homepage header button
     **/

    header.home .search-wrapper .search-submit {
        background-color: $healthcareColor;
        border-color: $healthcareColor;
    }

    header.home .search-wrapper .search-submit:hover {
        border-color: $healthcareColorDark !important;
    }

    header.home .header-content .button {
        @include button-arrow('white', 'primary-blue');
        @media only screen and (min-width: 768px) {
            display: none;
        }
    }

    /**
     *	Homepage sub-header nav
     **/
    header.home .block-content .content ul li a span {
        color: $healthcareColor;
	}
	
	header.home .block-content .content ul li a span:hover {
		border-color:$healthcareColor;
	}

    header.home .block-content .content ul li a:before {
        background-image: url(../images/arrow-blue@2x.png);
    }

    /**
    * Homepage Steps
    **/

    section.tngg-steps .content-column .step__icon {
        span {
            color: $healthcareColor;
        }
        &--purchase:before {
            background-image: url(../images/icons/Symbol/blue/icon-purchase.svg);
        }
        &--return:before {
            background-image: url(../images/icons/Symbol/blue/icon-return.svg);
        }
        &--swap:before {
            background-image: url(../images/icons/Symbol/blue/icon-swap.svg);
        }
    } 
    section.tngg-steps a.button {
        @include button-arrow('white', 'primary-blue');
    }
    /**
    * Homepage CTA Large
    **/
    .homepage-modules section.cta-panel-big .block-content .content,
    .homepage-modules section.cta-panel-big .block-content .content:before {
        background-color: $healthcareColor;
    }
    /**
    * Homepage end page form
    **/
    section.why-choose-us {
        form {
            input[type=search] {
                border-color: $healthcareColor;
                &:focus {
                    border-color: $healthcareColorDark;
                }
            }
            .search-wrapper .search-submit {
                @include button-arrow('white', 'primary-blue');
            }
        }
        .block-content .content {
            @media only screen and (min-width: 768px) {
                background-color: $healthcareColor;
            }
            .find-store {
                @include button-arrow('white', 'primary-blue');
                @media only screen and (min-width: 768px) {
                   display: none;
                }
            }
        }
    }
}
