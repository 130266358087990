/* iCheck plugin Square skin, black
----------------------------------- */
.icheckbox_square,
.iradio_square {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 13px;
    height: 13px;
    background-image: url(../../assets/images/checkbox-sprites.png);
    background-size: auto 13px ;
    border: none;
    cursor: pointer;
    @include for(xs) {
        width: 42px;
        height: 42px;
        background-size: auto 42px;
        margin-right: 6px;
        background-image: url(../../assets/images/mobile-checkbox-sprites@2x.png);
    }
}


.icheckbox_square {
    background-position: 0 0;
}
    .icheckbox_square.hover {
        background-position: -15px 0;
        @include for(xs) {
            background-position: -46px 0;
        }
    }
    .icheckbox_square.checked {
        background-position: -30px 0;
        @include for(xs) {
            background-position: -92px 0;
        }
    }
    .icheckbox_square.disabled {
        background-position: -74px 0;
        cursor: default;
        @include for(xs) {
            background-position: -230px 0;
        }
    }
    .icheckbox_square.checked.disabled {
        background-position: -96px 0;
    }

.iradio_square {
    background-position: -45px 0;
}
    .iradio_square.hover {
        background-position: -60px 0;
    }
    .iradio_square.checked {
        background-position: -76px 0;
    }
    .iradio_square.disabled {
        background-position: -192px 0;
        cursor: default;
    }
    .iradio_square.checked.disabled {
        background-position: -216px 0;
    }

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi), (min-resolution: 1.25dppx) {
    .icheckbox_square,
    .iradio_square {
        background-image: url(../../assets/images/checkbox-sprites@2x.png);
        -webkit-background-size: 89px 13px;
        background-size: 89px 13px;
        @include for(xs sm) {
            width: 42px;
            height: 42px;
            background-size: auto 42px;
            background-image: url(../../assets/images/mobile-checkbox-sprites@2x.png);
            margin-right: 6px;
        }
    }
    .icheckbox_square.hover {
        @include for(xs) {
            background-position: -46px 0;
        }
    }
    .icheckbox_square.checked {
        @include for(xs) {
            background-position: -92px 0;
        }
    }
    .icheckbox_square.disabled {
        @include for(xs) {
            background-position: -230px 0;
        }
    }
}