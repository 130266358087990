// Bootstrap Grid
@import 'vendor/bootstrap/bootstrap-grid.min.css';
@mixin for($size) {
  @if $size == xlg {
    @media (min-width: 1280px) {
      @content;
    }
  }
}

@include for(xlg) {
	.container {
		width: 1280px;
	}
}