.en-button {
  display: inline-block;
  width: 100%;
  padding: 24px max(3.75rem, 2.932rem + 4.09vw, 6rem);
  margin-block: 1rem;
  border: 0;
  font-size: 18px;
  line-height: 1;
  color: var(--color-white);
  text-align: center;
  text-decoration: none;

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40.521' height='26.343' viewBox='0 0 40.521 26.343'%3E%3Cg id='Group_4' data-name='Group 4' transform='translate(-1018.5 -3708.406)'%3E%3Cpath id='Path_26' data-name='Path 26' d='M3428.3,2917.467l12.111,12.111-12.111,12.111' transform='translate(-2383.511 792)' fill='none' stroke='%23fff' stroke-width='3'/%3E%3Cline id='Line_2' data-name='Line 2' x1='39.121' transform='translate(1018.5 3722.038)' fill='none' stroke='%23fff' stroke-width='3'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat no-repeat;
  background-position: 24px center;
  background-size: 20px;
  background-color: var(--color-primary);
  cursor: pointer;

  @include breakpoint($sm) {
    width: auto;
  }

  &:hover {
    background-color: var(--color-secondary);
  }
}
