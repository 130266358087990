// Page specific style includes
section.store-finder-filter {
	margin-top: 100px;
	margin-bottom: 100px;
	@include for(xs sm) {
		margin-top: 40px;
		margin-bottom: 40px;
	}
	h2 {
		@include heading(h5, $text);
		margin-bottom: 15px;
	}

	form {
		padding: 0 40px;
		margin-bottom: 60px;
		@include for(xs sm) {
			padding: 0px;
			margin-bottom: 20px;
		}
		

		.search-wrapper {
			position: relative;
			width: 100%;

			.search-btn-wrapper {
				position: absolute;
				top: 3px;
				right: 3px;
				text-align: right;
				&:before {
					content: '';
					position: absolute;
					width: 53px;
					height: 53px;
					top: 0px;
					right: 0px;
					transition: background-color .2s ease-in-out;
					background-image: url('../../assets/images/icons/Symbol/grey/search-grey.svg');
					background-position: center;
					background-repeat: no-repeat;
					cursor: pointer;
					pointer-events: none;
					
				}
				&:hover, &.focus {
					&:before {
						background-image: url('../../assets/images/icons/Symbol/orange/search-orange.svg');
					}

				}
				button.search-submit {
					width: 53px;
					height: 53px;
					text-indent: -9999px;
					border: 0;
					background-color: $white;
					&:focus {

					}
				}
			}
		}
		.filter-by {
			margin-top: 20px;
			margin-bottom: 20px;
			@include for(xs sm) {
				margin-bottom: 0;
			}
			h3 {
				@include heading(p, $secondary);
				display: inline-block;
				margin-right: 20px;
				margin-top: 0;
				float: left;
				@include for(md lg) {
					padding-bottom: 30px;
				}
				@include for(xs sm) {
					display: block;
					float: none;
					clear: both;
				}
			}
			.filter-item {
				display: inline-block;
				position: relative;
				@include for(xs) {
					display: block;
				}
				label {
					@include heading(p, $text);
					margin-right: 15px;
					margin-top: 0;
					vertical-align: middle;
					display: inline-block;
					padding-bottom: 20px;
					position: relative;
					cursor: pointer;
					span {
						position: relative;
						top: 1px;
					}
					&:last-child {
						margin-right: 0px;
					}
					@include for(xs) {
						width: 100%;
						width: calc(100% - 46px);
						padding: 6px 0;
						margin-right: 0;
					}
					&:hover {
						span {
							color: $secondary;
						}
						@include for(sm md lg) {
							+ .helper-icon + .tooltip {
								display: block;
							}
						}
					}
				}
				input {
					margin-left: 15px;
				}
				.helper-icon {
					display: none;
					@include for(xs) {
						display: inline-block;
						width: 36px;
						height: 36px;
						right: 0px;
						position: absolute;
						top: 18px;
						padding: 8px;
						border-radius: 50%;
						cursor: pointer;
					}
				}
				.tooltip {
					display: none;
					position: absolute;
					top: 35px;
					width: 200px;
					background: $primary;
					color: white;
					font-size: 1.2rem;
					padding: 10px;
					z-index: 99;
					&.active {
						display: block;
						width: 100%;
						top: 70px;
						padding-right: 28px;
						&:before {
							top: -20px;
							right: 7px;
							left: initial;
							width: 20px;
							height: 20px;
						}
					}
					&:before {
						position: absolute;
						top: -20px;
						left: 18px;
						width: 20px;
						height: 20px;
						content: "";
						border-bottom: 10px solid $primary;
						border-top: 10px solid rgba(0, 0, 0, 0);
						border-left: 10px solid rgba(0, 0, 0, 0);
						border-right: 10px solid rgba(0, 0, 0, 0);
					}
					.close-tooltip {
						display: none;
						@include for(xs) {
							display: block;
							cursor: pointer;
							position: absolute;
							padding: 12px;
							right: 0;
							top: 0;
							font-weight: 700;
							font-size: 14px;
						}
					}
				}
			}
		}
		input[type=search] {
			width: 100%;
			padding: 18px 20px 15px 20px;
			color: $text;
			border: 2px solid $text;
			display: inline-block;
			box-sizing:border-box;
			-webkit-appearance: none;
  			border-radius: 0;
			@include x-rem(font-size, 1.8);
			&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			  color: rgba($text, .2);
			}
			&::-moz-placeholder { /* Firefox 19+ */
			  color: rgba($text, .2);
			}
			&:-ms-input-placeholder { /* IE 10+ */
			  color: rgba($text, .2);
			}
			&:-moz-placeholder { /* Firefox 18- */
			  color: rgba($text, .2);
			}
		}
	}

	.results-info {
		margin-bottom: 20px;
		margin-left: 40px;
		@include for(xs sm) {
			margin-left: 0;
		}
	}


	.stores {
		width: 100%;
		overflow: hidden;
		margin-top: 20px;
		position: relative;
		
		@include for(xs sm) {
			margin-top: 20px;
		}
		.store-list {
			display: inline-block;
			float: left;
			
			margin-top: 0;

			// Scroll
			height: 830px;
			width: calc(100% - 660px);
			overflow-y: scroll;
			padding-bottom: 50px;

			&:after {
				content: '';
				width: calc(100% - 670px);
				height: 50px;
				position: absolute;
				top: 780px;
				left: 0;
				background: -moz-linear-gradient(bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
				background: -webkit-linear-gradient(bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
				background: linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
			}

				
			@include for(xs) {
				width: 100%;
				overflow: auto;
				height: auto;
				&:after {
					display: none;
				}
			}
			@include for(sm) {
				height: 540px;
				width: calc(100% - 340px);
				&:after {
					top: 490px;
					width: calc(100% - 350px);
				}
			}
			@include for(md) {
				height: 630px;
				width: calc(100% - 500px);
				&:after {
					top: 580px;
					width: calc(100% - 510px);
				}
			}
			
			
			&.has-scroll {
			&::-webkit-scrollbar {
		        -webkit-appearance: none;
		        width: 7px;
		       	background: $grey;
		       	border-radius: 4px;
		       	-webkit-box-shadow: inset 1px 0px 3px 0px rgba(0,0,0,0.2);
				-moz-box-shadow: inset 1px 0px 3px 0px rgba(0,0,0,0.2);
				box-shadow: inset 1px 0px 3px 0px rgba(0,0,0,0.2);
		    }
		    &::-webkit-scrollbar-thumb {
		        border-radius: 4px;
		        height: 50px;
		        background-color: $primary;
		    }
				
			}
			
			li.show-more {
				display: none;
				@include for(xs) {
					display: block;
				}
				a {
					width: 100%;
					background-color: $primary;
					@include heading(h5, $white);
					border:2px solid $primary;
					margin-top: 0;
					padding: 10px 20px;
					display: inline-block;
					text-align: center;
					position: relative;
					transition: .2s all ease-in-out;
					&:before {
						content: '';
						width: 20px;
						height: 20px;
						background-position: center;
						background-repeat: no-repeat;
						background-size: 20px auto;
						position: absolute;
						top: 10px;
						left: 10px;
						background-image: url('../../assets/images/icons/Symbol/reversed/arrow-down-white.svg');

					}
					&:focus, &:active {
						background-color: $white;
						@include heading(h5, $primary);
						margin-top: 0;
						&:before {
							background-image: url('../../assets/images/icons/Symbol/blue/arrow-down-blue.svg');
						}
					}
				}
			}
			li.store {
				padding: 20px 40px;
				position: relative;
				border-top: 1px solid rgba($text, .7);
				margin-right: 20px;
				transition: background-color .2s ease-in-out;
				@include for(xs) {
					&.show-me {
						display: none;
					}
				}
				&:before {
					content: '';
					position: absolute;
					height: calc(100% + 1px);
					width: 20px;
					top: -1px;
					right: -20px;
					transition: background-color .2s ease-in-out;
					background-color: $white;
					@include for(xs) {
						display: none;
					}
				}
				@include for(sm) {
					padding: 20px 20px;
				}
				@include for(xs) {
					padding: 10px 0px;
					margin-right: 0;
				}
				h2 {
					@include heading(p, $primary);
					a {
						color: inherit;
						margin-top: 0;
						display: inline;
					}
					margin-top: 0;
					position: relative;
					padding-left: 30px;
					&:before {
						content: '';
						width: 25px;
						height: 25px;
						background-position: center left;
						background-repeat: no-repeat;
						background-size: 20px auto;
						position: absolute;
						top: 0;
						left: 0;

					}
					&.branch,
					&.depot {
						&:before {
							background-image: url('../../assets/images/icons/Symbol/blue/shop-blue.svg');
						}
					}
					&.tradeNGoGasStockist {
						&:before {
							background-image: url('../../assets/images/icons/Symbol/blue/house-blue.svg');
						}
					}
					&.distributor {
						&:before {
							background-image: url('../../assets/images/icons/Symbol/blue/lorry-blue.svg');
						}
					}
					&.medical {
						&:before {
							background-image: url('../../assets/images/icons/Symbol/blue/medical-blue.svg');
						}
					}
					&.serviceCentreNz {
						&:before {
							background-image: url('../../assets/images/icons/Symbol/blue/shop-blue.svg');
						}
					}

				}
				.get-directions {
					@include heading(p, $primary);
					text-decoration: none;
					display: inline-block;
					span {
						text-decoration: underline;
						position: relative;
						padding-left: 25px;
						&:before {
							content: '';
							width: 20px;
							height: 20px;
							background-position: center;
							background-repeat: no-repeat;
							background-size: 20px auto;
							position: absolute;
							top: 0px;
							left: 0;
							background-image: url('../../assets/images/icons/Symbol/blue/location-pin-blue.svg');
						}
					} 
					&:hover, &:focus {
						@include heading(p, $secondary);
						span:before {
							background-image: url('../../assets/images/icons/Symbol/orange/location-pin-orange.svg');
						}
					}
				}
				.phone, .fax, .email, .website {
					position: relative;
					padding-left: 30px;
					margin-right: 10px;
					text-decoration: none;
					display: block;
					&:before {
						content: '';
						width: 25px;
						height: 20px;
						background-position: center;
						background-repeat: no-repeat;
						background-size: 20px auto;
						position: absolute;
						top: 2px;
						left: 0;
					}
				}

				.phone:before {
					background-image: url('../../assets/images/icons/Symbol/blue/phone-blue.svg');
				}
				.fax:before {
					background-image: url('../../assets/images/icons/Symbol/blue/data-sheet-blue.svg');
					top: 3px;
				}
				.email:before {
					background-image: url('../../assets/images/icons/Symbol/blue/email-icon-blue.svg');
					top: 3px;
				}
				.website:before {
					background-image: url('../../assets/images/icons/Symbol/blue/website-icon-blue.svg');
					top: 3px;
				}
				.available-ranges, .available-sizes {
					margin-top: 20px;
					.title {
						display: inline;
						color: $secondary;
						font-size: 1.8rem;
					}
					.range {
						display: inline;
						li {
							display: inline;
						}
						span {
							position: relative;
							padding-left: 12px;
							margin-right: 12px;
							&:before {
								content: "";
									position: absolute;
									top: 7px;
									left: 0;
									width: 6px;
									height: 9px;
									background-image: url(../../assets/images/chevron-text@2x.png);
									background-repeat: no-repeat;
									background-size: 100%;
									display: inline-block;
							}
						}
						@include for(xs) {
							display: inline-block;
						}
					}
				}
				&:first-child {
					border-top: 1px solid $grey;
					&:not(.active) {
						border-top: 1px solid $white;
					}
					@include for(xs) {
						border-top: 1px solid $white;
					}
				}
				@include for(sm md lg) {
					&:not(.active) {
						&:hover, &:focus {
							cursor: pointer;
							h2 {
								color: $secondary;
								text-decoration: underline;
								&.branch,
								&.depot {
									&:before {
										background-image: url('../../assets/images/icons/Symbol/orange/shop-orange.svg');
									}
								}
								&.tradeNGoGasStockist {
									&:before {
										background-image: url('../../assets/images/icons/Symbol/orange/house-orange.svg');
									}
								}
								&.distributor {
									&:before {
										background-image: url('../../assets/images/icons/Symbol/orange/lorry-orange.svg');
									}
								}
								&.medical {
									&:before {
										background-image: url('../../assets/images/icons/Symbol/orange/medical-orange.svg');
									}
								}
								&.serviceCentreNz {
									&:before {
										background-image: url('../../assets/images/icons/Symbol/orange/shop-orange.svg');
									}
								}
							}
						}
						
					}
				}
				
				&.active {
					background-color: $grey;
					border-top: 1px solid $grey;
					&:before {
						background-color: $grey;
					}
					h2 {
						@include heading(p, $secondary);
						margin-top: 0;
						a {
							pointer-events: none;
						}
						&.branch,
						&.depot {
							&:before {
								background-image: url('../../assets/images/icons/Symbol/orange/shop-orange.svg');
							}
						}
						&.tradeNGoGasStockist {
							&:before {
								background-image: url('../../assets/images/icons/Symbol/orange/house-orange.svg');
							}
						}
						&.distributor {
							&:before {
								background-image: url('../../assets/images/icons/Symbol/orange/lorry-orange.svg');
							}
						}
						&.medical {
							&:before {
								background-image: url('../../assets/images/icons/Symbol/orange/medical-orange.svg');
							}
						}
						&.serviceCentreNz {
							&:before {
								background-image: url('../../assets/images/icons/Symbol/orange/shop-orange.svg');
							}
						}
					}
					+ li {
						border-top: 1px solid $white;
					}
					+ li.show-more + li {
						border-top: 1px solid $white;
					}
					@include for(xs) {
						background-color: $white;
						border-top: 1px solid rgba($text, .7);
						&:before {
							display: none;
						}
						+ li.store {
							border-top: 1px solid rgba($text, .7);
						}
						+ li.show-more + li {
							border-top: 1px solid rgba($text, .7);
						}
						h2 {
							@include heading(p, $primary);
							&.branch,
							&.depot {
								&:before {
									background-image: url('../../assets/images/icons/Symbol/blue/shop-blue.svg');
								}
							}
							&.tradeNGoGasStockist {
								&:before {
									background-image: url('../../assets/images/icons/Symbol/blue/house-blue.svg');
								}
							}
							&.distributor {
								&:before {
									background-image: url('../../assets/images/icons/Symbol/blue/lorry-blue.svg');
								}
							}
							&.medical {
								&:before {
									background-image: url('../../assets/images/icons/Symbol/blue/medical-blue.svg');
								}
							}
							&.serviceCentreNz {
								&:before {
									background-image: url('../../assets/images/icons/Symbol/blue/shop-blue.svg');
								}
							}
						}
					}
				}
			}
		}
		.map {
			display: inline-block;
			float: right;
			background-color: $grey;
			padding: 30px;
			#storeLocator {
				margin-bottom: 10px;
				border: 1px solid $text;
			}
			@include for(xs) {
				display: none;
			}
			@include for(sm) {
				width: 340px;
				padding: 20px;
				#storeLocator {
					width: 300px!important;
					height: 400px!important;
				}
			}
			@include for(md) {
				width: 500px;
				#storeLocator {
					width: 440px!important;
					height: 600px!important;
				}
			}
			h3 {
			 	@include heading(p, $secondary);
			 	display: inline-block;
			 	width: 160px;
			 	float: left;
			 	@include for(sm md) {
					width: 100%;
					float: none;
				}
			}
			ul {
				display: inline-block;
				width: calc(100% - 161px);
				float: left;
				margin-bottom: 0;
				max-width: 400px;
				@include for(sm md) {
					width: 100%;
					float: none;
				}
				li {
					display: none;
					&.active {
						display: block;
					}
					span {
						position: relative;
						padding-left: 12px;
						margin-right: 12px;
						&:before {
							content: "";
						    position: absolute;
						    top: 7px;
						    left: 0;
						    width: 6px;
						    height: 9px;
						    background-image: url(../../assets/images/chevron-text@2x.png);
						    background-repeat: no-repeat;
						    background-size: 100%;
						    display: inline-block;
						}
					}
					@include for(sm) {

						span {
							display: block;
						}
					}
				}
			}
		}
	}
}

section.storefinder-how-to-buy {
	margin-bottom: 100px;
	@include for(xs) {
		margin-bottom: 40px;
	}
	.content-column {
		@include for(md lg) {
			padding-top: 0px;
			min-width: 100%;
			h3 {
				max-width: 300px;
			}
			.button {
				min-width: 200px;
				+ br {
					display: none;
				}
				~ .button {
					margin-left: 15px;
					min-width: 300px;
				}
			}
		}
	}
}