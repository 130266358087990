.header-image-container .header-image, .header-image-container .product-image, .product-image-container .header-image, .product-image-container .product-image{
    height:470px;
}

.place-order{
    font-size:14px;
    margin-bottom:50px;

    select,
    input{
        padding:10px 5px;
        margin-right:5px;
        width:100%;
    }

    h4{
        font-size:1.8rem;
    }

    .col-md-3,
    .col-md-2,
    .col-md-6,
    .col-md-4,
    .col-md-1
    {
        padding-left:7px !important;
        padding-right:7px !important;
    }

    .m-0{
        margin-left:0;
        margin-right:0;
    }

    .mb-0{
        margin-bottom:0 !important;
    }

    .p-0{
        padding-left:0 !important;
        padding-right:0 !important;
    }

    .pl-0{
        padding-left:0 !important;
    }

    .pr-0{
        padding-right:0 !important;
    }

    .row{
        margin-bottom:10px;
    }

    input[type="date"]:before {
        content: attr(placeholder) !important;
        color: #aaa;
        margin-right: 0.5em;
    }

    input[type="date"]:focus:before,
    input[type="date"]:valid:before {
        content: "";
    }

    .product-index{
        margin-right:10px;
        display:inline-block;
        width:9px;
    }

    .remove-btn{
        border:1px solid black;
        border-radius:5px;
        display:inline-block;
        padding:10px;
        cursor:pointer;
        span:first-of-type{
            margin-right:5px;
        }
    }

    .product-detail-title{
        color:#006FBA;
        margin:10px 0;
        text-transform:uppercase;
    }

    .single-product{
        margin-bottom:10px;
    }

    .add-btn{
        padding:10px 20px;
        color:#fff;
        background-color:#006FBA;
        display:inline-block;
        cursor:pointer;
        span:first-of-type{
            margin-right:10px;
        }
    }

    .your-detail{
        margin-top:50px;
    }

    .order-info-wrapper{
        position:relative;
    }

    .have-no-account{
        position:absolute;
        right:0;
        display:inline-block;
        margin:0px;
        text-decoration:underline;
        color:#FF7300;
    }

    .detail-row{
        margin-bottom:10px;
    }

    .order-info-wrapper .dont-know-account{
        font-size:1.8rem;
        p{
            color:#7D7D7D;
        }
        a{ 
            text-decoration:underline;
            color:#7D7D7D;
        }
    }

    .delivery-address-wrapper{
        margin-top:30px;
    }

    .tAndC{
        margin:20px 0;
        font-size:1.8rem;
    }

    .submit.bm-button{
        color: #fff;
        font-size:1.8rem;
        background-color: #FF7300;
        text-decoration: none;
        padding: 16px 20px;
        display: inline-block;
        text-align: center;
        margin-top: 10px;
        position: relative;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        border: none!important;
        margin-left: 20px;
        min-width: 220px;
        &:hover{
            padding: 16px 5px 16px 30px;
            background-color: #FF5300;
        }
        &:before{
            background-repeat: no-repeat;
            content: "";
            background-image: url(../../images/arrow-white@2x.png);
            background-size: 100%;
            position: absolute;
            display: inline-block;
            width: 20px;
            height: 12px;
            top: 22px;
            left: 20px;
            -webkit-transition: all .25s cubic-bezier(.51,.28,.35,.52);
            transition: all .25s cubic-bezier(.51,.28,.35,.52);
            -webkit-transform: translateX(-90%);
            transform: translateX(-90%);
            opacity: 0;
        }
        &:hover:before{
            transform:none;
            opacity:1;
        }
    }

    .disclaimer{
        color: #006FBA;
        text-decoration: underline;
    }
    .error-list li{
        list-style:none; 
        color: #FF7300;
        
    }
}


@media (max-width:767px){
    .place-order .col-md-3,
    .place-order .col-md-2,
    .place-order .col-md-6,
    .place-order .col-md-4,
    .place-order .col-md-1
    {
        padding-top:7px !important;          
    }
    .place-order .row{
        margin-bottom:0;
    }
    #order-form-wrapper .row{
        margin-bottom:10px;
    }
    .place-order .pl-0,
    .place-order .pr-0{
        padding-left:0px !important;
        padding-right:0px !important;
    }
}
