.en-enquiry-block {
  h2 {
    margin: 0;
    line-height: 1;
    color: var(--color-secondary);
    font-weight: 500;
  }

  h3 {
    margin-block: 1rem;
    font-size: 22px;
    font-weight: 600;
    color: var(--color-secondary);
  }

  h6 {
    margin-bottom: 0;
    margin-block: 1.5em;
    font-size: 18px;
    color: var(--color-secondary);
    font-weight: 600;

  }

  .heading {
    .en-column {
      margin-top: 8px;
    }

    p {
      margin: 0;
    }
  }

  &__form {
    margin-top: 32px;

    p {
      margin-bottom: 0;
    }

    &__columns {
      row-gap: 16px;
      margin-top: 32px;
    }

    &__input {
      margin-top: 8px;

      &:nth-child(6) {
        grid-row: span 2;
      }

      label {
        display: block;
        text-transform: uppercase;
        line-height: 1.2;
        font-size: 1.6rem;
        color: #719BCB;
      }

      input,
      textarea,
      select {
        display: block;
        width: 100%;
        padding: 16px 16px;
        margin-top: 4px;
        border: 0;
        border-radius: 0;
        background-color: var(--color-bg);
        font-size: 1.6rem;

        @include breakpoint($md) {
          margin-top: 8px;
        }

        &:focus {
          border: none;
          outline: none;
        }
      }

      textarea {
        height: 140px;
      }
    }

    &__captcha {
      margin-top: 32px;
      img {
        width: 320px;
      }
    }
  }
}
