// Page specific style includes
section.product-overview {
	.content {
		display: flex;
		width: 100%;
		margin-left: -20px;
		margin-right: -20px;

		@include for(xs sm) {
			flex-wrap: wrap;
			margin-left: 0;
			margin-right: 0;
		}

		@include for(xs) {
			padding: 0;
			background-color: transparent;
		}

		.content-column {
			@include for(md lg) {
				order: 1;
				padding: 0 20px;
			}

			@include for(xs) {
				padding: 0;
				width: 100%;
			}
		}

		.media-column {
			min-width: 50%;
			position: relative;
			text-align: center;

			@include for(md lg) {
				min-width: 20%;
				order: 0;
				padding: 40px 20px 0;

				&.videoMedia {
					min-width: 50%;
				}
			}

			@include for(sm) {
				&.videoMedia {
					min-width: 100%;
					margin-top: 40px;
				}
			}

			@include for(xs) {
				padding: 0;
				width: 100%;
			}

			.caption {
				@include heading(light, $primary);
			}
		}
	}

	.image-wrapper {
		img {
			max-width: 100%;
		}
	}

	h2 {
		@include x-rem(font-size, 4);
		line-height: 1;
		margin-top: 40px;

		@include for(xs) {
			@include x-rem(font-size, 3);
		}
	}

	h3 {
		@include heading(h5, $primary);
		margin-top: 20px;
	}

	div.formula {
		@include heading(h5, $primary);
		margin-top: 20px;

		* {
			@include heading(h5, $primary);
			margin-top: 20px;

			sub,
			sup {
				@include x-rem(font-size, 1.6);
			}
		}
	}

	.uses {
		ul {
			overflow: hidden;

			li {
				padding-left: 15px;
				position: relative;
				margin-bottom: 8px;
				width: 50%;
				float: left;

				&:before {
					content: '· ';
					position: absolute;
					left: 0;
				}

				@include for(xs) {
					width: 100%;
					margin-bottom: 0px;
				}
			}
		}
	}
}

section.product-tabs {
	margin-top: 100px;

	@include for(xs) {
		margin-top: 45px;
	}

	.image-wrapper {
		img {
			max-width: 100%;
		}
	}

	.panel-tabs {
		overflow: hidden;

		@include for(xs) {
			display: none;
		}

		.panel-tab {
			text-align: center;
			margin-top: 0;
			width: 50%;
			border-top: 2px solid $grey;
			border-left: 2px solid $grey;
			border-right: 2px solid $grey;
			float: left;

			a {
				@include heading(h4, $text);
				margin-top: 0;
				padding: 20px;
				display: inline-block;
				width: 100%;
				transition: all .2s ease-in-out;

				&:hover,
				&:focus {
					background-color: rgba($grey, .5);
				}

				&:active {
					color: rgba($text, .8);
				}
			}

			&.active {
				background-color: $grey;
				pointer-events: none;

				a {
					color: $secondary;
				}
			}
		}
	}

	.panels {
		background-color: $grey;
		overflow: hidden;
		padding: 60px 0;

		@include for(lg) {
			padding: 100px 0;
		}

		@include for(xs) {
			padding: 30px 0;
		}

		a.heading {
			display: none;
			@include heading(h3, $text);

			@include for(xs) {
				@include x-rem(font-size, 2.6);
				display: block;
				margin-top: 0;
			}
		}

		h4 {
			@include heading(h5, $primary);
			margin-top: 20px;
		}

		.panel {
			display: none;

			&.active {
				display: block;
			}

			@include for(xs) {
				display: block;
			}
		}

		.components {
			width: 100%;
			display: flex;
			margin-bottom: 60px;

			@include for(xs) {
				flex-direction: column;
				margin-bottom: 10px;
			}

			.product-summary-thumbnail {
				width: 200px;
				height: auto;

				@include for(xs) {
					display: none;
				}
			}

			.set {
				width: calc(50% - 140px);
				margin-left: 40px;

				@include for(md lg) {
					width: calc(40% - 140px);
				}

				@include for(xs) {
					margin-left: 0;
					width: 100%;
					margin-top: 15px;
				}

				h4 {
					margin-top: 0;
				}
			}
		}

		p.scroll {
			display: none;

			@include for(xs sm) {
				display: inline-block;
				float: right;
				position: absolute;
				top: 3px;
				right: 22px;
				@include heading(light, $primary);

				&:after {
					content: '';
					width: 15px;
					height: 15px;
					position: absolute;
					top: 2px;
					right: -22px;
					background-image: url('../../assets/images/icons/Symbol/blue/arrow-right-blue.svg');
					background-size: 100%;
					background-repeat: no-repeat;
					background-position: right center;
				}
			}
		}

		.table-wrapper {
			overflow: hidden;
			width: 100%;
			margin-top: 10px;
			position: relative;
			margin-bottom: 40px;

			&.hide {
				display: none;
			}

			a.data-sheet {
				position: relative;
				padding-left: 25px;
				display: inline-block;

				&:before {
					content: '';
					width: 20px;
					height: 20px;
					position: absolute;
					top: 3px;
					left: 0;
					background-image: url('../../assets/images/icons/Symbol/grey/data-sheet-grey.svg');
					background-size: 100%;
					background-repeat: no-repeat;
				}

				span {
					text-decoration: underline;
				}

				&:hover,
				&:focus {
					color: $secondary;

					&:before {
						background-image: url('../../assets/images/icons/Symbol/orange/data-sheet-orange.svg');
					}
				}
			}

			.table-heading {
				display: flex;
				justify-content: flex-end;

				h4 {
					margin-left: 0;
					margin-right: auto;
				}

				.un-number,
				.data-sheet {
					margin-top: 20px;
					margin-left: 60px;
					@include x-rem(font-size,
					1.8);

					@include for(xs sm) {
						display: none;
					}
				}
			}

			.table-footer {
				@include for(md lg) {
					display: none;
				}
			}

			.sizes-table {
				@include for(xs sm) {
					overflow-x: scroll;
					-webkit-overflow-scrolling: touch;
					margin-right: -15px;
				}

				table {
					border-top: 2px solid $primary;
					width: 100%;
					margin-top: 20px;
					text-align: left;

					@include for(xs sm) {
						min-width: 700px;
					}

					thead {
						th {
							padding: 15px 30px;

							@include for(md) {
								padding: 15px 20px;
							}

							@include for(xs sm) {
								padding: 10px 15px;
							}

							@include heading(light,
							$text);
							opacity: 1;
							@include x-rem(font-size,
							1.2);

							&.volume {
								min-width: 130px;
							}
						}
					}

					tbody {
						background-color: $white;

						tr {
							border-bottom: 5px solid $grey;

							td {
								padding: 20px 30px;
								@include x-rem(font-size,
								1.6);

								@include for(md) {
									padding: 20px 20px;
								}

								@include for(xs sm) {
									padding: 10px 15px;
								}

								a {
									@include heading(p,
									$secondary);
									position: relative;
									padding: 10px;
									padding-left: 25px;
									margin-top: 0;
									@include x-rem(font-size,
									1.6);

									@include for(xs sm) {
										padding: 20px;
										display: inline-block;

										span {
											display: none;
										}
									}

									&:hover,
									&:focus {
										span {
											text-decoration: underline;
										}
									}

									&:before {
										content: " ";
										width: 16px;
										height: 16px;
										background-size: 100%;
										background-repeat: no-repeat;
										position: absolute;
										display: inline-block;
										left: 0;
										top: 50%;
										transform: translateY(-50%);
										background-image: url('../../assets/images/icons/Symbol/orange/download-orange.svg');

										@include for(xs sm) {
											width: 20px;
											height: 20px;
											left: 50%;
											transform: translateY(-50%) translateX(-50%);
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.properties-table {
			table {
				width: 100%;
				margin-top: 20px;
				margin-bottom: 40px;

				@include for(xs) {
					margin-top: 10px;
					margin-bottom: 0;
				}

				thead {
					background-color: $primary;

					@include for(xs) {
						display: none;
					}

					th {
						padding: 20px 30px;
						border-left: 2px solid $grey;

						&:first-child {
							border-left: none;
						}

						@include for(sm) {
							padding: 10px 20px;
						}

						@include heading(light,
						$white);
						opacity: 1;
					}
				}

				tbody {
					background-color: $white;

					@include for(xs) {
						background-color: transparent;
					}

					tr {
						border-bottom: 5px solid $grey;

						@include for(xs) {
							display: inline-block;
							width: 100%;
						}

						td {
							span {
								display: none;

								@include for(xs) {
									display: block;
									@include heading(light,
									$primary);
									margin-top: 0;
								}
							}

							border-left: 2px solid $grey;

							&:first-child {
								border-left: none;
							}

							text-align: center;
							padding: 20px 30px;

							@include for(sm) {
								padding: 10px 20px;
							}

							@include for(xs) {
								padding: 10px;
								display: inline-block;
								text-align: left;
								width: calc(50% - 3px);
								border: none;
								float: left;
								text-overflow: ellipsis;
								overflow: hidden;

								&:nth-child(2n+1) {
									clear: both;
									padding-left: 0;
								}

								span {
									margin-top: 0;
									text-overflow: ellipsis;
									overflow: hidden;
								}
							}
						}
					}
				}
			}
		}

		.information,
		.hazard-symbols {
			width: 100%;
			float: left;

			@include for(md lg) {
				width: calc(50% - 20px);
			}

			a {
				position: relative;
				padding-left: 30px;
				display: inline-block;

				&:before {
					content: '';
					width: 20px;
					height: 20px;
					position: absolute;
					top: 2px;
					left: 0;
					background-image: url('../../assets/images/icons/Symbol/grey/data-sheet-grey.svg');
					background-size: 100%;
					background-repeat: no-repeat;
				}

				span {
					text-decoration: underline;
				}

				&:hover,
				&:focus {
					color: $secondary;
				}
			}
		}

		.information {
			@include for(md lg) {
				margin-right: 40px;
			}

			.data-sheet {
				width: 100%;
				margin-bottom: 20px;
			}

			.un-number,
			.hazard-class {
				width: calc(50% - 20px);
				display: inline-block;
				margin-bottom: 20px;
				vertical-align: top;

				@include for(xs) {
					width: 100%;
				}
			}

			.hazard-class {
				margin-left: 20px;

				@include for(xs) {
					margin-left: 0;
				}
			}
		}

		ul.hazards {
			li {
				display: block;
				position: relative;
				padding-top: 25px;
				padding-bottom: 25px;
				padding-left: 70px;

				@include for(xs) {
					padding-top: 10px;
					padding-bottom: 10px;
					padding-left: 50px;
				}
			}

			.hazard-symbol-img {
				width: 60px;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;

				@include for(xs) {
					width: 40px;
				}
			}
		}
	}

	.accordion-item {
		@include for(xs) {
			border-top: 2px solid $primary;

			a.heading {
				padding-top: 15px;
				padding-bottom: 15px;
				position: relative;
				width: 100%;
				padding: 20px 50px 20px 0px;

				&:after {
					content: "";
					position: absolute;
					top: 50%;
					right: 10px;
					transform: translateY(-50%) rotate(45deg);
					width: 22px;
					height: 22px;
					background-image: url('../../assets/images/icons/Symbol/blue/accordian-open-blue.svg');
					background-size: 100%;
					display: inline-block;
					pointer-events: none;
					transition: transform .3s $customEase;
				}
			}

			.show-content {
				overflow: hidden;
				display: none;
				padding-bottom: 15px;
			}

			&.open {
				a.heading:after {
					transform: translateY(-50%) rotate(0deg);
				}
			}
		}
	}

	.panel:last-child .accordion-item {
		@include for(xs) {
			border-bottom: 2px solid $primary;
		}
	}
}

section.more-info {
	overflow: hidden;
	padding-bottom: 80px;

	@include for(sm) {
		padding-bottom: 30px;
	}

	@include for(xs) {
		padding-bottom: 20px;
	}

	.text-content {
		width: 40%;
		float: left;

		h3 {
			@include heading(h4,
			$primary);
		}

		h4 {
			@include heading(h5,
			$text);
		}

		h5 {
			@include heading(p,
			$text);
			font-weight: 600;
		}

		h2,
		h3,
		h4,
		h5 {
			margin-top: 20px;
		}

		a {
			color: $secondary;

			&:hover,
			&:focus {
				text-decoration: underline;
			}

			&:active {
				color: $secondary-dark;
			}
		}

		@include for(lg) {
			width: 50%;
		}

		@include for(xs sm) {
			width: 100%;
			float: none;
		}
	}

	ul.resources {
		width: calc(60% - 40px);
		margin-left: 40px;
		float: left;
		flex-wrap: wrap;
		margin-top: 0;
		display: flex;

		@include for(lg) {
			width: calc(50% - 40px);
		}

		@include for(xs sm) {
			margin-top: 10px;
		}

		li {
			flex: 1 0 50%;

			@include for(xs) {
				width: 100%;
			}
		}

		@include for(xs sm) {
			width: 100%;
			float: none;
			margin-left: 0;
		}

		@include for(sm) {
			flex-direction: row;
		}
	}

	&.grey {
		background-color: $grey;
		padding: 100px 0;

		@include for(xs) {
			padding: 40px 0;
		}
	}

	&:not(.grey) {
		.content {
			@include for(md lg) {
				padding: 0 40px;
			}
		}
	}

	h2.light {
		@include heading(light,
		$secondary);
	}

	ul,
	ol {
		&:not(.resources) {
			li {
				margin-bottom: 15px;

				&:last-child {
					margin-bottom: 0;
				}

				a {
					color: $secondary;

					&:hover,
					&:focus {
						text-decoration: underline;
					}

					&:active {
						color: $secondary-dark;
					}
				}

				ul,
				ol {
					margin-top: 10px;
					margin-left: 15px;

					li {
						margin-bottom: 10px;
					}
				}
			}
		}
	}

	ul:not(.resources) {
		li {
			padding-left: 15px;
			position: relative;
			margin-bottom: 8px;

			&:before {
				content: '· ';
				position: absolute;
				display: inline-block;
				left: 0;
			}
		}
	}

	ul.resources {
		display: flex;
		margin-top: 20px;

		@include for(xs) {
			margin-top: 0;
			flex-direction: column;
		}

		li {
			.text {
				position: absolute;
				top: 50%;
				left: 135px;
				transform: translateY(-50%);

				@include for(xs) {
					left: 90px;
				}
			}

			h3 {
				@include heading(p,
				$text);
				margin-top: 0;

				@include for(xs) {
					margin-top: 0;
				}
			}

			img {
				width: 95px;
				transition: filter .2s ease-in-out;

				@include for(xs) {
					width: 70px;
				}
			}

			p.download {
				position: relative;
				padding-left: 20px;

				@include for(xs) {
					margin-top: 5px;
				}

				&:before {
					width: 15px;
					height: 18px;
					position: absolute;
					content: '';
					background-image: url('../../assets/images/icons/Symbol/orange/download-orange.svg');
					background-size: 100%;
					background-repeat: no-repeat;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
				}

				span {
					color: $secondary;
				}
			}

			a {
				width: 100%;
				height: 100%;
				display: block;
				margin-top: 0;
				padding: 20px;
				position: relative;

				@include for(xs) {
					padding: 10px 0px;
				}

				&:hover,
				&:focus {
					img {
						filter: brightness(.8);
					}

					span {
						border-bottom: 1px solid $secondary;
					}
				}
			}
		}
	}
}

section.how-to-buy {
	margin-top: 100px;

	@include for(xs) {
		margin-top: 45px;
	}

	@include for(xs) {
		.content {
			background-color: $grey;
			padding: 5px 15px 20px;
		}
	}

	.button-container {
		.button:first-child {
			margin-right: 20px;
		}

		br {
			display: none;
		}
	}
}

section.related {
	background-color: $primary; // fixed for odd gap between this and the footer
	margin-top: 90px;
	position: relative;
	top: 10px; // 
	padding: 60px 0 20px;

	@include for(xs) {
		margin-top: 40px;
		padding: 40px 0 20px;

		>.container {
			width: 100%;
			padding: 0;
		}
	}

	h2.light {
		@include heading(light,
		$white);
		margin-bottom: 20px;

		@include for(xs) {
			margin-left: 30px;
			margin-bottom: 10px;
		}
	}
}