header.genericProduct .block-content{
        position: relative;
        -webkit-transform: none;
        transform: none;
        margin-top: 100px;
        margin-bottom: 0;
}
header.genericProduct{
	background-repeat: no-repeat;
}
@media only screen and (min-width: 992px){
	header.genericProduct {
		background-image: none!important;
		background-repeat: no-repeat;
	}
}
@media only screen and (max-width: 992px){
	header.genericProduct{
	    padding-top: calc(80% - 20px);
	    min-height: 0;
	    margin-bottom: 0;
	    background-position: 0 45px;
	    background-position-x: 0px;
	    background-position-y: 45px;
	    background-size: 100% auto;
	    background-repeat: no-repeat;
	}
}
@media only screen and (max-width: 992px){
	header.genericProduct .block-content .content {
	    background-color: #006fba;
	    padding: 15px;
	    width: 100%;
	}
}