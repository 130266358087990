header {
    overflow-x: hidden;
    min-height: 425px;
    position: relative;
    -ms-scroll-limit: 0 0 0 0;
    -ms-overflow-style: none;
    @include for(xs) {
        min-height: 100px;
    }
    .slide {
        position: relative;
        .slide-bg {
            padding-top: 40vw;
            background-size: cover;
            position: relative;
            min-height: 450px;
            display: none !important;
            @include for(xs) {
                background-image: none !important;
            }
            @include for(sm md lg) {
                display: block !important;
            }
        }
        .slide-bg-xs {
            padding-top: 60vw;
            background-size: cover;
            background-position: 30% center;
            position: relative;
            min-height: 250px;
            display: block !important;
            @include for(sm md lg) {
                display: none !important;
                background-image: none !important;
            }
        }
    }
    .block-content {
        transform: translateY(-50%);
        width: 100%;
        .content {
            padding-bottom: 40px;
            @include for(md lg) {
                padding: 35px 40px 40px;
            }
            display: inline-block;
            h1 {
                @include heading(h1, $white);
                margin-top: 0;
                margin-bottom: 20px;
                @include for(sm) {
                    @include x-rem(font-size, 4);
                }
                @include for(md) {
                    @include x-rem(font-size, 5);
                }
            }
            p {
                color: $white;
            }
            .blurb a {
                color: $white;
                text-decoration: underline;
                &:hover,
                &:focus {
                    opacity: 0.8;
                }
            }
        }
    }
    &.home {
        @include for(xs) {
            background-color: $grey;
        }
        .block-content {
            @include for(xs) {
                transform: translateY(-40px);
                margin-bottom: -30px;
            }
            .content {
                background-color: $white;
                padding-bottom: 0;
                @include for(sm) {
                    padding: 0 25px;
                }
                @include for(xs) {
                    padding: 10px 15px 15px;
                }
                h1 {
                    @include heading(h1-home, $text);
                    margin-top: 0;
                    margin-bottom: 20px;
                    @include for(xs) {
                        margin-top: 0;
                    }
                }
            }
            .button {
                @include button-arrow('white', 'primary');
                min-width: 300px;
                margin-top: 0;
                @include for(xs) {
                    min-width: 0;
                    width: 100%;
                    max-width: 300px;
                }
            }
        }
    }
    &:not(.home) {
        background-color: $primary;
    }
    &.serviceCenter,
    &.productMarketing,
    &.generalContent,
    &.storeFinder,
    &.search,
    &.news,
    &.page-not-found,
    &.productSubPageGrid {
         background-image: none!important;
        .block-content {
            position: relative;
            transform: none;
            margin-top: 100px;
            margin-bottom: 0px;
            .content {
                width: 100%;
                clear: both;
                @include for(sm) {
                    width: 60%;
                }
            }
            @include for(md) {
                h1,
                .blurb,
                ul.crumbs {
                    width: 55%;
                }
            }
            @include for(lg) {
                h1,
                .blurb,
                ul.crumbs {
                    width: 60%;
                }
            }
            @include for(xlg) {
                h1,
                .blurb,
                ul.crumbs {
                    width: 65%;
                }
            }
        }
    }
    &.storeFinder,
    &.search {
        .block-content {
            .content {
                @include for(sm) {
                    width: 100%;
                }
            }
            @include for(md lg) {
                h1,
                .blurb {
                    width: 70%;
                }
            }
        }
    }
    &.storeFinder,
    &.search {
        min-height: 350px;
        @include for(sm) {
            min-height: 100px;
        }
        @include for(xs) {
            min-height: 100px;
        }
    }
    &.productSubPageGrid {
        margin-bottom: 180px;
        @include for(xs sm) {
            background-size: 100% auto;
            background-repeat: no-repeat;
            background-color: $white;
            &.hidden-image {
                background-image: none !important;
                background-color: $primary;
            }
        }
        @include for(md lg) {
            background-image: none !important;
        }
        @include for(xlg) {
            margin-bottom: 200px;
        }
        @include for(xs) {
            &:not(.hidden-image) {
                padding-top: calc(80% - 20px);
                min-height: 0;
                margin-bottom: 0px;
                background-position: 0px 45px;
                .block-content {
                    position: relative;
                    margin-top: 0;
                    margin-bottom: 0;
                    .content {
                        background-color: $primary;
                        padding: 15px;
                        width: 100%;
                        h1 {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
        @include for(sm) {
            &:not(.hidden-image) {
                padding-top: calc(40% - 20px);
                background-position: 0 140%;
                min-height: 0;
                margin-bottom: 0px;
                .block-content {
                    position: relative;
                    margin-top: 0;
                    margin-bottom: 50px;
                    .content {
                        background-color: $primary;
                        padding: 15px;
                        width: 100%;
                        h1 {
                            margin-top: 0;
                        }
                        ul.crumbs {
                            margin-top: 0;
                            li {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
        &.hidden-image {
            margin-bottom: 80px;
            @include for(xs) {
                margin-bottom: 40px;
            }
        }
    }
    &.generalContent,
    &.serviceCenter {
        margin-bottom: 80px;
        @include for(xs sm) {
            background-size: 100% auto;
            background-repeat: no-repeat;
            background-color: $white;
            &.hidden-image {
                background-image: none !important;
                background-color: $primary;
            }
        }
        @include for(md lg) {
            background-image: none !important;
        }
        @include for(xs) {
            &:not(.hidden-image) {
                padding-top: calc(80% - 20px);
                min-height: 0;
                margin-bottom: 0px;
                background-position: 0px 45px;
                .block-content {
                    position: relative;
                    margin-top: 0;
                    margin-bottom: 0;
                    .content {
                        background-color: $primary;
                        padding: 15px;
                        width: 100%;
                        h1 {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
        @include for(sm) {
            &:not(.hidden-image) {
                min-height: 0;
                margin-bottom: 0px;
                padding-top: calc(40% - 20px);
                background-position: 0 140%;
                .block-content {
                    position: relative;
                    margin-top: 0;
                    margin-bottom: 20px;
                    .content {
                        background-color: $primary;
                        padding: 15px;
                        width: 100%;
                        h1 {
                            margin-top: 0;
                        }
                        ul.crumbs {
                            margin-top: 0;
                            li {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
        &.hidden-image {
            margin-bottom: 0px;
            min-height: 0;
            @include for(xs) {
                margin-bottom: 0px;
            }
        }
        // Temporary 
        .block-content {
            @include for(md) {
                h1,
                .blurb,
                ul.crumbs {
                    width: 50%;
                }
            }
            @include for(lg) {
                h1,
                .blurb,
                ul.crumbs {
                    width: 55%;
                }
            }
            @include for(xlg) {
                h1,
                .blurb,
                ul.crumbs {
                    width: 60%;
                }
            }
        }
    }
    &.productMarketing {
        margin-bottom: 80px;
        @include for(xs sm) {
            background-size: 100% auto;
            background-repeat: no-repeat;
            background-color: $white;
        }
        @include for(md lg) {
            background-image: none !important;
        }
        @include for(xlg) {
            margin-bottom: 120px;
        }
        .cylinder-image {
            position: absolute;
            left: calc(50% + 20px);
            bottom: 0;
            height: 80%;
            width: auto;
            @include for(xs sm) {
                left: calc(50% - 200px);
                width: 50%;
                min-width: 100px;
                max-width: 250px;
                margin: 0 auto;
                height: auto;
                bottom: 25%;
            }
            @media only screen and (max-width: 550px) {
                left: 50px;
                bottom: auto;
                top: 20%;
                width: 40%;
            }
        }
        @include for(xs) {
            padding-top: calc(80% - 20px);
            min-height: 0;
            margin-bottom: 0px;
            background-position: 0px 45px;
            .block-content {
                position: relative;
                margin-top: 0;
                margin-bottom: 0;
                .content {
                    background-color: $primary;
                    padding: 15px;
                    width: 100%;
                    h1 {
                        margin-top: 0;
                    }
                }
            }
        }
        @include for(sm) {
            min-height: 0;
            margin-bottom: 0px;
            padding-top: calc(40% - 20px);
            background-position: 0 140%;
            .block-content {
                position: relative;
                margin-top: 0;
                margin-bottom: 50px;
                .content {
                    background-color: $primary;
                    padding: 15px;
                    width: 100%;
                    h1 {
                        margin-top: 0;
                    }
                    ul.crumbs {
                        margin-top: 0;
                        li {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
        &.news {
            @include for(xs sm) {
                background-size: cover;
                background-position: center;
            }
            @include for(xs) {
                padding-top: calc(50% - 20px);
            }
            .block-content .content h1 {
                margin-bottom: 5px;
            }
        }
    }
    &.productListing,
    &.generalListing,
    &.newsListing {
        .block-content {
            position: absolute;
            top: 50%;
            .content {
                width: 100%;
                clear: both;
                display: flex;
                flex-wrap: wrap;
            }
            ul.crumbs {
                width: 100%;
            }
            @include for(md lg) {
                h1 {
                    flex: 1 1 40%;
                    margin-right: 60px;
                }
                .blurb {
                    flex: 1 1 50%;
                }
            }
            @include for(xs) {
                position: relative;
                top: 0;
                transform: none;
                margin-top: 100px;
            }
        }
    }
    &.news {
        position: relative;
        margin-bottom: 0px;
        overflow: visible;
        .block-content {
            margin: 0;
            padding-top: 100px;
            @include for(xs sm) {
                padding-top: 0;
                bottom: -20px;
            }
        }
        .news-image-container {
            position: absolute;
            top: 0;
            right: 0;
            bottom: -122px;
            width: 40%;
            background-position: center;
            background-size: cover;
            @include for(sm xs) {
                display: none;
            }
        }
    }
    &.newsListing {
        .block-content {
            h1 {
                width: auto;
            }
        }
    }
    &.pageFAQ {
        background-color: $white;
        img {
            width: 100%;
            height: auto;
            display: block;
            @supports(object-fit: cover) {
                object-fit: cover;
                height: 40vw;
                min-height: 300px;
                max-height: 600px;
            }
            @include for(xs) {
                @supports(object-fit: cover) {
                    max-height: 300px;
                }
            }
        }
        .block-content {
            margin-top: -50px;
            margin-bottom: 80px;
            transform: none;
            @include for(xs sm) {
                margin-bottom: 40px;
            }
            .content {
                background-color: $text;
                padding: 20px 40px 40px;
                @include for(sm) {
                    padding: 20px 40px 40px;
                }
                @include for(xs) {
                    padding: 20px;
                    h1 {
                        margin-top: 0;
                    }
                }
                display: flex;
                flex-wrap: wrap;
                .blurb {
                    h2 {
                        margin-top: 10px;
                        color: $white;
                        @include for(xs sm) {
                            font-size: 2.8rem;
                        }
                    }
                    p {
                        max-width: 600px;
                    }
                }
                @include for(md lg) {
                    .title-wrap {
                        flex-basis: 320px;
                    }
                    .blurb {
                        flex-basis: calc(100% - 320px);
                        margin-top: 5px;
                    }
                }
                section.news-search {
                    width: 100%;
                    flex-basis: 100%;
                    margin-top: 50px;
                    .title {
                        color: $white;
                        opacity: 1;
                    }
                    input {
                        opacity: 1;
                        border-color: $white;
                    }
                }
            }
        }
    }
    &.askPatItem {
        background-color: $white;
        position: relative;
        img {
            display: block;
            margin: 40px auto 0;
            @include for(lg) {
                width: calc(1140px + 80px);
                height: auto;
            }
            @include for(xlg) {
                width: calc(1280px + 80px);
                height: auto;
            }
            @include for(md) {
                width: calc(960px + 80px);
                height: auto;
            }
            @include for(sm) {
                width: calc(720px + 80px);
                height: auto;
            }
            @include for(xs) {
                width: 100%;
                height: auto;
            }
        }
        .block-content {
            transform: translateY(-40px);
            margin-bottom: -20px;
        }
        .content {
            background-color: $text;
            padding: 20px 40px 40px;
            ;
            h1 {
                @include heading(h3, $white);
                @include for(xs) {
                    @include heading(h4, $white);
                }
            }
            @include for(sm) {
                padding: 10px 30px 30px;
                ;
            }
            @include for(xs) {
                padding: 10px 20px 20px;
                ;
            }
        }
    }
    ul.crumbs {
        list-style-type: none;
        @include for(xs) {
            display: none;
        }
        li {
            display: inline-block;
            position: relative;
            padding-left: 10px;
            padding-right: 10px;
            &:before {
                content: " ";
                width: 4px;
                height: 8px;
                background-image: url('../../assets/images/chevron-small-white@2x.png');
                background-size: 100%;
                background-repeat: no-repeat;
                position: absolute;
                display: inline-block;
                left: -2px;
                top: 50%;
                transform: translateY(-50%);
                color: $white;
            }
            &:first-child {
                padding-left: 0;
                &:before {
                    display: none;
                }
            }
            @include heading(light,
            $white);
            a {
                font-weight: inherit;
                font-size: inherit;
                text-transform: inherit;
                color: inherit;
                margin-top: 0;
                line-height: 1;
            }
            a {
                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }
        }
    }
    aside.quicklinks {
        position: absolute;
        bottom: 0;
        width: 100vw;
        @include for(xs) {
            display: none;
        }
        .content {
            background-color: $secondary;
            padding: 30px 100px 30px 40px;
            min-width: 360px;
            float: right;
            position: relative;
            right: -100px;
            padding-bottom: 160px;
            @include for(sm) {
                padding: 20px 80px 110px 30px;
                ;
            }
            h2 {
                @include heading(light,
                $white);
            }
            ul {
                list-style-type: none;
            }
            a {
                margin-top: 5px;
                display: inline-block;
                @include heading(h5,
                $white);
                &:hover,
                &:focus {
                    text-decoration: underline;
                }
                &:active {
                    opacity: .8;
                    text-decoration: underline;
                }
            }
        }
        &.quicklinks-xs {
            position: relative;
            bottom: auto;
            width: 100%;
            display: none;
            margin-bottom: 20px;
            overflow: hidden;
            .content {
                right: auto;
                width: 100%;
                min-width: 0;
                padding: 0;
                background-color: transparent;
                h2 {
                    @include heading(light,
                    $secondary);
                    opacity: 1;
                    margin-bottom: 20px;
                }
                li {
                    margin-top: 12px;
                }
                a {
                    @include heading(p,
                    $secondary);
                    position: relative;
                    padding-left: 25px;
                    &:before {
                        content: " ";
                        width: 16px;
                        height: 16px;
                        background-size: 100%;
                        background-repeat: no-repeat;
                        position: absolute;
                        display: inline-block;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    @include for(xs) {
                        margin-top: 0;
                        @include x-rem(font-size,
                        2);
                    }
                }
                li.storeIcon {
                    a:before {
                        background-image: url('../../assets/images/icons/Symbol/orange/store-location-orange.svg');
                    }
                }
                li.pageIcon {
                    a:before {
                        background-image: url('../../assets/images/icons/Symbol/orange/data-sheet-orange.svg');
                    }
                }
                li.userIcon {
                    a:before {
                        background-image: url('../../assets/images/icons/Symbol/orange/profile-orange.svg');
                    }
                }
                li.downloadIcon {
                    a:before {
                        background-image: url('../../assets/images/icons/Symbol/orange/download-orange.svg');
                    }
                }
                li.locationIcon {
                    a:before {
                        background-image: url('../../assets/images/icons/Symbol/orange/location-pin-orange.svg');
                    }
                }
                li.searchIcon {
                    a:before {
                        background-image: url('../../assets/images/icons/Symbol/orange/search-orange.svg');
                    }
                }
                li.phoneIcon {
                    a:before {
                        background-image: url('../../assets/images/icons/Symbol/orange/phone-orange.svg');
                    }
                }
            }
            @include for(xs) {
                display: block;
            }
        }
    }
}

.product-image-container,
.header-image-container {
    position: absolute;
    top: 112px;
    right: 0;
    width: 100%;
    text-align: right;
    overflow-x: hidden;
    pointer-events: none;
    @include for(xs sm) {
        display: none
    }
    .product-image,
    .header-image {
        width: auto;
        height: 570px;
        position: relative;
        top: 0;
        transform: translateX(50%);
        z-index: 5;
        @include for(sm) {
            right: calc(50% - 440px);
            height: 480px;
        }
        @include for(md) {
            right: calc(50% - 500px);
            height: 540px;
        }
        @include for(lg) {
            right: calc(50% - 540px);
        }
        @include for(xlg) {
            right: calc(50% - 650px);
        }
    }
    &.hide {
        display: none;
    }
}

section.button-container {
    .button {
        @include button-arrow('blue',
        'primary');
        margin-top: 40px;
        +br+.button {
            @include button-arrow('blue',
            'keyline');
            margin-top: 10px;
        }
        min-width: 300px;
        @include for(xs) {
            min-width: 0;
            width: 100%;
            max-width: 300px;
            margin-top: 30px;
            span {
                @include x-rem(font-size,
                1.8);
            }
        }
    }
}

// Homepage hero banner
.banner {
    position: relative;
    &--home {
        background-color: $grey-bg;
    }
    @media only screen and (min-width: 768px) {
        margin-bottom: 120px;
    }
    @media only screen and (min-width: 1200px) {
        margin-bottom: 180px;
    }
    &__background {
        background-color: $grey-bg;
        @supports(object-fit: cover) {
            max-height: 800px;
            height: 40vw;
            min-height: 550px;
            overflow: hidden;
            img {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
            @media only screen and (max-width: 767px) {
                height: 300px;
                min-height: 0;
            }
        }
        img {
            vertical-align: middle;
        }
    }
    &__content {
        transform: translateY(-25px);
        @media only screen and (min-width: 768px) {
            position: absolute;
            top: 50%;
            transform: translateY(calc(-50% - 25px));
            left: 0;
            width: 100%;
        }
        @media only screen and (min-width: 1500px) {
            transform: translateY(calc(-50% - 50px));
        }
        &-inner {
            @media only screen and (max-width: 767px) {
                background-color: $white;
                padding: 20px;
            }
            @media only screen and (min-width: 768px) {
                width: 60%;
            }
            @media only screen and (min-width: 992px) {
                max-width: 420px;
            }
            @media only screen and (min-width: 1200px) {
                width: 40%;
                max-width: 480px;
            }
            @media only screen and (min-width: 1500px) {
                max-width: 480px;
                margin-left: 30px;
            }
            h1 {
                @include heading(h1-home,
                $text);
                line-height: 1;
                margin-bottom: 25px;
                margin-top: 0;
                @media only screen and (max-width: 767px) {
                    margin-top: 0;
                    margin-bottom: 10px;
                    max-width: 400px;
                }
                @media only screen and (min-width: 768px) {
                    @include heading(h1-home,
                    $white);
                    margin-top: 0;
                }
            }
            p {
                margin-bottom: 20px;
                color: $white;
                padding-right: 50px;
                @media only screen and (max-width: 767px) {
                    display: none;
                }
                @media only screen and (min-width: 1200px) {
                    padding-right: 120px;
                }
            }
            a {
                @include button-arrow('white',
                'primary');
                min-width: 250px;
            }
        }
    }
    &__quicklinks {
        @media only screen and (min-width: 768px) {
            position: absolute;
            bottom: 0;
            width: 100%;
            left: 0;
        }
        &-inner {
            display: inline-block;
            width: 220px;
            padding: 0 0 25px 20px;
            @media only screen and (min-width: 768px) {
                background-color: $secondary;
                position: absolute;
                bottom: 0;
                right: -10px;
                padding: 20px 30px 80px;
            }
            @media only screen and (min-width: 992px) {
                padding: 30px 40px 80px;
                width: 300px;
            }
            @media only screen and (min-width: 1200px) {
                width: 350px;
                right: -20px;
            }
            @media only screen and (min-width: 1500px) {
                right: -40px;
            }
            h2 {
                @include heading(light,
                $white);
                margin-bottom: 15px;
            }
            a {
                color: $white;
                margin-top: 5px;
                display: inline-block;
                &:hover,
                &:focus {
                    text-decoration: underline;
                }
                @media only screen and (max-width: 991px) {
                    font-size: 16px;
                    font-size: 1.6rem;
                }
            }
        }
    }
    &__lg-btns {
        position: absolute;
        bottom: -50px;
        left: 0;
        width: 100%;
        @media only screen and (max-width: 767px) {
            display: none;
        }
        @media only screen and (min-width: 992px) {
            bottom: -60px;
        }
        @media only screen and (min-width: 1200px) {
            bottom: -50px;
        }
        &-inner {
            display: flex;
            width: 100%;
            .lg-btn {
                display: inline-block;
                padding: 25px;
                background-color: $white;
                color: $primary;
                flex: 1 1 33%;
                text-align: center;
                border-bottom: 2px solid $grey;
                border-right: 2px solid $grey;
                margin-top: 0;
                font-size: 16px;
                font-size: 1.6rem;
                transition: all .3s ease-in-out;
                display: flex;
                flex-direction: column;
                align-items: center;
                @include for(md) {
                    padding: 25px 15px;
                }
                @include for(sm) {
                    padding: 20px 10px;
                }
                &:last-child {
                    border-right: none;
                }
                &__title {
                    font-weight: 600;
                    text-transform: uppercase;
                    font-size: 18px;
                    font-size: 1.8rem;
                    line-height: 1;
                    vertical-align: middle;
                    @include for(lg) {
                        font-size: 22px;
                        font-size: 2.2rem;
                    }
                    @include for(md) {
                        font-size: 20px;
                        font-size: 2rem;
                    }
                    @include for(sm) {
                        font-size: 15px;
                        font-size: 1.5rem;
                    }
                }
                &__cta {
                    position: relative;
                    width: 20px;
                    height: 20px;
                    margin-top: 10px;
                    transition: padding .3s 0.1s ease-in-out;
                    &-hidden {
                        white-space: nowrap;
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        right: 0;
                        overflow: hidden;
                        padding-right: 18px;
                        color: $white;
                        line-height: 1.1;
                        transition: width .3s ease-in-out;
                        margin: 0;
                        font-size: 16px;
                        font-size: 1.6rem;
                        @media only screen and (min-width: 992px) {
                            font-size: 18px;
                            font-size: 1.8rem;
                            padding-right: 20px;
                            line-height: 1;
                        }
                    }
                    &:after {
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        content: '';
                        width: 18px;
                        height: 18px;
                        background-color: $primary;
                        background-image: url(../images/icons/Symbol/reversed/chevron-right-white.svg);
                        background-size: 10px auto;
                        background-repeat: no-repeat;
                        background-position: center;
                        @media only screen and (min-width: 992px) {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
                &:hover,
                &:focus {
                    color: $white;
                    background-color: $primary;
                    .lg-btn__cta {
                        padding-left: 90px;
                        transition: padding .5s ease-in-out;
                        &-hidden {
                            width: 90px;
                            transition: width .5s 0.1s ease-in-out;
                        }
                        @media only screen and (min-width: 992px) {
                            padding-left: 105px;
                            &-hidden {
                                width: 105px;
                            }
                        }
                    }
                }
            }
            &--empty {
                height: 100px;
                background-color: $white;
            }
        }
    }
    &__form {
        z-index: 10;
        @media only screen and (max-width: 991px) {
            display: none;
        }
        @media only screen and (min-width: 992px) {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        }
        &-inner {
            @media only screen and (min-width: 992px) {
                transform: translateY(-50%);
                position: absolute;
                bottom: 70px;
                width: 600px;
            }
            @media only screen and (min-width: 1500px) {
                padding: 0 30px;
            }
        }
        p {
            color: $white;
            margin-bottom: 10px;
        }
        .checkbox {
            display: none;
        }
        .search-wrapper {
            position: relative;
            width: 100%;
            height: 60px;
            .search-btn-wrapper {
                position: absolute;
                top: 0px;
                right: 0px;
                text-align: right;
            }
            .search-submit {
                @include button-arrow('white',
                'primary-blue');
                @include x-rem(font-size,
                1.8);
                min-width: 190px;
                margin-top: 0;
                border-radius: 0;
                line-height: 1.4;
                font-family: $headingfont;
            }
        }
        input[type=search] {
            width: calc(100% - 200px);
            padding: 19px 20px 16px 20px;
            color: $text;
            border: none;
            display: inline-block;
            box-sizing: border-box;
            border: 2px solid $white;
            -webkit-appearance: none;
            border-radius: 0;
            @include x-rem(font-size,
            1.8);
            &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                color: rgba($text, .2);
            }
            &::-moz-placeholder {
                /* Firefox 19+ */
                color: rgba($text, .2);
            }
            &:-ms-input-placeholder {
                /* IE 10+ */
                color: rgba($text, .2);
            }
            &:-moz-placeholder {
                /* Firefox 18- */
                color: rgba($text, .2);
            }
            &:focus {
                outline: none;
                border: 2px solid $primary-active;
            }
        }
    }
    &__link {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        overflow: hidden;
        @include for(xs sm) {
            display: none;
        }
        .content {
            background-color: $text;
            padding: 30px 40px 120px 40px;
            display: inline-block;
            position: relative;
            float: right;
            transform: translateX(30px);
            @include for(xlg) {
                padding-bottom: 120px;
                transform: translateX(50px);
            }
            a {
                margin-top: 5px;
                display: inline-block;
                position: relative;
                padding-left: 30px;
                @include heading(h5,
                $white);
                @include for(md) {
                    font-size: 1.8rem;
                }
                &:before {
                    content: "";
                    position: absolute;
                    display: inline-block;
                    left: -5px;
                    width: 24px;
                    height: 24px;
                    top: 50%;
                    transform: translateY(-50%);
                    background-size: 24px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-image: url('../../assets/images/icons/Symbol/reversed/icon-play.svg');
                }
                &:hover,
                &:focus {
                    text-decoration: underline;
                }
                &:active {
                    opacity: .8;
                    text-decoration: underline;
                }
            }
        }
        &.product-trailer-xs {
            display: none;
            @include for(xs) {
                display: inline-block;
            }
        }
    }
    &__top-pages {
        @media only screen and (max-width: 767px) {
            background-color: $white;
        }
        @media only screen and (min-width: 768px) {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        }
        &-inner {
            background-color: $white;
            padding: 0 0 20px;
            @media only screen and (min-width: 768px) {
                transform: translateY(-50%);
                position: absolute;
                top: 0;
                width: calc(100% - 30px);
                padding: 20px 40px;
            }
            h3 {
                @include heading(p, $text);
                @include x-rem(font-size,
                2);
            }
            .arrow-list {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                width: 100%;
                @include for(xs) {
                    margin: 0;
                }
                &__item {
                    flex-shrink: 1;
                    width: calc(100% / 3);
                    padding-right: 20px;
                    margin-top: 10px;
                    @include for(sm) {
                        width: calc(100% / 2);
                    }
                    @include for(xs) {
                        width: 100%;
                    }
                }
            }
        }
    }
    // Trade N Go Gas 
    &--tngg {
        @media only screen and (min-width: 768px) {
            margin-bottom: 180px;
        }
        @media only screen and (min-width: 1500px) {
            margin-bottom: 210px;
        }
        .banner__background {
            @supports(object-fit: cover) {
                @media only screen and (min-width: 992px) {
                    height: 800px;
                }
            }
        }
        .banner__content {
            @media only screen and (min-width: 768px) {
                transform: translateY(calc(-50% - 50px));
            }
            &-inner {
                @media only screen and (max-width: 767px) {
                    background-color: $grey;
                    h1 {
                        margin-bottom: 0;
                    }
                }
                @media only screen and (min-width: 992px) {
                    width: 60%;
                    max-width: 580px;
                }
                @media only screen and (min-width: 1200px) {
                    width: 60%;
                    max-width: 580px;
                }
            }
        }
        .banner__top-pages {
            .arrow-list__item {
                flex: 1 1 auto;
                width: auto;
                a {
                    @include link-arrow($secondary,
                    'orange');
                    left: 0;
                    span {
                        @include x-rem(font-size,
                        1.8);
                    }
                    &:hover,
                    &:focus {
                        span {
                            border-bottom: 1px solid $secondary;
                        }
                    }
                    &:active {
                        &:before {
                            filter: brightness(.8);
                        }
                        span {
                            border-bottom: 1px solid $secondary-dark;
                        }
                    }
                }
                &--dark a {
                    @include link-arrow($text,
                    'dark');
                    span {
                        @include x-rem(font-size,
                        1.8);
                    }
                    &:hover,
                    &:focus {
                        span {
                            border-bottom: 1px solid $text;
                        }
                    }
                }
            }
        }
        .banner__form {
            &-inner {
                @media only screen and (min-width: 992px) {
                    bottom: 50px;
                }
            }
            input[type=search] {
                &:focus {
                    border: 2px solid $secondary-active;
                }
            }
            .search-wrapper .search-submit {
                @include button-arrow('white',
                'primary');
                @include x-rem(font-size,
                1.8);
                min-width: 190px;
                margin-top: 0;
                border-radius: 0;
                line-height: 1.4;
                font-family: $headingfont;
            }
        }
    }
    // Healthcare
    &--healthcare {
        @media only screen and (min-width: 768px) {
            margin-bottom: 180px;
        }
        @media only screen and (min-width: 1500px) {
            margin-bottom: 210px;
        }
        .banner__background {
            @supports(object-fit: cover) {
                @media only screen and (min-width: 992px) {
                    height: 800px;
                }
            }
        }
        .banner__content {
            @media only screen and (min-width: 768px) {
                transform: translateY(calc(-50% - 50px));
            }
            &-inner {
                @media only screen and (min-width: 1200px) {
                    width: 50%;
                    max-width: 540px;
                }
                @media only screen and (max-width: 767px) {
                    background-color: $grey;
                    h1 {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .banner__top-pages {
            .arrow-list__item {
                a {
                    @include link-arrow($primary,
                    'blue');
                    left: 0;
                    span {
                        @include x-rem(font-size,
                        1.8);
                    }
                    &:hover,
                    &:focus {
                        span {
                            border-bottom: 1px solid $primary;
                        }
                    }
                    &:active {
                        &:before {
                            filter: brightness(.8);
                        }
                        span {
                            border-bottom: 1px solid $primary-dark;
                        }
                    }
                }
                &--dark a {
                    @include link-arrow($text,
                    'dark');
                    span {
                        @include x-rem(font-size,
                        1.8);
                    }
                    &:hover,
                    &:focus {
                        span {
                            border-bottom: 1px solid $text;
                        }
                    }
                }
            }
        }
    }
    // Specialty
    &--specialty {
        @media only screen and (min-width: 768px) {
            margin-bottom: 180px;
        }
        @media only screen and (min-width: 1500px) {
            margin-bottom: 210px;
        }
        .banner__content-inner {
            @media only screen and (max-width: 767px) {
                background-color: $grey;
                h1 {
                    margin-bottom: 0;
                }
            }
        }
        .banner__top-pages {
            .arrow-list__item {
                a {
                    @include link-arrow($primary,
                    'blue');
                    left: 0;
                    span {
                        @include x-rem(font-size,
                        1.8);
                    }
                    &:hover,
                    &:focus {
                        span {
                            border-bottom: 1px solid $primary;
                        }
                    }
                    &:active {
                        &:before {
                            filter: brightness(.8);
                        }
                        span {
                            border-bottom: 1px solid $primary-dark;
                        }
                    }
                }
                &--dark a {
                    @include link-arrow($text,
                    'dark');
                    span {
                        @include x-rem(font-size,
                        1.8);
                    }
                    &:hover,
                    &:focus {
                        span {
                            border-bottom: 1px solid $text;
                        }
                    }
                }
            }
        }
    }
}
