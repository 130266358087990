@use 'sass:math';

$break-xs: 768px;
$break-sm: 992px;
$break-md: 1200px;
$break-lg: 1500px;
$main-font-size: 10px;

@mixin transition($property, $speed) {
  -webkit-transition: $property $speed ease-in-out;
     -moz-transition: $property $speed ease-in-out;
      -ms-transition: $property $speed ease-in-out;
       -o-transition: $property $speed ease-in-out;
          transition: $property $speed ease-in-out;
}

@mixin vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin transparent($color, $alpha) {
  $rgba: rgba($color, $alpha);
  $ie-hex-str: ie-hex-str($rgba);
  background-color: transparent;
  background-color: $rgba;
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#{$ie-hex-str},endColorstr=#{$ie-hex-str});
  zoom: 1;
}

$customEase : cubic-bezier(.51,.28,.35,.52);

@mixin for($media) {
  @if index($media, 'xs') {
    @media only screen and (max-width: $break-xs - 1) { @content; }
  }
  @if index($media, 'sm') {
    @media only screen and (min-width: $break-xs) and (max-width: $break-sm - 1) { @content; }
  }
  @if index($media, 'md') {
    @media only screen and (min-width: $break-sm) and (max-width: $break-md - 1) { @content; }
  }
  @if index($media, 'lg') {
    @media only screen and (min-width: $break-md) { @content; }
  }
  @if index($media, 'xlg') {
    @media only screen and (min-width: $break-lg) { @content; }
  }
  @if index($media, 'retina') {
  	@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (-o-min-device-pixel-ratio: 3/2),
    only screen and (min--moz-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) { @content; }
  }
}

@mixin x-rem($property, $value, $base-font-size: 16) {
	// $rem-value: math.div($value, $base-font-size);
	// #{$property}: #{$rem-value}rem;
  #{$property}: #{$value}rem;
}