.en-services-block {
  margin-block: 92px !important;

  @include breakpoint($lg) {
    margin-block: 60px;
  }

  h2 {
    margin: 0;
    line-height: 1;
    color: var(--color-secondary);
    font-weight: 400;
  }

  h3 {
    margin-block: 1rem;
    font-size: 22px;
    font-weight: 600;
    color: var(--color-secondary);
  }

  .en-column {
    padding: 32px 24px;
    background-color: var(--color-bg);

    @include breakpoint($md) {
      padding: 32px 40px;
    }

    @include breakpoint($lg) {
      margin-top: 8px;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }
}
