@use 'sass:color';

section.faqs-filter {
    .content {
        @include for(xs) {
            padding-bottom: 0;
        }
        @include for(xs sm) {
            margin-bottom: 40px;
        }
        @include for(md lg) {
            display: flex;
            padding: 0 40px;
            .question {
                flex: 1 1 400px;
                min-width: 400px;
                margin-left: 40px;
            }
        }
    }
    h3 {
        @include heading(light, $secondary);
    }
    .categories {
        @include for(xs sm) {
            margin-bottom: 20px;
        }
    }
    .question {
        button.button {
            font-size: 2rem;
            top: 20px;
            @include button-arrow('white', 'primary');
            min-width: 280px;
            @include for(xs) {
                min-width: 220px;
            }
        }
    }
    .select-wrapper {
        display: none;
        width: 100%;
        border: 1px solid $text;
        padding: 12px 18px;
        margin-top: 15px;
        background-color: $grey;
        position: relative;
        height: 50px;
        &:before {
            content: "";
            width: 40px;
            height: 40px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            pointer-events: none;
            background-image: url('../images/icons/Symbol/grey/chevron-down-grey.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 12px auto;
        }
        select {
            width: 100%;
            border: none;
            font-size: 1.6rem;
            background-color: transparent;
            padding: 0 12px;
            position: absolute;
            top: 0;
            left: 0;
            height: 48px;
            -webkit-appearance: none;
        }
        @include for(xs) {
            display: inline-block;
        }
    }
    .category-nav {
        display: flex;
        flex-wrap: wrap;
        @include for(xs) {
            display: none;
        }
        .category-item {
            width: 33%;
            margin: 0 0 20px 0;
            flex: 0 0 33%;
            @include for(sm md) {
                flex: 0 0 200px;
            }
            @include for(xs) {
                flex: 0 0 200px;
                margin: 0 0 10px 0;
            }
            a {
                position: relative;
                padding-left: 15px;
                padding-right: 10px;
                display: block;
                font-size: 16px;
                font-size: 1.6rem;
                @include for(xs) {
                    font-size: 14px;
                    font-size: 1.4rem;
                }
                &:before {
                    content: "";
                    width: 2px;
                    height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    background-color: $primary;
                }
                &:hover,
                &:focus {
                    color: #000053;
                    &:before {
                        background: #000053;
                    }
                }
                &:active {                    
                    color: color.scale(#000053, $lightness: -30%);
                    &:before {                        
                        background: color.scale(#000053, $lightness: -30%)
                    }
                }
                &.active {
                    color: $secondary;
                    &:before {
                        background-color: $secondary;
                    }
                    &:hover,
                    &:focus {
                        color: #b44b09;
                        &:before {
                            background: #b44b09;
                        }
                    }
                    &:active {                        
                        color: color.scale(#b44b09, $lightness: -30%);
                        &:before {                            
                            background: color.scale(#b44b09, $lightness: -30%)
                        }
                    }
                }
            }
        }
    }
}

section.featured-faq {
    padding: 40px 0;
    @include for(xs sm) {
        padding: 20px 0 0;
    }
    .faqs-listing-feature {
        img {
            width: 65%;
            height: auto;
            display: block;
            @supports(object-fit: cover) {
                height: 400px;
                object-fit: cover;
            }
            @include for(xs) {
                width: 100%;
                @supports(object-fit: cover) {
                    height: 60vw;
                    max-height: 300px;
                }
            }
        }
        .content {
            background-color: $secondary;
            padding: 40px;
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 15px;
            ;
            width: 45%;
            @include for(xs) {
                padding: 0 20px 20px;
                position: relative;
                top: auto;
                right: auto;
                width: 100%;
                transform: none;
            }
            .button-wrap {
                align-self: flex-start;
                margin-top: auto;
                margin-bottom: 0;
            }
            h2 {
                @include heading(h4,
                $white);
                font-weight: 300;
                margin-bottom: 40px;
                margin-top: 0;
                @include for(xs) {
                    margin-bottom: 20px;
                }
                span {
                    @include heading(light,
                    $white);
                    display: block;
                    margin-bottom: 10px;
                    margin-top: 0;
                }
            }
        }
        .button {
            @include link-arrow();
        }
        &:hover,
        &:focus {
            .button {
                margin-left: 40px;
                margin-right: 0;
            }
        }
    }
}

.faqs-listing {
    display: flex;
    flex-direction: column;
    padding: 40px;
    @include for(sm) {
        padding: 30px 0 0;
    }
    @include for(xs) {
        padding: 0;
    }
    .faqs-listing-item {
        margin-bottom: 50px;
        position: relative;
        margin-top: 0;
        @include for(xs) {
            margin-bottom: 20px;
            margin-top: 0;
        }
        a {
            display: flex;
            align-items: stretch;
            height: 100%;
            position: relative;
            @include for(xs) {
                flex-direction: column;
            }
        }
        .content {
            display: flex;
            flex-direction: column;
            height: 100%;
            background-color: $grey;
            margin-left: 15px;
            position: relative;
            padding: 40px 40px 40px 420px;
            min-height: 270px;
            width: 100%;
            @include for(sm) {
                padding: 30px 30px 30px 280px;
                min-height: 135px;
            }
            @include for(xs) {
                padding: 20px;
                margin: 0;
                min-height: unset;
            }
        }
        img {
            height: auto;
            width: 400px;
            position: absolute;
            top: -15px;
            display: block;
            z-index: 1;
            @include for(sm) {
                width: 260px;
            }
            @supports(object-fit: cover) {
                object-fit: cover;
                height: 270px;
                @include for(sm) {
                    height: 170px;
                }
            }
            @include for(xs) {
                position: relative;
                width: 100%;
                height: auto;
                top: auto;
                left: auto;
                @supports(object-fit: cover) {
                    height: 40vw;
                }
            }
        }
        h3 {
            @include heading(h4,
            $text);
            font-weight: 300;
            margin-bottom: 20px;
            margin-top: 0;
            @include for(xs) {
                margin-bottom: 20px;
                margin-top: 0;
            }
            span {
                @include heading(light,
                $secondary);
                display: block;
                margin-bottom: 10px;
                margin-top: 0;
                @include for(xs) {
                    margin-top: 0;
                }
            }
        }
        .button-wrap {
            margin-bottom: 0;
            margin-top: auto;
            align-self: flex-start;
        }
        .button {
            @include link-arrow($secondary,
            'orange');
        }
        a:hover,
        a:focus {
            .button {
                margin-left: 40px;
                margin-right: 0;
            }
        }
    }
}

section.faqs-results {
    .content {
        padding: 20px 40px;
        @include for(xs sm) {
            padding: 20px 0px;
        }
    }
    .search-text {
        font-size: 18px;
        line-height: 25px;
        color: $text;
        font-weight: 400;
    }
}

section.faqs-question-panel {
    background-color: $grey;
    padding: 40px 0;
    @include for(xs sm) {
        padding: 20px 0;
    }
    .content {
        padding: 40px;
        display: flex;
        @include for(xs) {
            flex-direction: column;
            padding: 20px 0;
        }
    }
    .section-title {
        @include for(xs) {
            margin-bottom: 40px;
        }
        @include for(md lg) {
            flex: 1 1 50%;
        }
        h2 {
            @include heading(h3,
            $text);
            font-weight: 300;
            margin-bottom: 20px;
            margin-top: 0;
            @include for(xs) {
                margin-bottom: 20px;
                margin-top: 0;
            }
            span {
                @include heading(light,
                $secondary);
                display: block;
                margin-bottom: 10px;
                margin-top: 0;
                @include for(xs) {
                    margin-top: 0;
                }
            }
        }
        p {
            max-width: 400px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .section-button {
        @include for(sm md lg) {
            flex: 1 1 50%;
            margin-left: 40px;
        }
        h3.small {
            @include heading(light,
            $secondary);
        }
        button {
            font-size: 20px;
            font-size: 2rem;
            margin-top: 20px;
            @include button-arrow('white',
            'primary');
            min-width: 280px;
            @include for(xs) {
                min-width: 220px;
            }
        }
    }
}

// remove padding when in question item page
header.askPatItem {
    ~section.faqs-article-body {
        .content-modules {
            @include for(xs) {
                padding-top: 0;
            }
        }
    }
    ~section.faqs-question-panel {
        .content {
            @include for(sm md lg) {
                padding: 40px 0;
            }
        }
    }
}

.faqs-article-related {
    background-color: $grey;
    padding-bottom: 120px;
    @include for(xs sm) {
        padding-bottom: 40px;
    }
    .content {}
    .section-title {
        @include heading(h3, $text);
        margin-top: 0;
        padding-top: 80px;
        border-top: 2px solid $secondary;
        @include for(xs sm) {
            margin-top: 0;
            padding-top: 40px;
        }
        @include for(xs) {
            border: none;
            padding-top: 20px;
        }
    }
    .related-listing {
        display: flex;
        flex-direction: column;
        @include for(md lg) {
            margin: 0 -20px;
            flex-direction: row;
        }
        .listing-item {
            padding: 20px;
            flex: 1 1 100%;
            @include for(xs) {
                padding: 0;
                margin-bottom: 20px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            @include for(xs md lg) {
                margin-left: 15px;
                flex: 1 1 33%;
            }
            a {
                display: flex;
                background-color: $white;
                height: 100%;
                flex-direction: row;
                align-items: stretch;
                justify-content: stretch;
                @include for(xs md lg) {
                    flex-direction: column;
                }
                &:hover,
                &:focus {
                    .button {
                        margin-left: 40px;
                    }
                }
            }
            img {
                position: relative;
                top: -15px;
                left: -15px;
                width: 300px;
                height: auto;
                @include for(sm) {
                    height: 222px;
                    width: auto;
                    left: -20px;
                    top: -20px;
                }
                @supports (object-fit: cover) {
                    object-fit: cover;
                    @include for(xs md lg) {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            .item-text {
                display: flex;
                flex-direction: column;
                height: 100%;
                padding: 0 20px 20px;
                @include for(sm) {
                    padding-top: 20px;
                }
                h3 {
                    @include heading(h4, $text);
                    font-weight: 300;
                    span {
                        @include heading(light,
                        $secondary);
                        display: block;
                        margin-bottom: 10px;
                        @include for(xs md lg) {
                            margin-top: 0;
                        }
                    }
                    margin-bottom: 10px;
                }
                .button {
                    @include link-arrow($secondary,
                    'orange');
                    span {
                        font-size: 2rem;
                    }
                }
                .button-wrap {
                    margin-top: auto;
                }
            }
        }
    }
}

.modal__overlay {
    z-index: 200;
}

.modal {
    .success {
        display: none;
    }
    .modal__container {
        max-width: 680px;
        position: relative;
        @include for(xs) {
            padding: 20px;
        }
    }
    .modal__title {
        @include heading(h4, $text);
        font-weight: 300;
        margin-top: 0;
        @include for(xs) {
            margin-top: 0;
        }
        span {
            @include heading(light,
            $secondary);
            display: block;
            margin-top: 0;
            margin-bottom: 10px;
            @include for(xs) {
                margin-top: 0;
            }
        }
    }
    .modal__close {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 50px;
        height: 50px;
        font-size: 20px;
        font-size: 2rem;
        z-index: 2;
        @include for(xs) {
            top: 5px;
            right: 5px;
            width: 40px;
            height: 40px;
        }
    }
    form.loading {
        .circle {
            opacity: 1;
            transition: opacity 0.4s ease-in-out;
        }
        .form-fields,
        .button-wrapper {
            opacity: 0;
            pointer-events: none;
            transition: all 0.2s ease-in-out;
        }
    }
    form {
        .circle {
            pointer-events: none;
            width: 50px;
            height: 50px;
            top: 50%;
            left: 50%;
            position: absolute;
            opacity: 0;
            transition: opacity 0.2s 0.8s ease-in-out;
            animation: orbit 0.8s linear infinite;
            &:before {
                left: 0;
                top: 0;
            }
            &:after {
                right: 0;
                bottom: 0;
            }
            &:before,
            &:after {
                content: '';
                display: inline-block;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: $secondary;
                position: absolute;
            }
        }
    }
    .form-fields {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
        opacity: 1;
        transition: all 0.2s 1s ease-in-out;
        .error {
            padding-top: 5px;
            color: red;
            font-size: 14px;
            font-size: 1.4rem;
        }
    }
    label {
        padding: 10px;
        display: flex;
        flex-direction: column;
        span {
            @include heading(light,
            $secondary);
            margin-bottom: 10px;
        }
        input,
        textarea {
            max-width: 100%;
            width: 100%;
            font-size: 16px;
            font-size: 1.6rem;
            max-height: 120px;
            border-radius: 0;
            -webkit-appearance: none;
        }
    }
    input[type=text],
    input[type=email],
    textarea {
        padding: 12px;
        border: 1px solid $text;
    }
    .name-field,
    .email-field {
        flex: 1 1 50%;
        @include for(xs) {
            flex: 1 1 100%;
        }
    }
    .question-field,
    .file-field {
        flex: 1 1 100%;
    }
    .file-field {
        input {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
            &:hover,
            &:focus {
                +.custom-file-upload {
                    background-color: $grey;
                    &:before {
                        background-image: url('../images/icons/Symbol/orange/download-orange.svg');
                    }
                }
            }
            &:focus {
                +.custom-file-upload {
                    outline: 1px dotted $primary;
                    outline: -webkit-focus-ring-color auto 5px;
                }
            }
        }
        .custom-file-upload {
            border: 1px solid $text;
            height: 120px;
            width: 100%;
            position: relative;
            cursor: pointer;
            transition: all 0.25s ease-in-out;
            &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                width: 30px;
                height: 30px;
                transition: all 0.25s ease-in-out;
                background-image: url('../images/icons/Symbol/grey/download-grey.svg');
                background-repeat: no-repeat;
                background-size: 100% auto;
                background-position: center;
            }
        }
    }
    button.submit {
        font-size: 20px;
        font-size: 2rem;
        @include button-arrow('white',
        'primary');
        min-width: 280px;
        margin-top: 20px;
        @include for(xs) {
            font-size: 16px;
            font-size: 1.6rem;
            min-width: 260px;
        }
    }
    .button-wrapper {
        opacity: 1;
        transition: all 0.2s 1s ease-in-out;
    }
}

@keyframes bounce {
    0% {
        transform: translate3d(0, 50px, 0);
    }
    100% {
        transform: translate3d(0, 0px, 0);
    }
}

@keyframes orbit {
    0% {
        transform: translateX(-50%) translateY(0) rotate(0deg);
    }
    50% {
        transform: translateX(-50%) translateY(30px) rotate(180deg);
    }
    100% {
        transform: translateX(-50%) translateY(0) rotate(360deg);
    }
}

section.faqs-article-intro {
    .meta-share {
        display: flex;
        justify-content: space-between;
        @include for(md lg) {
            padding: 0 40px;
        }
    }
    time {
        @include heading(light,
        $text);
        @include for(xs) {
            margin-top: 0;
        }
    }
    .sharethis-custom-buttons {
        display: flex;
        justify-content: flex-end;
        margin: 0;
        @include for(xs) {
            display: none;
        }
        li {
            cursor: pointer;
            background: $secondary;
            width: 40px;
            height: 40px;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 15px;
        }
    }
}

.faqs-article-body {
    .content-modules {
        padding-top: 40px;
        @include for(sm) {
            padding-top: 20px;
        }
        @include for(xs) {
            padding-top: 20px;
        }
    }
}