$coregas-logo-width: 258px;
$coregas-logo-height: 46px;

$coregas-logo-width-xs: calc(#{$coregas-logo-width}/ 2);
$coregas-logo-height-xs: calc(#{$coregas-logo-height} / 2);


$wesfarmers-logo-width: 249px;
$wesfarmers-logo-height: 37px;

$wesfarmers-logo-width-xs: calc(#{$wesfarmers-logo-width} / 1.25);
$wesfarmers-logo-height-xs: calc(#{$wesfarmers-logo-height} / 1.25);
a.coregas-brand {
	width: $coregas-logo-width;
	height: $coregas-logo-height;
	background-image: url('../../assets/images/icons/Logo/reversed/coregas-white.svg');

	background-size: contain;
	background-position: center;
	display: inline-block;
	text-indent: -9999px;
	background-repeat: no-repeat;
	margin-top: 0;
	@include for(xs sm) {
		width: $coregas-logo-width-xs;
		height: $coregas-logo-height-xs;
	}
}

a.wesfarmers-brand {
	width: $wesfarmers-logo-width;
	height: $wesfarmers-logo-height;
	background-image: url('../../assets/images/icons/Logo/reversed/Wesfarmers-logo.svg');
	background-size: contain;
	background-position: center;
	display: inline-block;
	text-indent: -9999px;
	background-repeat: no-repeat;
	margin-top: 0;
	@include for(xs) {
		width: $wesfarmers-logo-width-xs;
		height: $wesfarmers-logo-height-xs;
	}
}