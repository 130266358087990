@keyframes orbit {
  0% {
    transform: translateX(-50%) translateY(0) rotate(0deg);
  }
  50% {
    transform: translateX(-50%) translateY(30px) rotate(180deg);
  }
  100% {
    transform: translateX(-50%) translateY(0) rotate(360deg);
  }
}

.bm-flex-form {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  margin-top: -30px;
  ~.success {
    display: none;
  }
  .circle {
    pointer-events: none;
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    position: absolute;
    opacity: 0;
    transition: opacity 0.2s 0.8s ease-in-out;
    animation: orbit 0.8s linear infinite;
    &:before {
      left: 0;
      top: 0;
    }
    &:after {
      right: 0;
      bottom: 0;
    }
    &:before,
    &:after {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $secondary;
      position: absolute;
    }
  }
  &.loading {
    .circle {
      opacity: 1;
      transition: opacity 0.4s ease-in-out;
    }
    .bm-field,
    .bm-button {
      opacity: 0;
      pointer-events: none;
      transition: all 0.2s ease-in-out;
    }
  }
}

.bm-flex-form input,
.bm-flex-form textarea,
.bm-flex-form .chosen-container,
.bm-flex-form .chosen-container .chosen-single {
  width: 100% !important;
  min-width: auto !important;
}

.bm-flex-form {
  .catchme-wrapper {
    visibility: hidden;
    opacity: 0;
    height: 1px;
    input#catchme {
      display: inline-block !important;
    }
  }
}

.bm-flex-form .wysiwyg {
  padding: 0 10px !important;
}

.bm-flex-form {
  h4 {
    margin-top: 20px;
    margin-bottom: 5px;
    color: $primary;
  }
  .bm-field-group .bm-field:first-child {
    margin-top: 0;
    h4 {
      margin-top: 0;
    }
  }
}

.bm-flex-form .bm-w-full {
  width: 100%;
}

.bm-flex-form .bm-w-4\/5 {
  width: 80%;
}

.bm-flex-form .bm-w-2\/3 {
  width: 66.6666%;
}

.bm-flex-form .bm-w-3\/5 {
  width: 60%;
}

.bm-flex-form .bm-w-1\/2 {
  width: 50%;
}

.bm-flex-form .bm-w-2\/5 {
  width: 40%;
}

.bm-flex-form .bm-w-1\/3 {
  width: 33.3333%;
}

.bm-flex-form .bm-w-3\/10 {
  width: 30%;
}

.bm-flex-form .bm-w-1\/4 {
  width: 25%;
}

.bm-flex-form .bm-w-1\/5 {
  width: 20%;
}

@media(max-width: 768px) {
  .bm-flex-form .bm-w-full,
  .bm-flex-form .bm-w-1\/2,
  .bm-flex-form .bm-w-1\/3,
  .bm-flex-form .bm-w-2\/3,
  .bm-flex-form .bm-w-1\/4,
  .bm-flex-form .bm-w-1\/5,
  .bm-flex-form .bm-w-2\/5,
  .bm-flex-form .bm-w-3\/5,
  .bm-flex-form .bm-w-4\/5,
  .bm-flex-form .bm-w-3\/10 {
    width: 100%;
  }
  .bm-flex-form .bm-w-xs-1\/2 {
    width: 50%;
  }
  .chosen-container-single .chosen-single {
    max-width: none;
  }
}

.bm-flex-form .bm-field {
  margin-top: 30px !important;
  padding-left: 10px;
  padding-right: 10px;
}

.bm-flex-form .bm-button {
  margin-left: 10px;
  font-size: 20px;
  font-size: 2rem;
  @include button-arrow('white', 'primary');
  min-width: 280px;
  margin-top: 20px;
  @include for(xs) {
    font-size: 16px;
    font-size: 1.6rem;
    min-width: 260px;
  }
}

@media(min-width: 768px) {
  .bm-flex-form .bm-button {
    margin-top: 40px !important;
  }
}

.bm-flex-form .bm-field-group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}

.bm-flex-form .bm-field-group .bm-field {
  margin-top: 10px !important;
}