.healthcareMicrosite{
    .home-page-slider{
		background-color: #fff;
	}
        .banner--healthcare .banner__background{
                height: 650px;
        }
        .banner--healthcare .banner__content{
                  -webkit-transform: translateY(calc(-80% - 50px));
                transform: translateY(calc(-80% - 50px));
        }
        .home-page-slider .slider-content-inner .banner__content-inner{
            margin-top: 2%;
            max-width: 600px;
            width: 100%;
        }
        .homepage_slider_divs.banner__form{
            position: relative;
            bottom: 36px;
        }
        .homepage_slider_divs.banner__top-pages{
            position: relative;
            bottom: 36px;
        }
	.homepage_slider_divs .banner__top-pages .arrow-list__item a:before {
 	   background-image: url(../images/arrow-blue@2x.png);
	}
	@media screen and (max-width: 992px) {
		.home-page-slider .slider-content-inner .banner__content-inner{
			margin-top: 0px;
		}
		.homepage_slider_divs.banner__top-pages	.banner__top-pages-inner{
			position:relative;
			-webkit-transform: translateY(-34%);
			transform: translateY(-34%);
		}
	}
    .homepage_slider_divs_mobile{
        display: none;
    }	
    @media screen and (max-width: 1478px) {
		.homepage_slider_divs_mobile{
            display: block;
        }
        .homepage_slider_divs_desktop{
            display: none;
        }
	.homepage_slider_divs_mobile{
		margin-top: -30px;
		margin-bottom: 20px;
	}

	}	

	.home-page-slider .slick-dots {
		bottom: 30%;
	}
@media screen and (max-width: 992px){
.home_page_slider_divs_mobile .banner__top-pages .banner__top-pages-inner {
    position: relative;
    -webkit-transform: translateY(-10%);
    transform: translateY(-10%);
}
.home-page-slider .slick-dots{
	bottom: 10%;
}
.home_page_slider_divs_mobile .banner__form{
	display: none;
}
}

@media screen and (max-width: 768px){
	.home-page-slider.banner .banner__content-inner .banner_sub_heading p{
		color: #3b3b3b !important;
	}
	 .home-page-slider.banner .banner__content-inner{
		padding-top: 0px;
		margin-bottom: 30px;
	}
	.home-page-slider .slick-dots li button{
		border: 1px solid #006fba;
		margin-bottom: 10px;
	}
	 .home-page-slider .slick-dots li{
		padding-bottom: 25px;
	}
.home_page_slider_divs_mobile .banner__top-pages .banner__top-pages-inner {
    position: relative;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
}

}
}