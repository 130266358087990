@mixin gridItem($column: 3) {
	margin-left: 30px;
	margin-bottom: 50px;
	height: auto;
	align-items: stretch;
	@include for(xs) {
		margin-bottom: 20px;
	}
	a {
		height: 100%;
		display: inline-block;
		width: 100%;
	}
	.content {
		min-height: 250px;
		height: 100%;
		@include for(xs) {
			min-height: 0;
		}
	} // Grid 4 
	@if $column==4 {
		width: calc(( 100% - 90px) / 4);
		&:nth-child(4n+1) {
			clear: both;
			margin-left: 0;
		}
		@include for(sm) {
			&:nth-child(4n+1) {
				clear: none;
				margin-left: 30px;
			}
			&:nth-child(2n+1) {
				clear: both;
				margin-left: 0px;
			}
			width: calc(( 100% - 30px) / 2);
		}
		@include for(xs) {
			&:nth-child(4n+1) {
				clear: none;
				margin-left: 30px;
			}
			&:nth-child(2n+1) {
				clear: none;
				margin-left: 0px;
			}
			width: 100%;
			clear: both;
			margin-left: 0px;
		}
	}
	@else {
		width: calc(( 100% - 61px) / 3);
		&:nth-child(4n+1) {
			clear: none;
			margin-left: 30px;
		}
		&:nth-child(3n+1) {
			clear: both;
			margin-left: 0;
		}
		@include for(sm) {
			&:nth-child(3n+1) {
				clear: none;
				margin-left: 30px;
			}
			&:nth-child(2n+1) {
				clear: both;
				margin-left: 0px;
			}
			width: calc(( 100% - 30px) / 2);
		}
		@include for(xs) {
			&:nth-child(3n+1) {
				clear: none;
				margin-left: 0px;
			}
			&:nth-child(2n+1) {
				clear: none;
				margin-left: 0px;
			}
			width: 100%;
			clear: both;
			margin-left: 0px;
		}
	}
}

@mixin gridPageItem() {
	.button {
		@include link-arrow($secondary, 'orange'); // for hover slide left
		padding-right: 10px;
	}
	h2 {
		background-color: $white;
		display: inline;
		clear: both;
		padding: 8px 20px 8px 0;
		align-self: flex-start;
		margin-top: 0;
		transition: all .2s ease-in-out;
		margin-right: 30px;
		line-height: 1.1;
		max-width: 100%;
		@include for(xs) {
			@include x-rem(font-size, 3.2);
		}
	}
	p {
		display: inline-block;
	}
	.content {
		display: flex;
		height: 100%;
		flex-direction: column;
		flex: 1 0 400px;
		&:before,
		&:after {
			content: none;
		}
		>*:nth-last-child(2) {
			margin-bottom: 20px;
		}
		>*:last-child {
			margin-top: auto;
			margin-bottom: 20px;
		}
	}
	a {
		display: inline-block;
		height: 100%;
		width: 100%;
		padding-top: calc(66.7% - 55px);
		margin-top: 0;
		background-size: 100% auto;
		background-repeat: no-repeat;
		border-bottom: 2px solid $text;
		@include for(xs) {
			padding-top: calc(66.7% - 45px);
		}
		&:hover,
		&:focus {
			border-bottom: 2px solid $secondary;
			h2 {
				color: $secondary;
			}
			.button {
				margin-left: 40px;
				padding-right: 0px;
			}
		}
	}
}

@mixin gridProductItem($page: 'listing') {
	.button {
		@include link-arrow($secondary, 'orange'); // for hover slide left
		padding-right: 10px;
	}
	.img {
		position: absolute;
		padding-top: calc(56% + 30px);
		width: 100%;
		top: 0;
		left: 0;
		background-size: cover;
		transition: filter .2s ease-in-out;
		@include for(xs) {
			padding-top: calc(56% + 40px);
		}
	}
	h2 {
		display: inline;
		clear: both;
		padding: 8px 20px 8px 0px;
		align-self: flex-start;
		max-width: calc(100% - 30px);
		margin-right: 30px;
		font-weight: 300;
		position: relative;
		transition: all .2s ease-in-out;
		margin-bottom: 0;
		margin-left: 15px;
		&:before {
			content: "";
			width: 15px;
			height: 100%;
			position: absolute;
			left: -15px;
			top: 0;
			background-color: $white;
		}
		&:after {
			content: "";
			width: 2px;
			height: 60%;
			position: absolute;
			top: 50%;
			left: -15px;
			transform: translateY(-50%);
		}
		@if $page=='listing' {
			background-color: $white;
			@include heading(h5, $text);
			margin-top: 0;
			@include for(xs) {
				@include x-rem(font-size, 2.8);
				font-weight: 300;
			}
			&:after {
				background-color: $primary;
			}
		}
		@else {
			background-color: $primary;
			@include heading(h5, $white);
			font-weight: 300;
			margin-top: 0;
			@include for(xs) {
				@include x-rem(font-size, 2.8);
			}
			&:after {
				background-color: $white;
			}
		}
	}
	p {
		display: inline-block;
	}
	&.slick-slide {
		margin-bottom: 20px;
		.img {
			opacity: .5;
		}
		.content {
			opacity: 0;
			min-height: 0;
		}
		&.slick-current {
			.img {
				opacity: 1;
			}
			.content {
				opacity: 1;
			}
		}
	}
	.content {
		display: flex;
		height: 100%;
		flex-direction: column;
		flex: 1 0 400px;
		>*:last-child {
			margin-top: auto;
			margin-bottom: 20px;
		}
		@if $page=='listing' {
			min-height: 150px;
			>*:nth-last-child(2) {
				margin-bottom: 20px;
			}
			@include for(xs) {
				min-height: 0;
			}
		}
		@else {
			min-height: 100px;
		}
	}
	a {
		display: inline-block;
		height: 100%;
		width: 100%;
		padding-top: 56%;
		margin-top: 0;
		background-size: 100% auto;
		background-repeat: no-repeat;
		transition: all .2s ease-in-out;
		position: relative;
		@if $page=='listing' {
			border-bottom: 2px solid $text;
			@include for(xs) {
				padding-top: 56%;
			}
		}
		@else {
			border-bottom: 2px solid $primary;
		}
		&:hover,
		&:focus {
			@if $page=='listing' {
				border-bottom: 2px solid $secondary;
				h2 {
					color: $secondary;
					&:after {
						background-color: $secondary;
					}
				}
			}
			@else {
				border-bottom: 2px solid $white;
			}
			.img {
				filter: brightness(.8);
			}
			.button {
				margin-left: 40px;
				padding-right: 0px;
			}
		}
	}
	ul {
		@include for(xs) {
			margin-top: 10px;
		}
		li {
			position: relative;
			@include heading(p, $text);
			margin-left: 0;
			width: 100%;
			@if $page=='listing' {
				@include heading(p, $text);
			}
			@else {
				@include heading(p, $white);
				margin-top: 5px;
			}
			@include for(xs) {
				@include x-rem(font-size, 1.6);
				margin-top: 2px;
			}
		}
	}
}

@mixin gridCTAItem() {
	padding: 25px 25px 30px;
	h2.light {
		@include heading(light, $white);
		margin-top: 0;
		@include for(xs) {
			margin-top: 0;
		}
	}
	h3 {
		@include heading(h3, $white);
		margin-top: 10px;
	}
	p {
		color: $white;
		padding-right: 20px;
	}
	.content {
		display: flex;
		height: 100%;
		min-height: 380px;
		flex-direction: column;
		@include for(xs) {
			min-height: 0;
		}
		p:last-child {
			margin-top: auto;
			margin-bottom: 0px;
			@include for(xs) {
				margin-top: 20px;
			}
		}
		p:nth-last-child(2) {
			margin-bottom: 20px;
		}
	}
	a {
		display: inline-block;
		height: 100%;
		margin-top: 0;
		>*:last-child {
			margin-top: auto;
		}
	}
	.cta-image {
		margin: 10px 0 10px auto;
		width: 110px;
		height: 110px;
		@include for(xs) {
			width: 55px;
			height: 55px;
			margin-bottom: 20px;
		}
	}
	&.orange {
		background-color: $secondary;
		.button {
			@include button-arrow('orange', 'primary');
		}
	}
	&.blue {
		background-color: $primary;
		.button {
			@include button-arrow('blue', 'primary');
		}
	}
}

ul.listing,
ul.sub-listing {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
	list-style-type: none;
	@include for(xs) {
		top: 0;
		margin-top: 40px;
	}
}

ul.listing {
	top: -50px;
	>li {
		@include gridItem(3);
		&.page-item {
			@include gridPageItem();
		}
		&.cta-item {
			@include gridCTAItem();

			.content {
				display: flex;

				.cta-image {
					float: right;
				}

				.button {
					width: 100%;
				}
			}
		}
	}
}

ul.sub-listing {
	@include for(xs) {
		margin-bottom: 40px;
	}
	>li {
		@include gridItem(4);
		&.product-page-item {
			@include gridProductItem('listing');
		}
		&.news-listing-item {
			a {
				background: $grey;
				border-bottom: none !important;
				h2 {
					background: $grey;
					margin-left: 0;
					margin-bottom: 16px;
					padding-left: 20px;
					font-family: 'Work Sans';
					font-size: 26px;
					color: #3B3B3B;
					line-height: 30px;
					&:before,
					&:after {
						display: none;
					}
				}
				.pub-date,
				.category {
					display: block;
					font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
					opacity: 0.8;
					font-size: 14px;
					letter-spacing: 2px;
					line-height: 22px;
					text-transform: uppercase;
				}
				.category {
					padding: 6px 0 10px 0;
				}
				.pub-date {
					margin: 0 20px 1rem 20px;
				}
				.excerpt {
					margin: 0 20px 20px 20px;
					font-size: 18px;
					letter-spacing: -0.5px;
					line-height: 26px;
				}
				.button-wrap {
					margin: 0 20px 20px 20px;
				}
			}
		}
		&.cta-item {
			@include gridCTAItem();
			padding: 20px 20px 25px;
			img.cta-image {
				width: 80px;
				height: 80px;
				@include for(xs) {
					width: 55px;
					height: 55px;
					margin-bottom: 20px;
				}
			}
			h3 {
				@include heading(h4, $white);
			}
			p:not(.button) {
				@include x-rem(font-size, 1.5);
			}
			.content {
				min-height: 200px;
			}
		}
	}
}

ul.news-listing {
	>li {
		@include gridItem(3);
	}
}

ul.related-products {
	li {
		@include gridItem(4);
		&.product-page-item {
			@include gridProductItem('related'); // Slick slider
			h2:before {
				background-color: $primary;
			}
			.content {
				min-height: 1rem;
				>:last-child {
					margin-top: 0;
					@include for(xs) {
						margin-bottom: 0;
					}
				}
			}
			@include for(xs) {
				float: left;
				display: inline-block;
				clear: none;
				&.slick-slide {
					margin-bottom: 0;
				}
				a {
					width: calc(100% - 10px);
				}
			}
		}
	}
}

ul.related-products {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
	list-style-type: none;
}