// Page specific style includes

.homepage-modules {
	section + section {
		margin-top: 80px;
		@include for(xs) {
			margin-top: 40px;
			
			&:not(.news-items) {
				overflow: hidden;
				display: inline-block;
				width: 100%;
			}
		}
	}
	.marketing-carousel {
		 + section {
			margin-top: 0;
		}
	}
	.cta-panel-big + .news-items, .tngg-steps + .why-choose-us, .product-carousel + .contact-panel {
		margin-top: 120px;
		@include for(xs) {
			margin-top: 40px;
		}
	}

	.cta-panel-small + .marketing-carousel {
		margin-top: 20px;
	}

	.cta-panel-small {
		.content-column + .content-column {
			padding-top: 40px;
			@include for(xs sm) {
				padding-top: 40px;
			}
			@include for(md lg) {
				padding-top: 0px;
				min-width: 60%;
				
				.button {
					min-width: 160px;
					+ br {
						display: none;
					}
					~ .button {
						margin-left: 15px;
						min-width: 300px;
					}
				}
			}
		}
	}

	
}

//
