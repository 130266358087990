@mixin nav($navBg, $navBgHover, $borderColor, $themeColor) {
    @media only screen and (max-width: 767px) {
        background-color: $navBg;
        .site-nav__top,
        .site-nav__bottom {
            >.container {
                padding: 0;
                width: 100%;
            }
        }
    }
    @media only screen and (min-width: 768px) {
        margin-bottom: calc(-72px / 2);
    }
    @media only screen and (min-width: 992px) {
        margin-bottom: calc(-75px / 2);
    }
    .logo {
        display: flex;
        flex: 1 1 200px;
        z-index: 15;
        max-height: 83px;
        justify-content: flex-start;
        align-items: center;
        @media only screen and (max-width: 767px) {
            flex: 1 1 120px;
            margin: 0;
            padding: 0 10px;
        }
    }
    .site-logo {
        margin-top: 0;
        &--coregas-product,
        &--coregas-main {
            display: inline-block;
            text-indent: -9999px;
            height: 100%;
            background-size: 100% auto;
            background-position: center;
            background-repeat: no-repeat;
        }
        // &--specialty-gases {
        // 	text-transform: uppercase;
        // 	font-weight: 900;
        // 	white-space: nowrap;
        // 	font-size: 22px;
        // 	font-size: 2.2rem;
        // 	color: $navBg;
        // 	padding: 15px 0px 10px 10px;
        // 	display: inline-block;
        // 	height: 100%;
        // 	@media only screen and (max-width: 768px) {
        // 		@include x-rem(font-size, 1.5);
        // 		line-height: 2;
        // 	}
        // 	@media only screen and (min-width: 768px) {
        // 		padding: 25px 0px 20px 0px;
        // 	}
        // 	@media only screen and (min-width: 1200px) and (max-width: 1500px) {
        // 		+ .site-logo {
        // 			width: 100px;
        // 		}
        // 	}
        // }
        &--coregas-product {
            margin-left: 25px;
            width: 120px;
            background-image: url(../images/icons/Logo/full-colour/coregas-product.svg);
            @media only screen and (max-width: 1199px) {
                display: none;
            }
        }
        &--coregas-main {
            width: 170px;
            background-image: url(../images/icons/Logo/full-colour/coregas-full.svg);
            background-size: contain;
            @media only screen and (max-width: 767px) {
                width: 110px;
                height: 20px;
                background-size: 100% auto;
                background-position: center;
                background-image: url(../images/icons/Logo/reversed/coregas-white.svg);
            }
        }
        &--healthcare,
        &--tngg,
        &--specialty-gases {
            text-transform: uppercase;
            font-weight: 900;
            white-space: nowrap;
            font-size: 26px;
            font-size: 2.6rem;
            color: $white;
            display: flex;
            align-items: center;
            height: 100%;
            @media only screen and (min-width: 768px) {
                padding: 15px 0px 10px 0px;
                color: $navBg;
            }
            @media only screen and (max-width: 768px) {
                @include x-rem(font-size, 1.8);
            }
        }
        &--service-center {
            color: $navBg;
            display: none;
            align-items: center;
            @include x-rem(font-size, 1.6);
            white-space: nowrap;
            margin-left: 30px;
            @media only screen and (min-width: 768px) {
                display: flex;
            }
            @media only screen and (min-width: 1200px) {
                @include x-rem(font-size, 2);
            }
        }
    }
    .site-nav {
        &__utils {
            display: flex;
            margin: 0;
            @media only screen and (max-width: 767px) {
                background-color: $navBg;
                position: fixed;
                top: 0;
                z-index: 100;
                width: 100%;
            }
            @media only screen and (min-width: 992px) {
                padding: 0 30px;
            }
            li.change-site {
                display: none;
                padding: 25px 0px;
                cursor: pointer;
                user-select: none;
                @media only screen and (min-width: 768px) {
                    display: inline-block;
                }
                @media only screen and (max-width: 991px) {
                    padding: 25px 5px;
                }
            }
            li.icon {
                margin-top: 0;
                a {
                    margin: 0;
                    display: inline-block;
                    color: $navBg;
                    position: relative;
                    height: 100%;
                    @include x-rem(font-size, 1.8);
                    padding: 10px 8px;
                    text-indent: -9999px;
                    width: 50px;
                    @media only screen and (min-width: 768px) {
                        padding: 25px 20px;
                    }
                    @media only screen and (min-width: 992px) {
                        text-indent: 0;
                        width: auto;
                        padding: 25px 45px 25px 15px;
                    }
                    &:hover,
                    &:focus {
                        background-color: $grey;
                    }
                    &:after {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        right: 0;
                        content: "";
                        background-size: 20px;
                        background-position: center;
                        background-repeat: no-repeat;
                        @media only screen and (min-width: 768px) {
                            background-size: 22px;
                        }
                        @media only screen and (min-width: 992px) {
                            background-size: 25px;
                            background-position: calc(100% - 10px) 50%;
                        }
                    }
                }
                &--storefinder {
                    a:after {
                        background-image: url('../../assets/images/icons/Symbol/#{$themeColor}/store-location-#{$themeColor}.svg');
                        @media only screen and (max-width: 767px) {
                            background-image: url('../../assets/images/icons/Symbol/reversed/store-location-white.svg');
                        }
                    }
                }
                &--contact {
                    a:after {
                        background-image: url('../../assets/images/icons/Symbol/#{$themeColor}/phone-#{$themeColor}.svg');
                    }
                }
                &--login {
                    a:after {
                        background-image: url('../../assets/images/icons/Symbol/#{$themeColor}/profile-#{$themeColor}.svg');
                        @media only screen and (max-width: 767px) {
                            background-image: url('../../assets/images/icons/Symbol/reversed/profile-white.svg');
                        }
                    }
                }
                &--menu {
                    a {
                        text-indent: -99999px;
                        width: 40px;
                    }
                    a:after {
                        background-image: url('../../assets/images/icons/Symbol/reversed/hamburger-menu-white.svg');
                    }
                }
            }
            .search {
                &__field {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    border-radius: 0;
                    @media only screen and (min-width: 768px) {
                        border-bottom: 1px solid $navBg;
                        color: $navBg;
                    }
                    &::-webkit-input-placeholder {
                        /* Chrome/Opera/Safari */
                        color: rgba($white, .7);
                        @media only screen and (min-width: 768px) {
                            color: rgba($navBg, .7);
                        }
                    }
                    &::-moz-placeholder {
                        /* Firefox 19+ */
                        color: rgba($white, .7);
                        @media only screen and (min-width: 768px) {
                            color: rgba($navBg, .7);
                        }
                    }
                    &:-ms-input-placeholder {
                        /* IE 10+ */
                        color: rgba($white, .7);
                        @media only screen and (min-width: 768px) {
                            color: rgba($navBg, .7);
                        }
                    }
                    &:-moz-placeholder {
                        /* Firefox 18- */
                        color: rgba($white, .7);
                        @media only screen and (min-width: 768px) {
                            color: rgba($navBg, .7);
                        }
                    }
                }
                &__form {
                    @media only screen and (max-width: 767px) {
                        background-color: $navBg;
                    }
                }
                &__submit {
                    @media only screen and (min-width: 768px) {
                        background-image: url('../../assets/images/icons/Symbol/#{$themeColor}/search-#{$themeColor}.svg');
                    }
                }
            }
        }
        &__xs-only {
            &.icon {
                a {
                    color: $navBg;
                    position: relative;
                    &:after {
                        width: 40px;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        right: 10px;
                        content: "";
                        background-size: 20px;
                        background-position: center right;
                        background-repeat: no-repeat;
                    }
                }
                &--storefinder {
                    a:after {
                        background-image: url('../../assets/images/icons/Symbol/#{$themeColor}/store-location-#{$themeColor}.svg');
                    }
                }
                &--login {
                    a:after {
                        background-image: url('../../assets/images/icons/Symbol/#{$themeColor}/profile-#{$themeColor}.svg');
                    }
                }
            }
            @media only screen and (min-width: 768px) {
                display: none;
            }
        }
        &__nav {
            margin: 0;
            list-style-type: none;
            align-items: stretch;
            z-index: 10;
            display: flex;
            justify-content: flex-start;
            position: relative;
            @media only screen and (min-width: 768px) {
                background-color: $navBg;
                padding: 0 10px;
            }
            >li {
                margin-top: 0;
                >a {
                    margin: 0;
                    color: $text;
                    display: inline-block;
                    position: relative;
                    font-size: 16px;
                    font-size: 1.6rem;
                    width: 100%;
                    padding: 12px 15px;
                    border-bottom: 1px solid $grey;
                    @media only screen and (min-width: 768px) {
                        color: $white;
                        border-bottom: none;
                        padding: 25px 15px;
                    }
                    @media only screen and (min-width: 992px) {
                        font-size: 18px;
                        font-size: 1.8rem;
                        padding: 25px 25px;
                    }
                    @media only screen and (min-width: 768px) {
                        &:hover,
                        &:focus {
                            background-color: $navBgHover;
                        }
                    }
                }
                &.mega-menu {
                    >a {
                        padding: 12px 30px 12px 15px;
                        @media only screen and (min-width: 768px) {
                            padding: 25px 38px 25px 15px;
                        }
                        @media only screen and (min-width: 992px) {
                            padding: 25px 42px 25px 20px;
                        }
                        &:after {
                            content: '';
                            width: 14px;
                            height: 16px;
                            background-image: url(../images/icons/Symbol/grey/chevron-right-grey.svg);
                            background-repeat: no-repeat;
                            background-size: 100% auto;
                            background-position: center;
                            position: absolute;
                            right: 12px;
                            top: 50%;
                            transform: translateY(-50%);
                            @media only screen and (min-width: 768px) {
                                width: 14px;
                                height: 10px;
                                right: 15px;
                                background-image: url(../images/icons/Symbol/reversed/chevron-down-white.svg);
                            }
                            @media only screen and (min-width: 992px) {
                                right: 18px;
                            }
                        }
                        // @media only screen and (min-width: 768px) {
                        // 	// &:hover,
                        // 	&:focus {
                        // 		&:after {
                        // 			transform: rotate(180deg) translateY(50%);
                        // 		}
                        // 		// + .mega-menu__wrapper {
                        // 		// 	opacity: 1;
                        // 		// 	visibility: visible;
                        // 		// 	transform: translateY(0);
                        // 		// }
                        // 	}
                        // }
                    }
                    @media only screen and (min-width: 768px) {
                        // &:hover, 
                        // &:focus-within {
                        // 	.mega-menu__wrapper {
                        // 		opacity: 1;
                        // 		visibility: visible;
                        // 		transform: translateY(0);
                        // 		transition-duration: 200ms;
                        // 	}
                        // 	>a:after {
                        // 		transform: rotate(180deg) translateY(50%);
                        // 	}
                        // 	>a {
                        // 		background-color: $navBgHover;
                        // 	}
                        // }
                        &.mega-menu--active {
                            .mega-menu__wrapper {
                                opacity: 1;
                                visibility: visible;
                                transform: translateY(0);
                                transition-duration: 200ms;
                            }
                        }
                    }
                }
            }
            li.site-nav__arrow {
                margin-top: 50px;
                border-top: 1px solid $grey;
                ~li {
                    border-top: none;
                    margin-top: 0;
                }
                a {
                    padding-left: 40px;
                    position: relative;
                    &:before {
                        content: "";
                        background-size: 100% auto;
                        background-repeat: no-repeat;
                        background-position: center;
                        position: absolute;
                        display: inline-block;
                        width: 20px;
                        height: 15px;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 10px;
                    }
                }
                &--orange>a {
                    color: $secondary;
                    &:before {
                        background-image: url(../images/icons/Symbol/orange/arrow-right-orange.svg);
                    }
                }
                &--grey>a {
                    &:before {
                        background-image: url(../images/icons/Symbol/grey/arrow-right-grey.svg);
                    }
                }
            }
        }
        &__mobile-menu-btn {
            @media only screen and (min-width: 768px) {
                display: none;
            }
        }
        // Mobile menu
        &__bottom {
            @media only screen and (min-width: 768px) {
                .site-nav__close-btn {
                    display: none;
                }
            }
            @media only screen and (max-width: 767px) {
                width: 100%;
                .site-nav__close-btn {
                    width: 48px;
                    height: 48px;
                    position: fixed;
                    top: 0;
                    left: 0;
                    transform: translateX(-100%);
                    transition: left 0.2s ease;
                    display: inline-block;
                    z-index: 100;
                    a {
                        width: 100%;
                        height: 100%;
                        background-color: $navBg;
                        position: relative;
                        text-indent: -9999px;
                        display: inline-block;
                        margin-top: 0;
                        &:after {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            right: 0;
                            content: "";
                            background-size: 20px;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-image: url('../../assets/images/icons/Symbol/reversed/cross-close-white.svg');
                        }
                    }
                }
                .site-nav__nav {
                    position: fixed;
                    top: 0;
                    left: 0;
                    transform: translateX(-100%);
                    transition: transform 0.2s ease;
                    flex-direction: column;
                    width: calc(100% - 48px);
                    height: 100vh;
                    z-index: 100;
                    overflow-y: scroll;
                    background-color: $white;
                }
                &--open {
                    .site-nav__nav {
                        transform: translateX(0%);
                    }
                    .site-nav__close-btn {
                        left: 100%;
                    }
                }
            }
        }
    }
    .change-site {
        &__style-wrap {
            &:after {
                background-image: url(../images/icons/Symbol/#{$themeColor}/chevron-down-#{$themeColor}.svg);
            }
        }
        select {
            color: $navBg;
        }
    }
}

@media (min-width:768px) {
    .site-nav {
        position: sticky;
        top: -75px;
        z-index: 80;
    }
}

.microsite {
    .change-site.change-site.change-site {
        display: none;
    }
}

.mega-menu {
    // Hide extra menus
    a {
        cursor: pointer;
    }
    &__wrapper {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        opacity: 0;
        visibility: hidden;
        transform: translateY(10px);
        transition: -webkit-transform 200ms 0ms ease-in-out, transform 200ms 0ms ease-in-out, opacity 50ms 0ms ease-in-out;
        max-height: 70vh;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
        @media only screen and (max-width: 767px) {
            position: fixed;
            left: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            display: block;
            transform: translateX(-100%);
            transition: -webkit-transform 0.2s ease, transform 0.2s ease;
            height: 100vh;
            max-height: none;
            z-index: 101;
            visibility: visible;
            opacity: 1;
        }
        @media only screen and (min-width: 768px) {
            width: 690px;
        }
        @media only screen and (min-width: 992px) {
            width: 930px;
        }
        @media only screen and (min-width: 1200px) {
            width: 100%;
        }
    }
    &__level-2 {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        width: 100%;
        background-color: #fff;
        
        @media only screen and (max-width: 767px) {
            min-height: 100%;
        }
        @media only screen and (min-width: 768px) {
            padding-top: 20px;
            flex-direction: row;
        }
        a:first-of-type{
            z-index: 111;
        }
    }
    &__back {
        display: inline-block;
        background-color: $grey-bg;
        color: $white;
        padding: 13px 15px 13px 40px;
        width: 100%;
        position: relative;
        margin-top: 0;
        min-height: 48px;
        &:before {
            background-image: url(../images/icons/Symbol/reversed/chevron-left-white.svg);
            content: "";
            background-size: 100% auto;
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            display: inline-block;
            width: 20px;
            height: 15px;
            top: 50%;
            transform: translateY(-50%);
            left: 10px;
        }
        @media only screen and (min-width: 768px) {
            display: none;
        }
    }
    &--active {
        @media only screen and (max-width: 767px) {
            .mega-menu__wrapper {
                transform: translateX(0%);
            }
        }
    }
    &__items {
        background-color: $white;
        flex: 1 1 100%;
        max-width: 100%;
        padding: 0;
        margin-top: 0;
        padding-bottom: 40px;
        @media only screen and (min-width: 768px) {
            padding: 25px;
        }
        @media only screen and (min-width: 992px) {
            flex: 1 1 66%;
            max-width: 70%;
        }
        @media only screen and (min-width: 1200px) {
            padding: 25px 45px;
        }
        li {
            margin-top: 0;
        }
        a {
            margin: 0;
            padding: 10px 15px;
            display: inline-block;
            width: 100%;
            font-size: 16px;
            font-size: 1.6rem;
            @media only screen and (min-width: 992px) {
                font-size: 18px;
                font-size: 1.8rem;
            }
            &:hover,
            &:focus {
                background-color: $grey;
            }
            &:active {
                background-color: $text;
                color: $white;
            }
        }
        >a {
            border-top: 1px solid $grey;
            border-bottom: 1px solid $grey;
            font-weight: 500;
            color: #4A4A4A;
        }
        ul {
            @media only screen and (min-width: 768px) {
                margin-top: 46px;
                column-count: 2;
                border-top: 1px solid $grey;
                column-gap: 18px;
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    width: 18px;
                    height: 1px;
                    background: white;
                    left: 50%;
                    top: -1px;
                    transform: translateX(-50%);
                }
            }
            li a {
                border-bottom: 1px solid $grey;
            }
        }
    }
    &__sidebar {
        background-color: $white;
        flex: 1 1 100%;
        padding: 30px 25px;
        position: relative;
        @media only screen and (min-width: 992px) {
            flex: 1 1 33%;
            min-width: 300px;
            max-width: 30%;
            padding: 30px 40px;
            &:before {
                content: '';
                width: 1px;
                height: calc(100% - 50px);
                background-color: $grey;
                position: absolute;
                top: 25px;
                left: 0;
            }
        }
        @media only screen and (max-width: 767px) {
            display: none;
        }
        li:first-child a {
            border-top: 1px solid $secondary;
        }
        a {
            margin: 0;
            padding: 12px 15px 12px 40px;
            display: inline-block;
            color: $secondary;
            position: relative;
            border-bottom: 1px solid $secondary;
            width: 100%;
            font-size: 16px;
            font-size: 1.6rem;
            @media only screen and (min-width: 992px) {
                font-size: 18px;
                font-size: 1.8rem;
            }
            &:before {
                background-image: url(../images/icons/Symbol/orange/arrow-right-orange.svg);
                content: "";
                background-size: 100% auto;
                background-repeat: no-repeat;
                background-position: center;
                position: absolute;
                display: inline-block;
                width: 20px;
                height: 15px;
                top: 50%;
                transform: translateY(-50%);
                left: 10px;
            }
            &:hover,
            &:focus {
                color: $white;
                background-color: $secondary;
                &:before {
                    background-image: url(../images/icons/Symbol/reversed/arrow-right-white.svg);
                }
            }
            &:active {
                background-color: $secondary-shade;
            }
        }
        >a {
            display: none;
        }
        p {
            margin-bottom: 10px;
        }
    }
}

.site-nav {
    &--theme-blue {
        @include nav($primary,
        $primary-hover,
        $secondary,
        'blue');
    }
    &--theme-orange {
        @include nav($secondary,
        $secondary-shade,
        $primary,
        'orange');
    }
}

body.menu-open {
    main {
        position: relative;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $grey-bg;
            opacity: 0.4;
        }
        // 
        .product-image-container,
        .header-image-container {
            top: -1px;
            z-index: 0;
        }
    }
}

@media only screen and (max-width: 767px) {
    body.menu-open {
        .site-nav__top .site-nav__nav {
            display: flex;
        }
        .blackout {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $grey-bg;
            opacity: 0.4;
            z-index: 99;
        }
    }
    body.search-open {
        .site-nav__top li:not(.search) {
            pointer-events: none;
            a,
            &:after {
                opacity: 0;
            }
        }
    }
    body.menu-open,
    body.search-open {
        overflow: hidden;
        height: 100vh;
        main {
            pointer-events: none;
            overflow: hidden;
        }
    }
}

.change-site {
    padding-right: 20px !important;
    margin-right: -20px !important;
    &__style-wrap {
        font-size: 16px;
        font-size: 1.6rem;
        position: relative;
        @media only screen and (min-width: 992px) {
            font-size: 18px;
            font-size: 1.8rem;
        }
        &:after {
            content: '';
            width: 14px;
            height: 10px;
            background-repeat: no-repeat;
            background-size: 100% auto;
            background-position: center;
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
        }
    }
    &__link {
        color: $primary;
        padding-right: 20px;
        padding-left: 20px;
    }
    &__dropdown {
        position: absolute;
        top: 100%;
        margin-top: 90px;
        right: 15px;
        width: auto;
        z-index: 80;
        background-color: $white;
        padding: 30px;
        display: none;
        a {
            display: block;
            padding: 10px 50px 10px 20px;
            border-bottom: 1px solid #f2f2f2;
            margin: 0;
            &:first-child {
                border-top: 1px solid #f2f2f2;
            }
            &:hover,
            &:focus {
                background-color: $grey;
            }
            &:active {
                background-color: $text;
                color: $white;
            }
        }
    }
    &:hover {
        background-color: #f2f2f2;
    }
    &.active {
        .change-site__style-wrap:after {
            transform: scaleY(-1);
            margin-top: -5px;
        }
        .change-site__dropdown {
            display: block;
        }
    }
}

.search {
    position: relative;
    margin: 0;
    @media only screen and (min-width: 768px) {
        padding: 0 10px;
    }
    @media only screen and (max-width: 767px) {
        order: 4;
    }
    &__field::-ms-clear {
        display: none;
    }
    &__field {
        background-color: transparent;
        padding: 10px 40px 8px 0px;
        border: none;
        border-bottom: 1px solid $white;
        color: $white;
        z-index: 8;
        width: calc(100% - 40px);
        @media only screen and (min-width: 768px) {
            width: 225px;
            height: 22px;
        }
        &:focus {
            outline: none;
            &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                opacity: 0;
            }
            &::-moz-placeholder {
                /* Firefox 19+ */
                opacity: 0;
            }
            &:-ms-input-placeholder {
                /* IE 10+ */
                opacity: 0;
            }
            &:-moz-placeholder {
                /* Firefox 18- */
                opacity: 0;
            }
        }
    }
    &__toggle-btn {
        display: inline-block;
        position: relative;
        width: 50px;
        height: 100%;
        margin: 0;
        padding: 10px 8px;
        text-indent: -9999px;
        &:after {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            content: "";
            background-size: 20px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url('../../assets/images/icons/Symbol/reversed/search-white.svg');
        }
        @media only screen and (min-width: 768px) {
            display: none;
        }
    }
    &__form {
        padding: 15px 0;
        position: relative;
        @media only screen and (max-width: 767px) {
            display: none;
            position: fixed;
            top: 45px;
            left: 0;
            width: 100%;
            height: calc(100vh - 45px);
            padding: 15px;
        }
    }
    &__submit {
        width: 40px;
        padding: 25px 15px;
        text-indent: -99999px;
        position: absolute;
        right: 10px;
        top: 0;
        height: auto;
        margin: 0;
        background-image: url('../../assets/images/icons/Symbol/reversed/search-white.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 20px;
        @media only screen and (min-width: 768px) {
            right: -5px;
            background-size: 22px;
        }
    }
    &--open {
        .search {
            &__toggle-btn:after {
                background-image: url('../../assets/images/icons/Symbol/reversed/cross-close-white.svg');
            }
            &__form {
                display: inline-block;
            }
        }
    }
}
