.en-columns {
  display: grid;
  grid-template-columns: minmax(0, 1fr);

  &-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  &-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  &-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  &-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  &-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  @include breakpoint($sm) {
    &-sm {
      &-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      &-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
      &-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
      &-5 {
        grid-template-columns: repeat(5, minmax(0, 1fr));
      }
      &-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr));
      }
    }
  }

  @include breakpoint($md) {
    &-md {
      &-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      &-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
      &-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
      &-5 {
        grid-template-columns: repeat(5, minmax(0, 1fr));
      }
      &-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr));
      }
    }
  }

  @include breakpoint($lg) {
    &-lg {
      &-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      &-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
      &-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
      &-5 {
        grid-template-columns: repeat(5, minmax(0, 1fr));
      }
      &-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr));
      }
    }
  }

  @include breakpoint($xl) {
    &-lg {
      &-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      &-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
      &-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
      &-5 {
        grid-template-columns: repeat(5, minmax(0, 1fr));
      }
      &-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr));
      }
    }
  }

  &-gap {
    &-0 {
      gap: 0;
    }
    &-1 {
      gap: 1rem;
    }
    &-2 {
      gap: 1.5rem;
    }
    &-3 {
      gap: 2rem;
    }
    &-4 {
      gap: 2.5rem;
    }
    &-5 {
      gap: 3rem;
    }
    &-6 {
      gap: 4rem;
    }
    &-7 {
      gap: 5rem;
    }
    &-8 {
      gap: 6rem;
    }

    @include breakpoint($sm) {
      &-sm {
        &-0 {
          gap: 0;
        }
        &-1 {
          gap: 1rem;
        }
        &-2 {
          gap: 1.5rem;
        }
        &-3 {
          gap: 2rem;
        }
        &-4 {
          gap: 2.5rem;
        }
        &-5 {
          gap: 3rem;
        }
        &-6 {
          gap: 4rem;
        }
        &-7 {
          gap: 5rem;
        }
        &-8 {
          gap: 6rem;
        }
      }
    }

    @include breakpoint($md) {
      &-md {
        &-0 {
          gap: 0;
        }
        &-1 {
          gap: 1rem;
        }
        &-2 {
          gap: 1.5rem;
        }
        &-3 {
          gap: 2rem;
        }
        &-4 {
          gap: 2.5rem;
        }
        &-5 {
          gap: 3rem;
        }
        &-6 {
          gap: 4rem;
        }
        &-7 {
          gap: 5rem;
        }
        &-8 {
          gap: 6rem;
        }
      }
    }

    @include breakpoint($lg) {
      &-lg {
        &-0 {
          gap: 0;
        }
        &-1 {
          gap: 1rem;
        }
        &-2 {
          gap: 1.5rem;
        }
        &-3 {
          gap: 2rem;
        }
        &-4 {
          gap: 2.5rem;
        }
        &-5 {
          gap: 3rem;
        }
        &-6 {
          gap: 4rem;
        }
        &-7 {
          gap: 5rem;
        }
        &-8 {
          gap: 6rem;
        }
      }
    }
  }
}
