.en-block {
  margin-block: 30px;

  @include breakpoint($sm) {
    margin-block: 40px;
  }

  @include breakpoint($lg) {
    margin-block: 60px;
  }

  &.has-bg {
    padding-block: 24px;

    @include breakpoint($sm) {
      padding-block: 32px;
    }

    @include breakpoint($lg) {
      padding-block: 100px;
    }
  }
}
