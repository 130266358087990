@use 'sass:color';  

section.news-filter,
section.news-search,
section.news-results {
    .content {
        background: white;
        padding: 33px 40px 0 40px;
        @include for(xs) {
            padding: 25px 20px 0 20px;
        }
    }
    .title {
        opacity: 0.8;
        font-size: 14px;
        color: $secondary;
        letter-spacing: 2px;
        line-height: 22px;
        margin: 0 0 20px 0;
    }
}

section.news-filter {
    margin-top: -50px;
    .content {
        @include for(xs) {
            padding-bottom: 0;
        }
    }
    .category-nav {
        display: flex;
        flex-wrap: wrap;
        .category-item {
            width: 25%;
            font-size: 16px;
            margin: 0 0 20px 0;
            @include for(sm) {
                width: 50%;
            }
            @include for(xs) {
                width: 100%;
            }
            a {
                position: relative;
                padding-left: 15px;
                padding-right: 10px;
                display: block;
                &:before {
                    content: "";
                    width: 2px;
                    height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    background-color: $primary;
                }
                &:hover,
                &:focus {
                    color: #000053;
                    &:before {
                        background: #000053;
                    }
                }
                &:active {                    
                    color: color.scale(#000053, $lightness: -30%);
                    &:before {
                        background: color.scale(#000053, $lightness: -30%)
                    }
                }
                &.active {
                    color: $secondary;
                    &:before {
                        background-color: $secondary;
                    }
                    &:hover,
                    &:focus {
                        color: #b44b09;
                        &:before {
                            background: #b44b09;
                        }
                    }
                    &:active {
                        color: color.scale(#b44b09, $lightness: -30%);
                        &:before {                            
                            background: color.scale(#b44b09, $lightness: -30%)
                        }
                    }
                }
            }
        }
    }
}

section.news-search {
    .content {
        padding-top: 0;
    }
    input {
        border: 1px solid #9d9d9d;
        padding: 16px 30px;
        opacity: 0.5;
        font-size: 22px;
        box-sizing: border-box;
        @include for(xs) {
            width: 100%;
            margin-bottom: 10px;
            -webkit-appearance: none;
            border-radius: 0;
        }
        &:hover {
            border: 2px solid #6c6c6c;
            padding: 15px 29px;
        }
        &:focus {
            border-color: #3b3b3b;
        }
    }
    button {
        @include button-arrow('orange');
        border: none !important;
        margin-left: 20px;
        min-width: 220px;
        font-size: 22px;
        @include for(xs) {
            margin: 0;
            width: 100%;
        }
    }
}

section.news-results {
    @include for(xs sm) {
        padding: 25px 0 0;
    }
    &.no-results {
        margin-bottom: 80px;
    }
    .search-text {
        font-size: 18px;
        line-height: 25px;
        color: $text;
        font-weight: 400;
    }
    .remove-filter {
        color: $secondary;
        display: inline-block;
        padding: 5px 0 0 0;
        margin: 0;
    }
    .content {
        padding-top: 15px;
        @include for(xs) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

.news-listing-feature {
    display: block;
    color: $white;
    margin-bottom: 60px;
    img {
        width: 100%;
        height: auto;
        @supports(object-fit: cover) {
            max-height: 527px;
            object-fit: cover;
            @include for(md) {
                max-height: 500px;
            }
            @include for(sm) {
                max-height: 430px;
            }
            @include for(xs) {
                max-height: 320px;
            }
        }
    }
    .content {
        position: relative;
        background: $primary;
        margin-top: -100px;
        margin-left: 60px;
        width: 63%;
        padding: 30px 40px;
        @include for(xs sm) {
            width: 80%;
        }
        @include for(xs) {
            margin: -50px auto 0;
            width: calc(100% - 30px);
            padding: 25px 20px;
        }
    }
    h2 {
        margin: 0 0 45px 0;
        font-family: 'Work Sans';
        font-size: 26px;
        color: $white;
        line-height: 30px;
        &:before,
        &:after {
            display: none;
        }
    }
    .pub-date,
    .category {
        display: block;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        opacity: 0.8;
        font-size: 14px;
        letter-spacing: 2px;
        line-height: 22px;
        text-transform: uppercase;
        margin-bottom: 10px;
    }
    .excerpt {
        color: $white;
        font-size: 18px;
        letter-spacing: -0.5px;
        line-height: 26px;
        margin-bottom: 20px;
    }
    .button-wrap {
        .button {
            @include link-arrow('white');
        }
    }
}

section.news-listing-grid {
    margin-top: 60px;
    margin-bottom: 80px;
}

section.news-article-intro {
    @include for(xs sm) {
        padding-top: 20px;
    }
    .content {
        padding: 30px 40px 40px;
        @include for(xs) {
            padding: 15px;
        }
    }
    background: $grey;
    .meta-share {
        width: 55%;
        padding-bottom: 30px;
        border-bottom: 2px solid $primary;
        display: flex;
        align-items: center;
        margin-bottom: 60px;
        @include for(xs sm) {
            width: 100%;
        }
        @include for(xs) {
            padding-top: 20px;
            margin-bottom: 40px;
        }
        time {
            text-transform: uppercase;
            opacity: 0.8;
            font-size: 14px;
            letter-spacing: 2px;
            line-height: 22px;
            width: 50%;
        }
        .sharethis-custom-buttons {
            width: 50%;
            display: flex;
            justify-content: flex-end;
            @include for(xs) {
                display: none;
            }
            li {
                cursor: pointer;
                background: $secondary;
                width: 40px;
                height: 40px;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 15px;
            }
        }
    }
    .sub-header {
        padding-bottom: 20px;
        @include for(xs sm) {
            padding-bottom: 35px;
        }
        p {
            font-size: 30px;
            line-height: 40px;
            @include for(xs) {
                font-size: 22px;
                line-height: 1.5;
            }
        }
    }
}

section.news-article-body {
    margin-bottom: 100px;
    @include for(xs) {
        margin-bottom: 40px;
    }
    .content-modules {
        padding-top: 45px;
        @include for(xs) {
            padding-top: 30px;
        }
        .wysiwyg {
            padding: 0 60px 40px 60px;
            width: 75%;
            @include for(xs sm) {
                width: 100%;
            }
            @include for(xs) {
                padding: 0;
            }
            h3 {
                margin-bottom: 20px;
            }
        }
        section.case-study {
            background-color: $white;
            margin-top: 55px;
            .feature-image {
                display: block;
                margin: 0 auto;
                position: relative;
                z-index: 10;
                width: 100%;
                height: auto;
                padding: 0 60px;
                @include for(xs) {
                    padding: 0;
                }
            }
            .block-content {
                bottom: 60px;
                @include for(xs) {
                    bottom: 30px;
                }
            }
            .block-content .content {
                background: $primary;
                padding: 120px 60px 40px 60px;
                @include for(xs) {
                    padding: 60px 15px 40px 15px;
                }
                h3 {
                    @include heading(light, $white);
                    margin-top: 0;
                }
                h2 {
                    font-family: 'Work Sans';
                    font-size: 45px;
                    text-transform: none;
                    opacity: 1;
                    letter-spacing: 0;
                    line-height: 54px;
                    @include for(xs) {
                        color: $white;
                    }
                }
                p {
                    @include for(xs) {
                        color: $white;
                    }
                }
            }
        }
    }
}

.news-article-related {
    background: $grey;
    margin-top: -40px;
    padding-top: 60px;
    padding-bottom: 80px;
    @include for(xs) {
        padding-top: 40px;
        padding-bottom: 20px;
    }
    .section-title {
        opacity: 0.8;
        font-size: 14px;
        letter-spacing: 2px;
        line-height: 22px;
        text-transform: uppercase;
        margin-bottom: 20px;
        @include for(xs) {
            margin-bottom: 0;
        }
    }
    .news-listing-item {
        a,
        h2 {
            background: $white !important;
        }
    }
}