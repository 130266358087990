footer {
	background-color: $text;
	color: $white;
	position: relative;
	height: 100%;
	padding-top: 50px;
	padding-bottom: 40px;
	@include for(xs) {
		padding-top: 30px;
	}
	.top-bar{
		margin-bottom: 55px;
		@include for(xs) {
			margin-bottom: 20px;
		}
		.back-to-top {
			float: right;
			@include link-arrow();
			&:before {
				top: 50%;
				transform: translateY(-50%) rotate(-90deg);
			}
			@include for(xs) {
				display: none;
			}
		}
		.coregas-brand {
			float: left;
		}
		overflow: hidden;
		.social {
			display: none;
			float: right;
			@include for(xs) {
				display: inline-block;
			}
		 	ul {
		 		margin-top: 0;
		 		li {
			 		display: inline-block;
			 		width: 24px;
			 		height: 24px;
			 		padding: 0;
			 		margin-left: 10px;
			 		margin-top: 0;
			 		&:first-child {
			 			margin-left: 0;
			 		}
			 		a {
			 			text-indent: -9999px;
			 			display: inline-block;
			 			width: 24px;
			 			height: 24px;
			 			margin-top: 0;
			 			background-size: 100%;
			 			background-position: center;
			 			background-repeat: no-repeat;
			 			
			 		}
			 		&.social-facebook {
			 			a {
			 				background-image: url('../../assets/images/icons/Symbol/reversed/facebook-white.svg');
			 				&:hover, &:focus, &:active {
				 				background-image: url('../../assets/images/icons/Symbol/orange/facebook-orange.svg');
				 			}
			 			}
			 		}
			 		&.social-youtube {
			 			a {
			 				background-image: url('../../assets/images/icons/Symbol/reversed/youtube-white.svg');
			 				&:hover, &:focus, &:active {
				 				background-image: url('../../assets/images/icons/Symbol/orange/youtube-orange.svg');
				 			}
			 			}
			 		}
			 		&.social-linkedin {
			 			a {
			 				background-image: url('../../assets/images/icons/Symbol/reversed/linkedin-white.svg');
			 				&:hover, &:focus, &:active {
				 				background-image: url('../../assets/images/icons/Symbol/orange/linkedin-orange.svg');
				 			}
			 			}
			 		}
			 		&.social-twitter {
			 			a {
			 				background-image: url('../../assets/images/icons/Symbol/reversed/twitter-white.svg');
			 				&:hover, &:focus, &:active {
				 				background-image: url('../../assets/images/icons/Symbol/orange/twitter-orange.svg');
				 			}
			 			}
			 		}
			 	}
		 	}
		}
	}
	
	nav.navfooter {
		overflow: hidden;
		ul {
			list-style-type: none;
			width: 100%;
			@include for(xs) {
				&.nav {
					border-top: 1px solid rgba($grey, .5);
					border-bottom: 1px solid rgba($grey, .5);
					display: inline-block;
					padding-bottom: 15px;	
					padding-top: 15px;
				}
				
			}
			li {
				display: inline-block;
				width: calc(100% / 4 - 20px);
				float: left;
				margin-left: 20px;
				@include for(xs) {
					width: 100%;
					margin-left: 0;
					padding: 0 15px;
					margin-top: 5px;
					&.social {
						display: none;
					}
					.nav__level2 {
						display: none;
					}
				}
				&:first-child{
					margin-left: 0;
				}
				a {
					color: $white;
					&:hover, &:focus {
						text-decoration: underline;
					}
					&:active {
						opacity: .8;
						text-decoration: underline;
					}
				}
				&.has-children {
					margin-top: 20px;
					> a {
						@include heading(light, $white);
						@include for(sm md lg) {
							pointer-events: none;
							&:hover, &:focus {
								text-decoration: none;
							}
						}
					}
					@include for(xs) {
						margin-top: 0px;
						.nav__level2 {
							padding-bottom: 10px;
						}
						> a {
							display: inline-block;
							margin-top: 0;
							width: 100%;
							padding: 5px 0;
							position: relative;
							&:after {
								content: " ";
								width: 13px;
								height: 8px;
								background-image: url('../../assets/images/chevron-white@2x.png');
								background-size: 100%;
								background-repeat: no-repeat;
								position: absolute;
								display: inline-block;
								right: 0px;
								top: 50%;
								transform: translateY(-50%);
								color: $white;
								transition: transform .2s ease-in-out;
							}
						}
						&.open > a:after {
							transform: translateY(-50%) rotate(180deg);
						}
					}
				}
				.social {
					@include for(xs) {
						display: none;
					}
				 	ul {
				 		li {
					 		display: inline-block;
					 		width: 30px;
					 		height: 30px;
					 		padding-left: 5px;
					 		&:first-child {
					 			padding-left: 0;
					 		}
					 		a {
					 			text-indent: -9999px;
					 			display: inline-block;
					 			width: 24px;
					 			height: 24px;
					 			margin-top: 0;
					 			background-size: 100%;
					 			background-position: center;
					 			background-repeat: no-repeat;
					 		}
					 		&.social-facebook {
					 			a {
					 				background-image: url('../../assets/images/icons/Symbol/reversed/facebook-white.svg');
					 				&:hover, &:focus {
							 				background-image: url('../../assets/images/icons/Symbol/orange/facebook-orange.svg');
							 			}



					 			}
					 		}
					 		&.social-youtube {
					 			a {
					 				background-image: url('../../assets/images/icons/Symbol/reversed/youtube-white.svg');
					 				&:hover, &:focus {
				 				background-image: url('../../assets/images/icons/Symbol/orange/youtube-orange.svg');
				 			}
					 			}
					 		}
					 		&.social-linkedin {
					 			a {
					 				background-image: url('../../assets/images/icons/Symbol/reversed/linkedin-white.svg');
					 				&:hover, &:focus {
				 				background-image: url('../../assets/images/icons/Symbol/orange/linkedin-orange.svg');
				 			}
					 			}
					 		}
					 		&.social-twitter {
					 			a {
					 				background-image: url('../../assets/images/icons/Symbol/reversed/twitter-white.svg');
					 				&:hover, &:focus {
				 				background-image: url('../../assets/images/icons/Symbol/orange/twitter-orange.svg');
				 			}
					 			}
					 		}
					 	}
				 	}
				}
				
				ul {
					
					li {
						margin-left: 0;
						width: 100%;
						float: none;
						@include heading(h5, $white);
					}

				}
				
			}
		}
	}


	nav.endfooter {
		margin-top: 100px;
		@include for(xs) {
			margin-top: 20px;
		}
		ul {
			display: flex;
			list-style-type: none;
			justify-content: center;
			flex-wrap: wrap;
			@include for(lg) {
				justify-content: flex-end;
				vertical-align: baseline;
			}
			
			li {
				margin: 8px 0 0;
				position: relative;
				@include for(xs) {
					text-align: center;
					margin: 4px 0 0;
				}
				&.first {
					width: 100%;
					flex-grow: 2;
					text-align: center;
					// margin: 0 auto 20px; 
					@include for(lg) {
						width: auto;
						text-align: left;
						margin-bottom: 0;
						margin-left: 0;
						align-self: flex-start;
						p {
							padding-left: 0;
						}
					}
					
					&:after {
						display: none;
					}
					+ li {
						a {
							padding-left: 0;
						}
					}
					@include for(xs) {
						margin: 0 auto 15px; 
						+ li {
							width: 100%;
							&:after {
								display: none;
							}
							+ li {
								a {
									padding-left: 0;
								}
							}	
						}
					}
				}
				&:after {
					content: "";
					height: 50%;
					width: 1px;
					background-color: $white;
					display: inline-block;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					right: 0;
					opacity: .5;
					@include for(xs) {
						height: 40%;
					}
				}
				

				&:last-child {
					a {
						padding-right: 0;
						@include for(xs) {
							padding-right: 10px;
						}
					}
					&:after {
						display: none;
					}
				}
				a {
					&:hover, &:focus {
						text-decoration: underline;
					}
					&:active {
						opacity: .4;
						text-decoration: underline;
					}
				}

				a, p {
					@include heading(p, $white);
					@include x-rem(font-size, 1.6);
					display: inline-block;
					margin: 0;
					line-height: 1;
					padding: 10px 20px;
					opacity: .5;
					@include for(xs sm) {
						@include x-rem(font-size, 1.2);
						padding: 10px 10px;
						margin-top: 0;
					}

				}
			}
		}
	}
}