.en-products-block {
  color: var(--color-white);

  &__inner {
    background-color: var(--color-secondary);
    padding: 12px 24px 24px;

    @include breakpoint($sm) {
      padding: 12px 32px 32px;
    }

    @include breakpoint($lg) {
      padding: 12px 40px 40px;
    }
  }

  h2 {
    margin-block: 1.5rem;
    color: #fff;
  }

  .en-columns {
    row-gap: 1.5rem;
  }

  h4 {
    margin: 0;
    font-size: 1.8rem;
    font-weight: 600;
    color: #fff;
  }

  p {
    margin: 4px 0 0;
    color: #fff;
    font-size: 1.6rem;
  }

  footer {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 0.5rem;
    background-color: transparent;

    @include breakpoint($sm) {
      margin-top: 40px;
    }

    @include breakpoint($lg) {
      margin-top: 48px;
    }

    a {
      display: flex;
      align-items: center;
      gap: 40px;
      color: var(--color-white);
      text-decoration: none;
      text-underline-offset: 4px;
      font-size: 26px;
      line-height: 1.2;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
