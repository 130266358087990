@mixin button-arrow($context: 'white', $style: 'keyline') {
    // @include button-arrow('keyline', 'orange', 'white');
    &:hover,
    &:focus {
        &:before {
            transform: none;
            opacity: 1;
        }
        padding: 16px 5px 16px 30px;
        @include for(xs) {
            padding: 12px 5px 12px 20px;
        }
    }
    @if $style=='primary' {
        // Primary on white
        @if $context=='white' {
            color: $white;
            background-color: $secondary;
            border: 2px solid $secondary;
            &:before {
                background-image: url('../../assets/images/arrow-white@2x.png');
            }
            &:hover,
            &:focus {
                border: 2px solid $secondary-dark;
            }
            &:active {
                color: $secondary-dark;
                &:before {
                    background-image: url('../../assets/images/arrow-orange@2x.png');
                    filter: brightness(.8);
                }
            }
            // Primary on color
        }
        @else {
            background-color: $white;
            border: 2px solid $white;
            &:hover,
            &:focus {
                border: 2px solid $white;
            }
            &:active {
                background-color: rgba($white, .5);
            }
            // Primary on orange
            @if $context=='orange' {
                color: $secondary;
                &:before {
                    background-image: url('../../assets/images/arrow-orange@2x.png');
                }
                &:hover,
                &:focus {
                    border: 2px solid $secondary-dark;
                }
                &:active {
                    color: $secondary-dark;
                    &:before {
                        filter: brightness(.8);
                    }
                }
                // Primary on blue
            }
            @else {
                color: $primary;
                &:before {
                    background-image: url('../../assets/images/arrow-blue@2x.png');
                }
                &:hover,
                &:focus {
                    border: 2px solid $primary-dark;
                }
                &:active {
                    color: $primary-dark;
                    &:before {
                        filter: brightness(.8);
                    }
                }
            }
        }
    }
    @else if $style=='primary-blue' {
        background-color: $primary;
        color: $white;
        border: 2px solid $primary;
        &:before {
            background-image: url('../../assets/images/arrow-white@2x.png');
        }
        &:hover,
        &:focus {
            border: 2px solid $primary-dark;
        }
        &:active {
            color: $primary-dark;
            &:before {
                background-image: url('../../assets/images/arrow-blue@2x.png');
                filter: brightness(.8);
            }
        }
    }
    @else {
        background-color: transparent;
        @if $context=='white' {
            color: $secondary;
            background-color: transparent;
            border: 2px solid $secondary;
            &:active {
                border: 2px solid $secondary-dark;
                color: $secondary-dark;
                background-color: rgba($secondary, .1);
                &:before {
                    filter: brightness(.8);
                }
            }
            &:before {
                background-image: url('../../assets/images/arrow-orange@2x.png');
            }
        }
        @else {
            color: $white;
            &:hover,
            &:focus {
                border: 2px solid $white;
            }
            &:before {
                background-image: url('../../assets/images/arrow-white@2x.png');
            }
            @if $context=='orange' {
                background-color: $secondary;
                &:hover,
                &:focus {
                    background-color: $secondary-shade;
                    border: 2px solid $white;
                }
                &:active {
                    &:before {
                        background-image: url('../../assets/images/arrow-orange@2x.png');
                        filter: brightness(.8);
                    }
                    background-color: $secondary-shade;
                    border: 2px solid $secondary-dark;
                    color: $secondary-dark;
                }
            }
            @else {
                background-color: $primary;
                &:hover,
                &:focus {
                    background-color: $primary-shade;
                    border: 2px solid $white;
                }
                &:active {
                    &:before {
                        background-image: url('../../assets/images/arrow-blue@2x.png');
                        filter: brightness(.8);
                    }
                    background-color: $primary-shade;
                    border: 2px solid $primary-dark;
                    color: $primary-dark;
                }
            }
        }
    }
    text-decoration: none;
    padding: 16px 20px 16px 20px;
    @include for(xs) {
        padding: 12px 10px 12px 10px;
    }
    position: relative;
    transition: all .2s ease-in-out;
    display: inline-block;
    text-align: center;
    span {
        margin-top: 0;
        @include for(xs) {
            @include x-rem(font-size,
            1.6);
        }
    }
    &:before {
        content: "";
        background-size: 100%;
        background-repeat: no-repeat;
        position: absolute;
        display: inline-block;
        width: 20px;
        height: 12px;
        top: 22px;
        left: 20px;
        transition: all .25s $customEase;
        transform: translateX(-90%);
        opacity: 0;
        @include for(xs) {
            top: 18px;
            left: 10px;
            transform: translateX(-80%);
        }
    }
}

@mixin link-arrow($color: $white,
$arrow: 'white') {
    position: relative;
    padding-left: 10px;
    margin-right: 14px;
    margin-left: 26px;
    margin-top: 10px;
    display: inline-block;
    transition: all .2s ease-in-out;
    line-height: 1;
    @include x-rem(font-size,
    2.2);
    span {
        @include heading(h5,
        $color);
        line-height: 1;
        @include for(xs) {
            @include x-rem(font-size,
            2);
        }
    }
    &:before {
        content: "";
        background-image: url('../../assets/images/arrow-#{$arrow}@2x.png');
        background-size: 100%;
        background-repeat: no-repeat;
        position: absolute;
        display: inline-block;
        width: 26px;
        height: 16px;
        top: 3px;
        left: -26px;
        color: $white;
    }
    &:hover,
    &:focus {
        text-decoration: none;
    }
    &:active {
        text-decoration: none;
    }
}