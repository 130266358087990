.home-page-slider{            
    background-color: #{$color-dark-grey};
    max-height: 70vh;

    .slick-slide{
        width: 100%;

        .banner__content {
            visibility: hidden;
        }
    }
    .slick-slide.slick-active .banner__content{
        visibility: visible;
    }
    .slick-slide.slick-current .banner__content{
        visibility: visible;
    }
    .item{
        background-size: cover;
        background-repeat: repeat-x;
        background-repeat:  repeat-y;
        background-position: center center; 
    }
    .special_heading{
        color: #{$color-white};
        font-weight: 400;
        font-size: 30px;
        line-height: 1;
        h1, h2, h3, h4, h5, h6, p, span, li{
            color: #{$color-white};
            font-weight: 400;
            font-size: 50px;
            line-height: 1;
        }
    }
    .special_sub_heading {
        color: $color-blue;   
        line-height: 1;
        font-size: 34px;
        font-weight: 500;
        h1, h2, h3, h4, h5, h6, p, span, li{
            color: #fff;   
            line-height: 1;
            font-size: 34px;
            font-weight: 500;
        }        
    }
    .slider-content-inner{
        height: 70vh;
        padding: 18px;
        top: 50%;
        background-size: cover;
        background-position: center center;

        .banner__content-inner{
            margin-top:10%;
            padding: 36px;
            margin-left: 0px;

            h2{
                margin-top: 0px;
            }
            p{
                padding-right: 0px;
            }
        }
        .banner__content-inner.show_desktop{
            position:absolute;
            top:0;           
        }
    }
    .btn{
        color: #fff;
        background-color: #FF7300;
        border: 2px solid #FF7300;
        padding: 16px 20px;
        position: relative;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        display: inline-block;
        text-align: center;
    }
    .slick-dots{
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
        bottom: 10%;

        li{
            position: relative;
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 5px;
            padding: 0;
            cursor: pointer;

            button{
                font-size: 0;
                line-height: 0;
                display: block;
                width: 15px;
                height: 15px;
                padding: 5px;
                cursor: pointer;
                color: transparent;
                border: 1px solid #ffffff;
                border-radius: 50%;
                outline: none;
                background: transparent;
            }        
        }
        li button:hover{
            outline: none;
        }
        li button:focus{
            outline: none;
        }
        li button:hover:before{
            opacity: 1;
        }
        li button:focus:before{
            opacity: 1;
        }
        li button:before{
            font-family: 'slick';
            font-size: 6px;
            line-height: 20px;
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            content: ' ';
            text-align: center;
            opacity: .25;
            color: black;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
        li.slick-active{
            button:before{
                opacity: .75;
                color: black;
            }
        }
    }
    .slick-active button{
        background: #ffffff !important;
    }
    .show_desktop{
        display: block;
    }
    .hide_desktop{
        display: none;
    }
}
 
@media screen and (max-width: $break-mobile){
    .home-page-slider{
        max-height: none;
        .hide_mobile{
            display:none;
        }
        .show_mobile{
            display: block;
        }
        .slider-content-inner{
            max-height: none;
            background-image: none !important;
            height:auto;

            .banner__content-inner{
                margin-top: -10%;
                background-color: transparent;

                p{
                    display:block;
                }
            }            
        }
        .slick-list{
            width: 100%;
            padding: 0px !important;
        }
        .banner__quicklinks{
            background-color: #3B3B3B;
            margin-top:0%;
        }
        .slick-dots{
            bottom: 0%;
        }
        .image_mobile{
            width: 100%;                
        }
        .slick-slide.slick-current .banner__content{
            visibility: visible;
        }
        .slick-slide .banner__content {
            visibility: hidden;
        }         
    }    
}
