@mixin fullWidthPanel($bg: $primary, $position: 'left') {
	position: relative;
	&:focus {
		outline: none;
	}

	.slide-bg {
		padding-top: 35vw;
		background-size: cover;
		background-position: center;
		position: relative;
		// min-height: 400px;
		@include for(xs) {
			padding-top: 60vw;
			background-image: none!important;
		}
		&:after {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-color: $text;
			opacity: 0;
			transition: opacity .2s ease-in-out;
			
		}
	}
	.slide-bg-xs {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-repeat: no-repeat;
		background-size: auto 100%;
		@include for(sm md lg) {
			display: none!important;
			background-image: none!important;
		}
	}

	// &:hover, &:focus {
	// 	.slide-bg:after {
	// 		opacity: .2;
	// 	}
	// }
	.block-content {
		bottom: 130px;
		position: relative;
		width: 100vw;
		z-index: 2;
		@include for(xs sm) {
			bottom: 40px;
		}
		>.container {
			height: 100%;
		}
		.content {
			background-color: $bg;
			padding: 40px 80px 40px 40px;
			display: flex;
			flex-direction: column;
			
			.button {
				margin-top: auto;
			}
			height: 100%;
			width: auto;

			position: relative;
			float: #{$position};
			@include for(xs) {
				padding: 5px 15px 15px;
				width: 100%;
			}
			@include for(md) {
				width: 65%;
			}
			@include for(sm) {
				width: 100%;
				margin-bottom: 40px;
			}
			h2 {
				@include heading(light, $white);
				margin-top: 0;
			}
			h3 {
				@include heading(h2, $white);
				margin-top: 10px;
				@include for(xs) {
					@include heading(h3, $white);
					margin-top: 10px;
				}
			}
			p {
				color: $white;
			}
			.button {
				@include link-arrow($white, 'white');
				@include x-rem(font-size, 2.2);
				margin-top: 20px;
				@include for(xs) {
					margin-top: 30px;
					@include x-rem(font-size, 2);
				}
			}
			&:hover, &:focus {
				.button {
					margin-left: 40px;
					margin-right: 0;
				}
			}
		}

	}

}

section.marketing-carousel {
	position: relative;
	&:after {
		content: "";
		position: absolute;
		width: 90vw;
		height: 70px;
		background-color: $white;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		@include for(xs) {
				display: none;
			}
	}
	.carousel-nav {
		position: absolute;
		top: calc(35vw - 30px);
		z-index: 5;
		right: 0;
		@include for(sm) {
			top: calc(35vw - 20px);
			right: 40px;
		}
		@include for(xs) {
			top: calc(60vw - 34px);
    		right: 30px;
		}
		.slick-dots {
			list-style-type: none;
			display: flex;
			width: 280px;
			@include for(xs sm) {
				width: 100px;
			}
			li {
				flex-grow: 1;
				margin-left: 10px;
				&:first-child {
					margin-left: 0;
				}
				button {
					text-indent: -9999px;
					width: 100%;
					height: 8px;
					background-color: $white;
					opacity: .5;
					border-radius: 0;
					border: none;
					transition: opacity .2s ease-in-out;
					&:hover, &:focus {
						opacity: .8;
					}
					@include for(xs) {
						height: 4px;
					}
				}
				&.slick-active {
					button {
						background-color: $secondary;
						opacity: 1;
						&:hover, &:focus {
							opacity: 1;
						}
					}
				}
			}
		}
	}
	&:nth-child(odd) {
		.slide {
			@include fullWidthPanel($text);
		}
	}
	&:nth-child(even) {
		.slide {
			@include fullWidthPanel($primary);
		}
	}
}