// Page specific style includes
form.search-results-form {
		padding: 40px;
		@include for(xs) {
			padding: 20px 0;
		}
		@include for(sm) {
			padding: 40px 0;
		}

		.search-wrapper {
			position: relative;
			width: 100%;

			.search-btn-wrapper {
				display: inline-block;
				@include for(xs) {
					width: 100%;
					text-align: center;
				}
				.search-submit {
					@include button-arrow('white', 'primary');
					min-width: 250px;
					@include for(xs) {
						min-width: 220px;
						width: 100%;
					}
				}
			}
		}
		input[type=search] {
			-webkit-appearance: none;
  			border-radius: 0;
			width: calc(100% - 280px);
			max-width: 500px;
			padding: 18px 20px 17px 20px;
			color: $text;
			border: 2px solid $text;
			display: inline-block;
			box-sizing:border-box;
			position: relative;
			top: -1px;
			margin-right: 20px;
			@include x-rem(font-size, 1.8);
			@include for(xs) {
				width: 100%;
				max-width: none;
				margin-right: 0;
			}
			&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			  color: rgba($text, .2);
			}
			&::-moz-placeholder { /* Firefox 19+ */
			  color: rgba($text, .2);
			}
			&:-ms-input-placeholder { /* IE 10+ */
			  color: rgba($text, .2);
			}
			&:-moz-placeholder { /* Firefox 18- */
			  color: rgba($text, .2);
			}
		}
		input[type=search]::-ms-clear {
		    display: none;
		}

	}
p.results-number, p.pages {
	
	@include for(md lg) {
		margin-left: 40px;
	}
}

ul.results-list {
	margin-bottom: 50px;
	@include for(xs) {
		margin-bottom: 20px;
	}
	li.result {
		padding: 30px 40px 25px;
		border-top: 2px solid $primary;
		overflow: hidden;
		margin-top: 0;
		@include for(sm) {
			padding: 30px 0px 25px;
		}
		@include for(xs) {
			padding: 18px 0px 12px;
		}
		&:last-child {
			border-bottom: 2px solid $primary;
		}
		a {
			display: inline-block;
			width: 100%;
			height: 100%;
			margin-top: 0;
			@include for(xs) {
				margin-top: 0;
			}
			&:hover, &:focus {
				h3 {
					color: $secondary;
					text-decoration: underline;
					transition: color .2s ease-in-out;
				}
				img {
					filter: brightness(.9);
				}
			}
		}
		img, .text {
			float: left;
			display: inline-block;
		}
		img {
			width: 150px;
			margin-right: 20px;
			transition: filter .2s ease-in-out;
			@include for(xs) {
				width: 60px;
				margin-right: 10px;
			}
		}
		.text {
			width: 100%;
			&.has-img {
				width: calc(100% - 170px);
			}
			h3 {
				@include heading(h4, $primary);
				margin-top: -3px;

				@include for(xs) {
					margin-top: -3px;
				}
			}
			h4 {
				@include heading(p, $text);
				@include x-rem(font-size, 1.4);
				margin-top: 0;
				
				@include for(xs) {
					margin-top: 0;
				}
			}
			@include for(xs) {
				&.has-img {
					width: calc(100% - 70px);
					.short-text {
						margin-left: -70px;
						width: calc(100% + 70px);
					}
				}
			}
		}
	}
}
p.no-results {
	margin-bottom: 100px;
	margin-left: 40px;
	@include for(xs sm) {
		margin-left: 0;
	}
	@include for(xs) {
		margin-bottom: 40px;
	}

}

.pagination {
	margin: 0 auto 100px;
	text-align: center;
	@include for(xs) {
		margin: 0 auto 40px;
	}
	p {
		display: inline-block;
		padding: 10px 5px 0;
		margin-top: 0;
		margin-left: 10px;
		@include for(xs) {
			display: none;
		}
	}
	a, span {
		padding: 5px 12px;
		background-color: $primary;
		border: 2px solid $primary;
		@include heading(p, $white);
		display: inline-block;
		margin-left: 10px;
		margin-top: 0;
		transition: background-color .2s ease-in-out, color .2s ease-in-out;
		@include for(xs sm) {
			padding: 3px 8px;
			margin-left: 5px;

		}
		@include for(xs) {
			&:first-child {
				margin-left: 0;
			}
			&.first-page , &.last-page {
				width: calc(100% - 80px);
				margin: 10px 40px 0;
			}
			&.first-page + a, &.first-page + p + a {
				margin-left: 0;
			}
			
		}
	}
	span {
		background-color: $white;
		@include heading(p, $primary);
	}
	a {
		&:hover, &:focus {
			background-color: $white;
			@include heading(p, $primary);
			border: 2px solid $primary-shade;
		}
		&:active {
			background-color: rgba($primary, .1);
			@include heading(p, $primary);
			border: 2px solid $primary-shade;

		}
	}
}
