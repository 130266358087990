/* White */
$color-white: #ffffff;

/* Black and Grey */
$color-dark-grey: #3b3b3b;


/* Break Points */
$break-mobile: 768px;

$color-blue: #006fba;

:root {
    --color-primary: #ed7b30;
    --color-secondary: #306eb4;
    --color-white: #fff;
    --color-bg: #f2f2f2;
    --color-bg-dark: #3b3b3b;
    --color-text: #707070;
  }
  

//spacing

.en-m-0 {
    margin: 0 !important;
  }
  
  .en-mt-0 {
    margin-top: 0 !important;
  }
  
  .en-p-0 {
    padding: 0 !important;
  }
  
  .en-pt-0 {
    padding-top: 0 !important;
  }
  
  //overflow

  .en-overflow-hidden {
    overflow: hidden;
  }
  
  .en-overflow-x-hidden {
    overflow-x: hidden;
  }
  
  .en-overflow-y-hidden {
    overflow-y: hidden;
  }
  
// contaainer


  


// Breakpoints
$xs: 400px;
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

@mixin breakpoint($min) {
    @media (min-width: $min) {
      @content;
    }
  }

  .en-container {
    width: 100%;
    max-width: 1280px;
    padding-inline: 1rem;
    margin-inline: auto;
  
    @include breakpoint($sm) {
      padding-inline: 1.5rem;
    }
  
    // @include breakpoint($lg) {
    //   padding-inline: 3rem;
    // }
  }