.case-study-full-width{
	background-color: #{$color-white} !important;
	.block-content{
		.content{
			p{
				max-width: 100% !important;
			}
		}
	}	
}

.header {
	background-color: #000;
}