.timeLine a.button{
color: #fff;
background-color: #ff7300;
border: 2px solid #ff7300;
text-decoration: none;
padding: 16px 20px;
position: relative;
-webkit-transition: all .2s ease-in-out;
transition: all .2s ease-in-out;
display: inline-block;
text-align: center;
min-width: 300px;
margin-top: 20px;
}
.timeLine header.timeLines{
background-size: cover;
background-position: center center;
display: flex;
align-items: center;
min-height: 450px;
}
.timeLine header.timeLines .block-content{
transform: none;
}
.timeLine header.timeLines .block-content h2{
color: #fff;
}
.timeLine header.timeLines:before{
content: "";
background: blue;
}
.timeline h4,
.timeline h3{
color: #006fba;
}
section.timeline{
padding: 2rem 0;
}
section.timeline .row{
display: flex;
}
section.timeline.contentRight .row .col-md-6:nth-child(1){
order: 2;
}
section.timeline.contentRight .row .col-md-6:nth-child(2){
order: 1;
padding-left: 0;
}	
section.timeline.anniversaryStory{
padding: 0;
}
section.timeline.anniversaryStory .row .col-md-6:nth-child(1){
padding: 0;
}
section.timeline.quote p{
font-size: 2.5rem;			
color: #707070;
margin-bottom: 1rem;
}
section.timeline.quote blockquote p:before{
content: '\201C';
font-size: 2.5rem;
color: #707070;

}
section.timeline.quote blockquote p:after{
content: '\201D';
font-size: 2.5rem;
color: #707070;
}
section.timeline.quote .author{
border-top: 1px solid #707070;
font-size: 1.5rem;
color: #707070;
padding-top: 1rem;
}
section.generalContent img{
width: 100%;
}
section.generalContent figure{
margin: 0;
}

@media only screen and (max-width: 992px) {
section.timeline{
        padding:0;
    }
    section.timeline .row .col-md-6{
        padding: 1rem;
    }
    section.timeline.contentRight .row .col-md-6:nth-child(1){
        order: 1;
    }
    section.timeline.contentRight .row .col-md-6:nth-child(2){
        order: 2;
        padding-left: 0;
    }	
}

.timeline_nav{
    background: #ff7300;      
    display: flex;
    flex-direction: row;
    justify-content: center; 
    gap: 2rem;
    margin-top: -2rem;
}
.timeline_nav li{
    padding: 0.5rem;
}
.timeline_nav li a{
    color: #fff;
}
.timeline_nav li.active{
    border-bottom: 2px solid #006fba;
}
@media only screen and (max-width: 992px) {
    .timeline_nav{
    gap: 0;
    }
}