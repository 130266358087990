body.microsite {

    section.store-finder-filter form .filter-by {
        display: none;
    }

    .homepage-modules section.cta-panel-big {
        .block-content .content {
            background-color: $text;

            &:before {
                background-color: $text;
            }
        }
    }

    .microsite-nav__nav-wrapper {
        &:before {
            background-color: $secondary;
        }


    }

    nav.navmain ul.utilities li {
        width: 100%;
        max-width: 100%;

        a {
            max-width: none;
        }
    }

    ul.utilities {
        background-color: $secondary;

        @include for(sm md) {
            max-width: 130px;
        }

        @include for(xs) {
            max-width: 70px;
        }

        li {
            @include for(xs) {
                width: calc(100% / 2);
            }

            a {
                background-color: $secondary;
                border-color: $secondary;

                &:hover,
                &:focus {
                    border-color: $primary;
                    background-color: $secondary-active;
                }

                @include for(lg) {
                    text-indent: 0;
                    padding-right: 60px;
                    text-align: right;
                }

                &:before {
                    right: 0;
                    left: auto;
                }
            }

            &.store-finder {
                a:before {
                    @include for(lg) {
                        width: 60px;
                    }
                }
            }
        }
    }

    nav.navmain ul.nav {
        background-color: $secondary;

        li {
            &:hover>a {
                border-color: $primary;
                background-color: $secondary-active;
            }

            >a {
                border-color: $secondary;

                &:hover,
                &:focus {
                    border-color: $primary;
                    background-color: $secondary-active;
                }
            }
        }

        ul.nav__level2 {
            background-color: $secondary-active;

            a:hover {
                background-color: $secondary;
            }
        }
    }

    header.home {
        @include for(xs) {
            top: 45px;
            background-color: $white;
            margin-bottom: 96px;
        }

        .slide-bg {
            padding-top: 800px;
            background-position: center;

            @include for(xs sm) {
                padding-top: 600px;
            }
        }

        aside.product-trailer {
            position: absolute;
            bottom: 0;
            width: 100vw;

            @include for(xs sm) {
                display: none;
            }

            .content {
                background-color: $text;
                padding: 30px 40px 165px 40px;
                min-width: 360px;
                float: right;
                position: relative;
                right: -100px;

                @include for(xlg) {
                    padding-bottom: 180px;
                }

                @include for(xs) {
                    background-color: $white;
                }

                @include for(sm) {
                    padding: 20px 80px 110px 30px;
                }

                ul {
                    list-style-type: none;
                }

                a {
                    margin-top: 5px;
                    display: inline-block;
                    position: relative;
                    padding-left: 30px;

                    @include for(md) {
                        width: 350px;
                    }

                    &:before {
                        content: "";
                        position: absolute;
                        display: inline-block;
                        left: -5px;
                        width: 24px;
                        height: 24px;
                        top: 50%;
                        transform: translateY(-50%);
                        background-size: 24px;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-image: url('../../assets/images/icons/Symbol/reversed/icon-play.svg');
                    }

                    @include heading(h5,
                    $white);

                    @include for(xs) {
                        @include heading(h5,
                        $text);
                    }

                    &:hover,
                    &:focus {
                        text-decoration: underline;
                    }

                    &:active {
                        opacity: .8;
                        text-decoration: underline;
                    }
                }
            }

            &.product-trailer-xs {
                display: none;

                @include for(xs) {
                    display: inline-block;
                }
            }
        }

        .header-content {
            position: absolute;
            top: 240px;
            width: 100%;

            @include for(md) {
                top: 200px;
            }

            @include for(sm) {
                top: 150px;
            }

            @include for(xs) {
                position: relative;
                top: -40px;
            }

            .content {
                width: 55%;
                padding-left: 40px;

                @include for(xlg) {
                    padding-left: 70px;
                }

                @include for(md) {
                    width: 55%;
                }

                @include for(sm) {
                    width: 100%;
                    padding-right: 40px;
                }

                @include for(xs) {
                    padding: 20px 20px;
                    background-color: $grey;
                    width: 100%;
                }
            }

            h1 {
                color: $white;
                @include x-rem(font-size,
                5.5);
                line-height: 1;
                margin-top: 0;

                @include for(xs) {
                    @include x-rem(font-size,
                    3);
                    color: $text;
                }
            }

            p {
                color: $white;
                @include x-rem(font-size,
                2.2);

                @include for(xs) {
                    display: none;
                }

                a {
                    color: $white;
                    font-size: inherit;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .button {
                @include button-arrow('white',
                'primary');
                min-width: 280px;
                margin-top: 15px;
                display: none;

                @include for(xs) {
                    display: inline-block;
                }
            }
        }

        form.store-finder-form {
            margin-top: 50px;

            @include for(xs) {
                display: none;
            }
        }

        .search-wrapper {
            position: relative;
            width: 100%;
            margin-top: 10px;

            .search-btn-wrapper {
                position: absolute;
                top: 0px;
                right: 0px;
                text-align: right;
            }

            .search-submit {
                @include button-arrow('white',
                'primary');
                @include x-rem(font-size,
                1.8);
                min-width: 190px;
                margin-top: 0;
                border-radius: 0;
                line-height: 1.4;
                font-family: $headingfont;
            }
        }

        input[type=search] {
            width: calc(100% - 200px);
            padding: 20px 20px 18px 20px;
            color: $text;
            border: none;
            display: inline-block;
            box-sizing: border-box;
            -webkit-appearance: none;
            border-radius: 0;
            @include x-rem(font-size,
            1.8);

            &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                color: rgba($text, .2);
            }

            &::-moz-placeholder {
                /* Firefox 19+ */
                color: rgba($text, .2);
            }

            &:-ms-input-placeholder {
                /* IE 10+ */
                color: rgba($text, .2);
            }

            &:-moz-placeholder {
                /* Firefox 18- */
                color: rgba($text, .2);
            }

            &:focus {
                outline: none;
            }
        }

        .checkbox {
            display: none;
        }

        .block-content {
            transform: translateY(-85%);

            @include for(xs sm) {
                transform: none;
            }

            @include for(xs) {
                margin-bottom: 0;
            }

            .content {
                width: 100%;

                @include for(xs) {
                    padding: 0;
                }

                @include for(sm) {
                    padding: 40px;
                }

                @include for(xlg) {
                    padding: 55px 70px 0px;
                }

                h3 {
                    @include heading(p, $text);
                    @include x-rem(font-size,
                    2);
                    margin-top: 0;

                    @include for(xs) {
                        margin-top: 0;
                        @include x-rem(font-size,
                        2);
                    }
                }

                ul {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    width: 80%;

                    @include for(md) {
                        width: 90%;
                    }

                    @include for(xs) {
                        margin: 0;
                    }

                    li {
                        flex-shrink: 1;
                        width: calc(100% / 3);

                        @include for(xs sm) {
                            width: 100%;
                        }

                        a {
                            @include link-arrow($secondary,
                            'orange');
                            left: 0;

                            @include for(xs) {
                                margin-top: 0;

                                span {
                                    @include x-rem(font-size,
                                    1.8);
                                }
                            }

                            &:hover,
                            &:focus {
                                span {
                                    border-bottom: 1px solid $secondary;
                                }
                            }

                            &:active {

                                @include link-arrow($secondary-dark,
                                'orange');

                                &:before {
                                    filter: brightness(.8);
                                }

                                span {
                                    border-bottom: 1px solid $secondary-dark;
                                }
                            }
                        }

                        &:last-child a {

                            @include link-arrow($text,
                            'dark');

                            @include for(xs) {
                                margin-top: 0;

                                span {
                                    @include x-rem(font-size,
                                    1.8);
                                }
                            }

                            &:hover,
                            &:focus {
                                span {
                                    border-bottom: 1px solid $text;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    section {

        // 
        &.tngg-steps {
            @include for(md lg) {
                margin-top: -40px;
            }

            .content {
                padding: 0 40px;

                @include for(xs) {
                    padding: 30px 0 0;
                    border-top: 1px solid $grey;
                }


                h2 {
                    @include x-rem(font-size,
                    5.5);
                    margin-top: 0;
                    margin-bottom: 15px;

                    @include for(xs) {
                        @include x-rem(font-size,
                        3);
                    }
                }
            }

            .item-carousel {
                display: flex;
                margin: 10px -10px 20px;

                @include for(xs sm) {
                    flex-direction: column;
                    margin: 10px -10px 0px;
                }
            }

            .content-column {
                margin: 10px;
                padding: 30px;
                background-color: $grey;

                @include for(xs sm) {
                    margin: 5px 10px;
                }

                @include for(xs) {
                    padding: 15px;
                }

                .step__icon {
                    position: relative;
                    padding-left: 45px;
                    @include x-rem(font-size,
                    2.5);
                    margin-top: 0;
                    font-weight: 400;
                    letter-spacing: -.2px;

                    &:before {
                        content: "";
                        position: absolute;
                        display: inline-block;
                        top: 24px;
                        transform: translateY(-50%);
                        width: 40px;
                        height: 48px;
                        left: -2px;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }

                    span {
                        @include heading(light, $text);
                        display: block;
                        color: $secondary;
                        margin-bottom: 5px;
                        opacity: 1;
                        margin-top: 0;
                        font-weight: 500;

                        @include for(xs) {
                            margin-top: 0;
                        }
                    }

                    &--purchase:before {
                        background-image: url('../../assets/images/icons/Symbol/orange/icon-purchase.svg');
                    }

                    &--swap:before {
                        background-image: url('../../assets/images/icons/Symbol/orange/icon-swap.svg');
                    }

                    &--return:before {
                        background-image: url('../../assets/images/icons/Symbol/orange/icon-return.svg');
                    }

                }

                p {
                    margin-top: 15px;
                }



            }

            a.button {
                @include button-arrow('white',
                'primary');
                min-width: 280px;
                @include for(xs) {
                    min-width: 240px;
                }
            }

            .button-container {
                @include for(xs) {
                    display: none;
                }
            }
        }

        &.why-choose-us {
            @include fullWidthPanel($secondary);

            .slide-bg-xs {
                width: 100%;
                height: auto;
                padding-top: 60vw;
            }

            .block-content {
                bottom: 100px;
                width: 100%;

                @include for(xs) {
                    bottom: 0;

                    >.container {
                        padding: 0;
                    }
                }

                .content {
                    width: 100%;
                    padding: 40px;

                    @include for(xlg) {
                        padding: 50px 50px;
                    }

                    @include for(xs) {
                        padding: 15px 15px 40px;
                        background-color: $grey;
                    }

                    @include for(md) {
                        width: 100%;
                    }

                    h2 {
                        @include heading(h2,
                        $white);
                        @include x-rem(font-size,
                        5.5);
                        margin-top: 0;
                        opacity: 1;

                        @include for(xs) {
                            color: $text;
                            @include x-rem(font-size,
                            3);
                        }
                    }

                    .find-store {
                        display: none;

                        @include for(xs) {
                            display: inline-block;
                            @include button-arrow('white',
                            'keyline');
                            margin-top: 10px;
                            width: 290px;
                        }
                    }
                }
            }

            .columns {
                display: flex;
                margin: 20px 0px 0;
                width: 100%;
                justify-content: space-between;
                flex-wrap: wrap;

                @include for(xs) {
                    flex-direction: column;
                    margin: 10px 0px 0;
                }

                .content-column {
                    width: calc(50% - 20px);
                    margin: 10px 20px;

                    @include for(xs) {
                        width: 100%;
                    }

                    h2 {
                        display: none;
                    }

                    h4 {
                        @include heading(h5,
                        $white);
                        font-weight: 500;

                        @include for(xs) {
                            color: $text;
                        }
                    }

                    p {
                        @include for(md lg) {
                            width: 90%;
                        }

                        @include for(xs) {
                            color: $text;
                        }

                        a {
                            color: $white;
                            text-decoration: underline;

                            @include for(xs) {
                                color: $text;
                            }
                        }
                    }

                    @include for(lg) {
                        &:first-child {
                            margin-left: 0;
                        }

                        &:last-child {
                            margin-right: 0;
                        }
                    }

                    &:nth-child(odd) {
                        margin-left: 0;
                    }

                    &:nth-child(even) {
                        margin-right: 0;
                    }

                    @include for(xs) {
                        margin: 0 0 15px;
                    }
                }
            }

            form {
                padding: 40px;
                display: inline-block;
                width: 100%;
                background-color: $grey;
                margin-bottom: 120px;
                margin-top: 40px;

                h3 {
                    @include x-rem(font-size,
                    3);
                    margin-bottom: 20px;
                    margin-top: 0;
                }

                @include for(xlg) {
                    padding: 50px 50px;
                }

                @include for(sm) {
                    margin-bottom: 80px;
                    margin-top: 0px;
                }

                @include for(xs) {
                    display: none;
                }
            }

            .search-wrapper {
                position: relative;
                width: 100%;
                margin-top: 10px;
                height: 60px;

                .search-btn-wrapper {
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    text-align: right;
                }

                .search-submit {
                    @include button-arrow('white',
                    'primary');
                    @include x-rem(font-size,
                    1.8);
                    min-width: 190px;
                    margin-top: 0;
                    border-radius: 0;
                    line-height: 1.4;
                    font-family: $headingfont;
                }
            }

            input[type=search] {
                width: calc(100% - 200px);
                padding: 19px 20px 16px 20px;
                color: $text;
                border: none;
                display: inline-block;
                box-sizing: border-box;
                border: 2px solid $secondary;
                -webkit-appearance: none;
                border-radius: 0;
                @include x-rem(font-size,
                1.8);

                &::-webkit-input-placeholder {
                    /* Chrome/Opera/Safari */
                    color: rgba($text, .2);
                }

                &::-moz-placeholder {
                    /* Firefox 19+ */
                    color: rgba($text, .2);
                }

                &:-ms-input-placeholder {
                    /* IE 10+ */
                    color: rgba($text, .2);
                }

                &:-moz-placeholder {
                    /* Firefox 18- */
                    color: rgba($text, .2);
                }

                &:focus {
                    outline: none;
                    border: 2px solid $secondary-active;
                }
            }

            .checkbox {
                display: none;
            }
        }

        &.product-carousel {
            a.view-all {
                float: right;
                @include link-arrow($text,
                'dark');
                margin-bottom: 15px;
                margin-top: -15px;
                margin-right: 0;

                &:hover,
                &:focus {
                    span {
                        border-bottom: 1px solid $text;
                    }
                }

                @include for(xs) {
                    display: none;
                }
            }

            .carousel-wrapper {
                position: relative;
                clear: both;
            }

            .content {
                padding: 1px 40px;

                @include for(xlg) {
                    padding: 1px 50px;
                }

                @include for(xs) {
                    padding: 0;
                }

                h2 {
                    @include x-rem(font-size,
                    5.5);

                    @include for(xs) {
                        @include x-rem(font-size,
                        3);
                        margin-top: 0;
                    }
                }

                .item-carousel {
                    margin: 20px -10px;

                    .slick-track {
                        display: flex;

                        .slick-slide {
                            display: flex;
                            height: auto;
                        }
                    }

                    .item {
                        border: 2px solid $secondary;
                        margin: 0 10px;
                        flex-direction: column;
                        height: 100%;

                        @include for(xs) {
                            margin: 0 5px;
                        }

                        h3 {
                            @include x-rem(font-size,
                            3);
                            font-weight: 400;
                            margin-top: 0;
                            margin-bottom: 20px;

                            @include for(xs) {
                                @include x-rem(font-size,
                                2);
                            }
                        }

                        h4 {
                            @include heading(light, $text);
                            color: $secondary;
                            opacity: 1;
                        }

                        p.link {
                            @include link-arrow($text,
                            'dark');
                            margin-top: auto;
                            margin-bottom: 0;
                            position: absolute;
                            bottom: 40px;

                            @include for(xs md) {
                                bottom: 20px;
                            }

                            @include for(xs) {
                                padding-left: 0px;
                                margin-left: 20px;

                                span {
                                    @include x-rem(font-size,
                                    1.6);
                                }

                                &:before {
                                    width: 13px;
                                    top: 8px;
                                    left: -20px;
                                }
                            }
                        }

                        a {
                            margin-top: 0;
                            padding: 40px 40px 80px;
                            position: relative;
                            display: inline-block;
                            height: 100%;
                            transition: all .2s ease-in-out;

                            @include for(xs md) {
                                padding: 20px 25px 60px;
                            }

                            &:hover,
                            &:focus {
                                background-color: $secondary;

                                h3,
                                h4,
                                p,
                                ul li {
                                    color: $white;
                                }

                                p.link {
                                    &:before {
                                        background-image: url('../../assets/images/arrow-white@2x.png');
                                    }

                                    span {
                                        color: $white;
                                        border-bottom: 1px solid $white;
                                    }
                                }
                            }
                        }

                        .uses {
                            p {
                                display: none;
                            }

                            ul {
                                margin-top: 10px;

                                li {
                                    padding-left: 15px;
                                    position: relative;
                                    margin-bottom: 8px;

                                    &:before {
                                        content: '· ';
                                        position: absolute;
                                        left: 0;
                                    }

                                    &:nth-of-type(1n+4) {
                                        display: none;
                                    }

                                    ul {
                                        margin-left: 10px;
                                    }

                                    @include for(xs) {
                                        @include x-rem(font-size,
                                        1.6);
                                    }
                                }
                            }
                        }

                        .bottle-sizes {
                            margin-top: 40px;

                            @include for(xs) {
                                margin-top: 20px;
                            }

                            ul {
                                margin: 10px 0;

                                li {
                                    color: $secondary;
                                    line-height: 2;

                                    @include for(xs) {
                                        @include x-rem(font-size,
                                        1.6);
                                        line-height: 1.6;
                                    }
                                }
                            }

                            ul li:nth-of-type(1n+4) {
                                display: none;
                            }
                        }
                    }
                }
            }

            .carousel-arrows {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -40px;
                width: calc(100% + 80px);
                height: auto;
                pointer-events: none;

                @include for(xs) {
                    width: calc(100% + 10px);
                    left: -5px;
                }

                .slick-arrow {
                    position: relative;
                    background-color: transparent;
                    padding: 30px 12px;
                    border: none;
                    text-indent: -9999px;
                    min-width: 40px;
                    opacity: 1;
                    transition: opacity .2s ease;
                    pointer-events: auto;

                    &:after {
                        content: " ";
                        width: 25px;
                        height: 15px;
                        background-image: url('../../assets/images/icons/Symbol/grey/chevron-down-grey.svg');
                        background-size: 100% auto;
                        background-position: right;
                        background-repeat: no-repeat;
                        position: absolute;
                        display: inline-block;
                        top: 50%;
                        margin-top: 2px;
                        color: $white;

                        @include for(xs) {
                            border-radius: 50%;
                            background-color: $text;
                            width: 20px;
                            height: 20px;
                            background-image: url('../../assets/images/icons/Symbol/reversed/chevron-down-white.svg');
                            background-size: 14px 9px;
                            background-position: center;
                        }
                    }

                    &:hover,
                    &:focus {
                        &:after {
                            filter: contrast(2);
                        }

                        outline: none;
                    }

                    &:active {
                        opacity: .5;
                        outline: none;
                    }

                    &.slick-disabled {
                        opacity: 0;
                        pointer-events: none;
                    }

                    &.slick-prev {
                        float: left;
                        transform: translateX(-25%);

                        &:after {
                            left: 50%;
                            transform: translateY(-50%) translateX(-50%) rotate(90deg);
                        }
                    }

                    &.slick-next {
                        float: right;
                        transform: translateX(25%);

                        &:after {
                            right: 50%;
                            transform: translateY(-50%) translateX(50%) rotate(-90deg);
                        }
                    }
                }
            }

            .carousel-nav {
                position: absolute;
                bottom: -50px;
                z-index: 5;

                @include for(xs) {
                    width: 100%;
                    text-align: center;
                }

                .slick-dots {
                    list-style-type: none;
                    display: flex;
                    width: 120px;

                    @include for(xs) {
                        width: 100%;
                        justify-content: center;
                        padding: 0 40px;
                    }

                    li {
                        flex-grow: 1;
                        margin-left: 4px;

                        &:first-child {
                            margin-left: 0;
                        }

                        button {
                            text-indent: -9999px;
                            width: 100%;
                            height: 6px;
                            background-color: $text;
                            opacity: .5;
                            border-radius: 0;
                            border: none;
                            transition: opacity .2s ease-in-out;

                            &:hover,
                            &:focus {
                                opacity: .8;
                            }

                            @include for(xs) {
                                @include x-rem(font-size,
                                2);
                            }
                        }

                        &.slick-active {
                            button {
                                background-color: $secondary;
                                opacity: 1;

                                &:hover,
                                &:focus {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.contact-panel {
            .content {
                display: flex;
                background-color: $grey;
                padding: 40px;

                @include for(xlg) {
                    padding: 55px 50px;
                }

                @include for(sm) {
                    flex-direction: column;
                    padding: 30px 40px;
                }

                @include for(xs) {
                    flex-direction: column;
                    padding: 10px 0;
                }
            }

            .content-column {
                min-width: 33%;
                margin: 0 10px;

                @include for(xs) {
                    margin: 0 15px 15px;
                }

                @include for(sm) {
                    margin: 15px 0;
                }

                @include for(md lg) {
                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-left: 0;
                    }
                }

                .small {
                    display: none;
                }

                h3 {
                    @include heading(light, $text);
                    opacity: 1;
                    margin-top: 0;
                    margin-bottom: 20px;

                    @include for(xs) {
                        margin-bottom: 15px;
                    }
                }

                p {
                    @include x-rem(font-size,
                    1.8);
                    font-weight: 300;

                    strong {
                        font-weight: 500;
                    }

                    @include for(xs) {
                        @include x-rem(font-size,
                        1.6);
                    }
                }

                a {
                    color: $primary;
                    text-decoration: underline;

                    &:hover,
                    &:focus {
                        color: $primary-hover;
                    }

                    &:active {
                        color: $primary-active;
                    }
                }
            }
        }
    }

    section+footer {
        margin-top: 120px;

        @include for(sm) {
            margin-top: 80px;
        }

        @include for(xs) {
            margin-top: 40px;
        }
    }

    header.productMarketing,
    header.productListing,
    header.storeFinder,
    header.generalContent {
        background-color: $text;
    }
}