.video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    height: 0;
    background-color: rgba($text, .5);
    &:hover,
    &:focus {
        .preview:before {
            background-color: rgba(black, .2);
        }
    }
    .play-button {
        display: none;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: 15% auto;
        background-position: center 50%;
        background-repeat: no-repeat;
        z-index: 10;
        transition: filter .2s ease-in-out;
        margin-top: 0;
        &:focus {
            filter: brightness(.8);
            outline: none;
        }
        @include for(md) {
            background-size: 20%;
        }
        @include for(sm) {
            background-size: 15%;
        }
        @include for(xs) {
            background-size: 15%;
        }
        background-image: url('../../assets/images/icons/Symbol/reversed/icon-play.svg');
    }
    .inline-video {
        opacity: 1;
        transition: opacity .3s .5s ease;
    }
    .preview {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        &:before {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background-color: (black, 0);
            transition: background-color .3s ease-in-out;
            content: "";
        }
    }
    &.vid-init {
        .inline-video {
            opacity: 0;
        }
        .preview,
        .play-button {
            display: inline-block;
        }
    }
    @include for(xs) {
        margin-top: 20px;
        min-height: 180px;
    }
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
        transition: filter .2s ease-in-out;
    }
    p.caption {
        position: absolute;
        background-color: $white;
        width: auto;
        display: inline;
        padding: 8px 15px 8px 10px;
        bottom: 0;
        left: 0;
        @include for(xs) {
            bottom: 0;
            background-color: $white;
            @include x-rem(font-size,
            1.4);
        }
    }
}

.video-column {
	background-color: $grey;
}