.en-hero {
  &__outer {
    padding-bottom: 40px;
  }

  background-color: var(--color-bg);

  .en-columns {
    align-items: center;
  }

  &__content {
    padding-block: 32px;

    @include breakpoint($sm) {
      padding-block: 48px;
    }

    @include breakpoint($lg) {
      padding-block: 64px;
    }

    p {
      font-size: 22px;
      font-weight: 500;
    }
  }

  &__image {
    height: calc(100% + 48px);
    margin-bottom: -48px;

    img {
      object-fit: cover;
      min-width: 100%;
      height: 100%;
      max-width: 100%;

      @include breakpoint($lg) {
        max-width: 150%;
      }
    }
  }
}
