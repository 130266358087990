// General Page specific style includes
.content-modules {
    padding-top: 80px;

    @include for(xs) {
        padding-top: 30px;
    }

    @include for(sm) {
        padding-top: 50px;
    }

    section+section {
        margin-top: 80px;

        @include for(xs) {
            margin-top: 30px;
        }

        @include for(sm) {
            margin-top: 50px;
        }

        &.case-study {
            margin-top: 160px;
            
            @include for(xs) {
                margin-top: 40px;
            }
        }
    }

    section {
        &.grey {
            background-color: $grey;
            padding: 85px 0 100px;

            @include for(xs) {
                padding: 30px 0 50px;
            }

            @include for(sm) {
                margin-top: 60px;
                padding: 40px 0 60px;
            }

            .wysiwyg {
                @media only screen and (min-width:1200px) {
                    width:80%;
                    padding:0 40px 40px;
                }
                @media only screen and (min-width:992px) and (max-width:1199px) {
                    padding:0 40px 40px;
                }
                
                // @include for(md lg) {
                //     width:80%;
                //     padding:0 40px 40px;
                // }
            }
        }

        &.basic-content:last-child {
            padding-bottom: 80px;

            @include for(sm) {
                padding-bottom: 50px;
            }

            @include for(xs) {
                padding-bottom: 40px;
            }
        }

        &.basic-content+.preset-buttons {
            margin-top: 0;
        }

        &.cta-panel-big:last-child {
            margin-bottom: 80px;

            @include for(sm) {
                margin-bottom: 50px;
            }

            @include for(xs) {
                margin-bottom: 40px;
            }
        }

        @include for(xs) {
            display: inline-block;
            width: 100%;
        }

        .wysiwyg {
            @include for(lg) {
                width: 80%;
            }

            @include for(md lg) {
                padding: 0px 40px 40px;
            }

            h3 {
                @include heading(h4, $primary);
            }

            h4 {
                @include heading(h5, $text);
            }

            h5 {
                @include heading(p, $text);
                font-weight: 600;
            }

            h2,
            h3,
            h4,
            h5 {
                margin-top: 30px;
            }

            p,
            li {
                a {
                    color: $secondary;
                    text-decoration: underline;

                    &:hover,
                    &:focus {
                        color: $secondary-hover;
                    }

                    &:active {
                        color: $secondary-active;
                    }
                }
            }

            ul {
                li {
                    padding-left: 15px;
                    position: relative;
                    margin-bottom: 8px;

                    &:before {
                        content: '· ';
                        position: absolute;
                        left: 0;
                    }

                    ul {
                        margin-left: 10px;
                    }
                }
            }

            ol {
                li {
                    margin-bottom: 8px;

                    ol {
                        margin-left: 10px;
                    }
                }
            }

            >ul,
            >ol {
                display: table;
                display: flow-root;
            }

            .button {
                @include button-arrow('white', 'primary');
                min-width: 300px;

                +br+.button {
                    @include button-arrow('white', 'keyline');
                }

                @include for(xs) {
                    min-width: 0;
                    width: 100%;
                    max-width: 300px;

                    span {
                        @include x-rem(font-size, 1.8);
                    }
                }
            }

            iframe {
                border: 2px solid $primary;
                padding: 20px;
                max-width: 100%;

                @include for(xs) {
                    padding: 15px;
                }
            }

            figure {
                margin: 0;

                figcaption {
                    @include heading(p, $text);
                    padding: 10px 0;
                }

                img {
                    max-width: 100%;
                }

                &.c-figure--left {
                    float: left;
                    margin: 20px 40px 20px 0;
                    text-align: left;

                    @include for(xs) {
                        float: none;
                        text-align: center;
                        margin-right: 0;
                    }

                    @include for(sm) {
                        float: none;
                        text-align: center;
                        margin-right: 0;
                    }
                }

                &.c-figure--right {
                    float: right;
                    margin: 20px 0px 20px 40px;
                    text-align: right;

                    @include for(xs) {
                        float: none;
                        text-align: center;
                        margin-left: 0;
                    }

                    @include for(sm) {
                        float: none;
                        text-align: center;
                        margin-left: 0;
                    }
                }

                &.c-figure--full {
                    margin: 20px 0px 20px 0px;
                    text-align: center;
                }
            }
        }

        .accordion-module {
            >h4 {
                margin-bottom: 20px;
            }

            ul.accordion {
                margin-top: 0;

                @include for(xs sm) {
                    margin-top: 20px;
                }

                >li {
                    border-top: 2px solid $primary;
                    margin-top: 0;

                    &:last-child {
                        border-bottom: 2px solid $primary;
                    }

                    a.heading {
                        @include heading(h5, $text);
                        padding: 20px 80px 20px 40px;
                        display: inline-block;
                        margin-top: 0;
                        position: relative;
                        width: 100%;

                        @include for(sm) {
                            padding: 20px 50px 20px 20px;
                        }

                        @include for(xs) {
                            margin-top: 0;
                            padding: 20px 50px 20px 0px;
                        }

                        &:after {
                            content: "";
                            position: absolute;
                            top: 50%;
                            right: 40px;
                            transform: translateY(-50%) rotate(45deg);
                            width: 22px;
                            height: 22px;
                            background-image: url('../../assets/images/icons/Symbol/blue/accordian-open-blue.svg');
                            background-size: 100%;
                            display: inline-block;
                            pointer-events: none;
                            transition: transform .3s $customEase;

                            @include for(sm) {
                                right: 20px;
                            }

                            @include for(xs) {
                                right: 10px;
                            }
                        }
                    }

                    .show-content {
                        display: none;
                        padding: 0px 40px 30px;

                        @include for(xs) {
                            padding: 0px 0px 20px;
                        }

                        @include for(sm) {
                            padding: 0px 20px 30px;
                        }

                        @include for(lg) {
                            width: 80%;
                        }
                    }

                    p,
                    li {
                        a {
                            color: $secondary;
                            text-decoration: underline;

                            &:hover,
                            &:focus {
                                color: $secondary-hover;
                            }

                            &:active {
                                color: $secondary-active;
                            }
                        }
                    }

                    ul {
                        li {
                            padding-left: 15px;
                            position: relative;
                            margin-bottom: 8px;

                            &:before {
                                content: '· ';
                                position: absolute;
                                left: 0;
                            }

                            ul {
                                margin-left: 10px;
                            }
                        }
                    }

                    ol {
                        li {
                            margin-bottom: 8px;

                            ol {
                                margin-left: 10px;
                            }
                        }
                    }

                    &.open {
                        a.heading:after {
                            transform: translateY(-50%) rotate(0deg);
                        }
                    }
                }
            }
        }

        p.scroll {
            display: none;

            @include for(xs) {
                display: inline-block;
                position: absolute;
                top: -40px;
                right: 25px;
                @include heading(light,
                $primary);

                &:after {
                    content: '';
                    width: 15px;
                    height: 15px;
                    position: absolute;
                    top: 2px;
                    right: -22px;
                    background-image: url('../../assets/images/icons/Symbol/blue/arrow-right-blue.svg');
                    background-size: 100%;
                    background-repeat: no-repeat;
                    background-position: right center;
                }
            }
        }

        .responsive-table {
            position: relative;
            width: 100%;
        }

        .table {
            display: block;
            margin-top: 10px;

            @include for(md lg) {
                margin-top: -20px;
            }

            @include for(xs) {
                overflow-x: scroll;
                -webkit-overflow-scrolling: touch;
                margin-right: -15px;
                overflow-y: auto;
            }

            table {
                background-color: $white;
                width: 100%;
                margin-top: 0;

                @include for(xs) {
                    min-width: 700px;
                    border-right: 15px solid $grey;
                }

                thead {
                    background-color: $primary;

                    th {
                        padding: 20px 40px;

                        @include for(xs) {
                            padding: 10px 20px;
                        }

                        @include heading(light,
                        $white);
                        opacity: 1;
                        border-left: 2px solid $grey;

                        &:first-child {
                            border-left: none;
                        }
                    }
                }

                tbody {
                    tr {
                        border-top: 2px solid $grey;

                        td {
                            padding: 20px 40px;

                            @include for(xs) {
                                padding: 10px 20px;
                            }

                            border-left: 2px solid $grey;

                            &:first-child {
                                border-left: none;
                                @include heading(p,
                                $primary);
                                text-transform: uppercase;
                                @include x-rem(font-size,
                                1.4);
                            }
                        }
                    }
                }
            }
        }

        .inline-navigation {
            margin-top: 10px;

            @include for(md lg) {
                margin-top: -20px;
            }

            ul.list-links {
                margin-top: 0;
                border-top: 2px solid $primary;
                border-bottom: 2px solid $primary;
                padding-top: 10px;
                padding-bottom: 10px;
                padding: 20px 40px;

                @include for(xs) {
                    padding: 10px 15px;
                }

                li {
                    margin-top: 4px;

                    a {
                        padding: 4px 0 4px 12px;
                        display: inline-block;
                        position: relative;
                        margin-top: 0;

                        &:before {
                            content: "";
                            position: absolute;
                            top: 12px;
                            left: 0;
                            width: 6px;
                            height: 9px;
                            background-image: url('../../assets/images/chevron-text@2x.png');
                            background-repeat: no-repeat;
                            background-size: 100%;
                            display: inline-block;

                            @include for(xs) {
                                top: 10px;
                            }
                        }

                        &:hover,
                        &:focus {
                            color: $secondary;
                        }

                        &:active {
                            color: $secondary-hover;
                        }
                    }

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }

        .form__title {
            position: relative;
            
            .hidden-anchor {
                position: absolute;
                top: -120px;
                left: 0;
            }   
            @media only screen and (min-width: 992px) {
                padding: 0 40px;
                margin-bottom: 40px;
            }
            
            h3 {
                color: $primary;
            }
        }
        div:has(.contact-form){
            .form__title {
                padding: 0 !important;
            }
        }
        form {
            padding-top: 40px;

            @include for(md lg) {
                padding: 0 40px;
            }

            .field {
                margin-top: 30px;

                &:first-of-type {
                    margin-top: 0;
                }
            }

            label:not(.error) {
                @include heading(light,
                $primary);

            }
            label.error {
                border: 1px solid $secondary;
                background-color: rgba($secondary, .1);
                color: $text;
                padding: 8px 12px;
                margin-top: 5px;
                display: block;
                width: 100%;
                font-size: 1.2rem;
                @media only screen and (min-width: 768px) {
                    max-width: 500px;
                }
            }

            .field.checkbox,
            .field.radio {
                .input-container {
                    label {
                        @include heading(p,
                        $text);
                        margin-right: 15px;
                        vertical-align: middle;
                        display: inline-block;
                        opacity: 1;
                        letter-spacing: 0;

                        span {
                            position: relative;
                            top: 1px;
                        }

                        &:last-child {
                            margin-right: 0px;
                        }

                        @include for(sm) {
                            width: 100%;
                            margin-right: 0;
                        }

                        @include for(xs) {
                            width: 100%;
                            margin-right: 0;
                        }
                    }

                    input {
                        margin-left: 15px;
                    }
                }
            }

            .field.instructions {
                p {
                    @include x-rem(font-size,
                    1.4);
                    max-width: 500px;

                    @include for(xs) {
                        max-width: 100%;
                        width: 100%;
                    }
                }
            }

            input,
            textarea,
            select {
                -webkit-appearance: none;
                border-radius: 0;
                padding: 8px 15px;
                background-color: $grey;
                border: 1px solid $grey;
                border-radius: 0;
                @include heading(p,
                $text);
                min-width: 500px;

                @include for(xs) {
                    min-width: 0;
                    width: 100%;
                    max-width: 500px;
                }

                &:focus {
                    border: 1px solid $primary;
                    outline: none;
                }

                &#catchme {
                    display: none !important;
                }
            }

            a.button,
            button.button {
                @include button-arrow('white',
                'primary');
                min-width: 300px;
                margin-top: 20px;

                @include for(xs) {
                    min-width: 0;
                    width: 100%;
                    max-width: 300px;

                    span {
                        @include x-rem(font-size,
                        1.8);
                    }
                }
            }

            p.note {
                @include x-rem(font-size,
                1.4);
            }

            ul.errors,
            p.success-message {
                padding: 8px 15px;
                margin-bottom: 20px;
                width: 100%;
                @media only screen and (min-width: 768px) {
                    max-width: 500px;
                }
            }

            ul.errors {
                margin-top: 0;
                border: 1px solid $secondary;
                background-color: rgba($secondary, .1);

                li {
                    margin-top: 0;

                    @include for(xs) {
                        margin-top: 0;
                    }
                }
            }

            p.success-message {
                margin-top: 0;
                border: 1px solid green;
                background-color: rgba(green, .1);
            }
        }

        &.case-study {
            @include fullWidthPanel($secondary);
            background-color: $grey;

            @include for(sm md lg) {
                padding-bottom: 0;
            }         
            
            .slide-bg-xs {
                width: 100%;
                height: auto;
                padding-top: 60vw;
                display: none !important;
                background-image: none !important;
                
            }

            .slide-bg {
                background-size: auto 35vw;
                background-repeat: no-repeat;
                background-position: top center;                    
            }

            .block-content {
                bottom: 100px;
                width: 100%;

                @include for(xs) {
                    bottom: 0;

                    >.container {
                        padding: 0;
                    }
                }

                .content {
                    float: none;
                    width: 100%;
                    padding: 40px;

                    h2 {
                        @include heading(light,
                        $white);
                        margin-top: 0;
                    }

                    p {
                        max-width: 700px;

                        a {
                            color: $white;
                            text-decoration: underline;

                            &:hover,
                            &:focus {
                                opacity: 0.8;
                            }
                        }
                    }

                    ul {
                        li {
                            color: $white;
                            padding-left: 15px;
                            position: relative;
                            margin-bottom: 8px;

                            &:before {
                                content: '· ';
                                position: absolute;
                                left: 0;
                            }

                            ul {
                                margin-left: 10px;
                            }
                        }
                    }

                    ol {
                        li {
                            margin-bottom: 8px;
                            color: $white;

                            ol {
                                margin-left: 10px;
                            }
                        }
                    }

                    >ul,
                    >ol {
                        display: table;
                        display: flow-root;
                    }

                    @include for(xlg) {
                        padding: 50px 70px;
                    }

                    @include for(sm) {
                        margin-bottom: 0;
                    }

                    @include for(xs) {
                        padding: 15px 15px 40px;
                        background-color: $grey;

                        h1,
                        h2,
                        h3,
                        h4,
                        h5,
                        h6,
                        p,
                        ul li,
                        ol li {
                            color: $text;

                            a {
                                color: $secondary;
                                text-decoration: underline;

                                &:hover,
                                &:focus {
                                    opacity: 1;
                                    color: $secondary-hover;
                                }
                            }
                        }
                    }
                }
            }

            +.relatedPages {
                margin-top: 0;
            }
        }

        &.relatedPages {
            background-color: $primary;
            margin-top: 90px;
            position: relative;
            padding: 60px 0 20px;

            @include for(xs) {
                margin-top: 40px;
                padding: 40px 0 20px;

                >.container {
                    width: 100%;
                    padding: 0;
                }
            }

            h2.light {
                @include heading(light,
                $white);
                margin-bottom: 20px;

                @include for(xs) {
                    margin-left: 30px;
                    margin-bottom: 10px;
                }
            }

            .carousel-nav {
                @include for(xs) {
                    width: 100%;

                    .slick-dots {
                        list-style-type: none;
                        display: flex;
                        justify-content: center;

                        li {
                            flex-grow: 1;
                            margin-left: 4px;
                            max-width: 30px;

                            &:first-child {
                                margin-left: 0;
                            }

                            button {
                                text-indent: -9999px;
                                width: 100%;
                                height: 4px;
                                background-color: rgba($white, .8);
                                opacity: .5;
                                border-radius: 0;
                                border: none;
                                transition: opacity .2s ease-in-out;

                                &:hover,
                                &:focus {
                                    opacity: .8;
                                }
                            }

                            &.slick-active {
                                button {
                                    background-color: $secondary;
                                    opacity: 1;

                                    &:hover,
                                    &:focus {
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    section.inline-image {
        img {
            width: 100%;
        }

        .image-wrapper {
            position: relative;

            p.caption {
                position: absolute;
                background-color: $white;
                width: auto;
                display: inline;
                padding: 8px 15px 8px 10px;
                bottom: 0;
                left: 0;

                @include for(xs) {
                    bottom: 0;
                    padding-left: 0;
                    @include x-rem(font-size,
                    1.4);
                }
            }
        }
    }

    section.inline-video {
        .video-item-container {
            position: relative;

            @include for(md lg) {
                padding: 0 40px;
            }

            .video-item {
                background-color: $text;

                @include for(md lg) {
                    width: calc(100% - 300px);

                    &.video-playing+.content {
                        left: calc(100% - 340px);
                        padding: 40px 20px;
                    }
                }
            }

            .content {
                background-color: $secondary;
                padding: 40px;
                transition: all 0.3s ease-in-out;

                @include for(md lg) {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: calc(100% - 380px);
                }

                @include for(sm) {
                    padding: 30px;
                }

                @include for(xs) {
                    padding: 20px;
                }

                h2 {
                    span {
                        @include heading(light,
                        $white);
                        display: block;
                        margin-top: 0;
                        margin-bottom: 10px;

                        @include for(xs) {
                            margin-top: 0;
                        }
                    }

                    @include heading(h3,
                    $white);

                    @include for(md lg) {
                        font-size: 26px;
                        font-size: 2.6rem;
                    }

                    margin-top: 0;

                    @include for(xs) {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}